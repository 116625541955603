import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Typography,
  Button,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
} from "@mui/material";
import "./HsePreviewReportModal.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { config } from "../../config";
import axios from "../../APIs/axios";
import { toast } from "react-toastify";
import { getAccountDetails } from "../Services/localStorage";
import HSE_Cover from "../../HSE_Cover.jpg";
import ExportExcel from "../ExportExcel/ExportExcel";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExportSavedReportPDF from "../ExportSavedReportPDF/ExportSavedReportPDF";
import ExportWordDoc from "../ExportWordDoc/ExportWordDoc";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import Chart from "react-apexcharts";
import html2canvas from "html2canvas";
import ImageViewerModal from "../ImageViewerModal/ImageViewerModal";
import CreatableSelect from "react-select/creatable";
import VariantsModal from "../VariantsModal/VariantsModal";
import Select from "react-select";
import { Category } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";

const HsePreviewReportModal = ({
  open,
  setOpenModal,
  setOpenCreateReportModal,
  reportHeader,
  selectedObservations,
  recommendations,
  selectedArea,
  selectedCategory,
  setSelectedObservations,
  selectedOrganization,
  selectedSite,
  setRecommendations,
  setSelectedArea,
  setSelectedCategory,
  setSelectedOrganization,
  setSelectedSite,
  ReportUID,
  selectedDateTime,
  observations,
  setCategoriesToDisplay,
  setAreasToDisplay,
  setObservations,
  getAllHseData,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  generateUniqueId,
  getCurrentDateTime,
  setLoading,
  areaOptions,
  allData,
  getAllHseReports,
  selectedSector,
  setSelectedSector,
  setSelectedParam
}) => {
  const [backgroundBrief, setBackgroundBrief] = useState(
    reportHeader.background_brief
      .replace("Alpha Maier Pvt.Ltd", `${selectedOrganization.label}`)
      .replace("Manesar plant", `${selectedSite.label}`)
      .replace("Electrical Systems", "Health, Safety and Environment")
      .replace(
        "30th & 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      )
  );
  const [contents, setContents] = useState(
    reportHeader.contents.replace("Scoring Table", "Charts")
  );
  const [exeSummary, setExeSummary] = useState(
    reportHeader.hse_exe_summary
      .replace(
        "Alpha Maier private Ltd",
        `${selectedOrganization.label}(${selectedSite.label})`
      )
      .replace(
        "30th &amp; 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      )
      .replace("<areas>", selectedArea.join(","))
  );
  const [introduction, setIntroduction] = useState(reportHeader.introduction);
  const [criticalObservations, setCriticalObservations] = useState(
    selectedObservations
      .filter((e) => e.criticality === "High")
      .map((observation) => ({ ...observation }))
  );
  const [otherDetails, setOtherDetails] = useState("");
  const [conclusion, setConclusion] = useState(
    reportHeader.conclusion.replaceAll("electrical", "HSE")
  );
  const [bestPractice, setbestPractice] = useState(reportHeader.best_practice);
  const [theWayForward, setTheWayForward] = useState(
    reportHeader.the_way_forward
  );
  const [screenNumber, setScreenNumber] = useState(1);
  const { userId, name } = getAccountDetails();
  const [editedObservations, setEditedObservations] = useState([]);
  const [isReportEdited, setIsReportEdited] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentEditedRow, setCurrentEditedRow] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState([]);
  const [scorePercent, setScorePercent] = useState(0);
  const [chartImage, setChartImage] = useState(null);
  const chartContainerRef = useRef(null);
  const [disableSaveNext, setDisableSaveNext] = useState(false);
  const [waitForCharts, setWaitForCharts] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [area, setArea] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [editedFields, setEditedFields] = useState([]);
  const [observationVariants, setObservationVariants] = useState([]);
  const [openVairantModal, setOpenVariantModal] = useState(false);
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [facilityInfo, setFacilityInfo] = useState({
    "Name of Facility": "", 
    "Address & Location": "",
    "Geographical Co-ordinates Seismic Zone": "",
    "Brief Property Description:": "",
    "Type of Construction": "",
    "Number of Floors":"",
    "Average Worker Foot Fall:": "",
    "No Objection Certificate": "",
  });
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [newRowInputs, setNewRowInputs] = useState({});
  const initializeNewRowInputs = (tableType, sectorType) => {
    setNewRowInputs((prev) => ({
      ...prev,
      [tableType]: {
        table_type: tableType, // Fixed key for table type
        sector_type: sectorType, // Include sector type
        is_selected: 1, // Fixed value for is_selected
        area: "",
        check_points: "",
        observation: "",
        criticality: "",
        recommendations: "",
        is_reference: "",
        score: "",
        imageUrls: [],
      },
    }));
  };

  useEffect(() => {
    updateBackgroundBrief();
    updateExecSummary();
  }, [selectedOrganization, selectedSite, startDate, endDate, selectedArea]);

  const updateBackgroundBrief = () => {
    const updatedData = reportHeader.background_brief
      .replace("Alpha Maier Pvt.Ltd", `${selectedOrganization.label}`)
      .replace("Manesar plant", `${selectedSite.label}`)
      .replace("Electrical Systems", "Health, Safety and Environment")
      .replace(
        "30th & 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      );
    setBackgroundBrief(updatedData);
  };

  const updateExecSummary = () => {
    const updatedData = reportHeader.exe_summary
      .replace(
        "Alpha Maier private Ltd",
        `${selectedOrganization.label}(${selectedSite.label})`
      )
      .replace(
        "30th &amp; 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      )
      .replace("<areas>", selectedArea.join(","))
      .replaceAll("electrical", "HSE")
      .replaceAll("Electrical", "HSE");
    setExeSummary(updatedData);
  };

  const fetchDataForCharts = () => {
    const observations = selectedObservations || [];

    const totalScore =
      observations.length > 0
        ? observations
            .map((e) => e.score || 0)
            .reduce((acc, score) => acc + score, 0)
        : 0;

    const percentage = Math.floor(
      (totalScore / (observations.length * 5)) * 100
    );

    setScorePercent(isNaN(percentage) ? 0 : percentage);
    setData(observations);
  };

  // const convertChartToImage = async () => {
  //   try {
  //     if (chartContainerRef.current) {
  //       const contentHeight = chartContainerRef.current.scrollHeight;
  //       chartContainerRef.current.style.height = `${contentHeight}px`;

  //       const canvas = await html2canvas(chartContainerRef.current, {
  //         scrollY: -window.scrollY,
  //       });

  //       chartContainerRef.current.style.height = null;

  //       const image = canvas.toDataURL("image/png");
  //       setChartImage(image);
  //     }
  //   } catch (error) {
  //     console.log("Error converting div to image:", error);
  //   }
  // };

  const convertChartToImage = async () => {
    try {
      if (!chartContainerRef.current) {
        console.error("chartContainerRef is null or undefined");
        return;
      }

      const contentHeight = chartContainerRef.current.scrollHeight;
      chartContainerRef.current.style.height = `${contentHeight}px`;

      const canvas = await html2canvas(chartContainerRef.current, {
        scrollY: -window.scrollY,
      });

      chartContainerRef.current.style.height = null;

      const image = canvas.toDataURL("image/png");
      setChartImage(image);
    } catch (error) {
      console.log("Error converting div to image:", error);
    }
  };

  useEffect(() => {
    if (screenNumber === 10) {
      fetchDataForCharts();
      const timeoutId = setTimeout(() => {
        convertChartToImage();
      }, 1000); // Adjust the delay as needed (e.g., 1000 milliseconds)

      // Clear the timeout if the component unmounts or screenNumber changes
      return () => clearTimeout(timeoutId);
    }
  }, [screenNumber, selectedObservations, convertChartToImage]);

  useEffect(() => {
    setWaitForCharts(true);
    if (screenNumber === 10) {
      // Simulate loading for 3 seconds
      const loaderTimeout = setTimeout(() => {
        setWaitForCharts(false);
      }, 5000);

      // Cleanup timeout if the component unmounts or when loading is complete
      return () => clearTimeout(loaderTimeout);
    }
  }, [screenNumber]);

  const handleCellEdit = (e, field, originalContent, observationObj) => {
  // Handle the confirmation dialog
  const handleConfirmation = () => {
    if (!confirmationShown) {
      const confirmEdit = window.confirm(
        "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
      );
      if (!confirmEdit) {
        e.target.textContent = originalContent; // Revert content
        return false;
      } else {
        setConfirmationShown(true); // User confirmed
        return true;
      }
    }
    return true;
  };

  // Show confirmation for "observation" field
  if (field === "observation" && !handleConfirmation()) {
    setIsEditing(false);
    return; // Exit if the user cancels the confirmation
  }

  setIsEditing(true);

  const updateEditedFields = (newField) => {
    if (!editedFields.includes(newField)) {
      setEditedFields((prevFields) => [...prevFields, newField]);
    }
  };

  let currentContent;
  if (field === "area" || field === "category" || field === "criticality") {
    currentContent = e.value;
  } else {
    currentContent = e.target.textContent;
  }

  // Validate character limits
  const charLimitExceeded = (limit) => {
    if (currentContent.length > limit) {
      toast.warning(`Only ${limit} characters are allowed in this field.`);
      setIsEditing(false);
      return true;
    }
    return false;
  };

  if (field === "area" || field === "category") {
    if (charLimitExceeded(50)) return;
  } else if (field === "observation" || field === "recommendations") {
    if (charLimitExceeded(7000)) {
      e.target.textContent = currentContent.substring(0, 7000);
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(e.target.childNodes[0], 7000);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      e.preventDefault();
      return;
    }
  }

  setIsReportEdited(true);

  // Find the observation using either sr_no or id
  const observationIndex = selectedObservations.findIndex(
    (obs) => obs.sr_no === observationObj.sr_no || obs.id === observationObj.id
  );

  console.log("observationIndex", observationIndex)

  // Check if a different row is being edited without saving the current one
  if (currentEditedRow !== -1 && currentEditedRow !== observationObj.sr_no && currentEditedRow !== observationObj.id) {
    toast.warning(
      "Please save changes in the currently edited row before editing another row."
    );
    return;
  }

  // Update the currentEditedRow state with the new sr_no or id
  setCurrentEditedRow(observationObj.sr_no || observationObj.id);

  let value = currentContent;

  const updatedObservations = [...editedObservations];
  let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
  updatedObservations[i] = {
    ...observationObj,
    ...updatedObservations[i],
    [field]: value,
    selectedRefIndex: observationIndex,
  };

  setEditedObservations(updatedObservations);

  // Check if the content has been reverted to the original
  const isReverted = (() => {
    switch (field) {
      case "observation":
      case "recommendations":
      case "is_reference":
        return e.target.textContent === originalContent;
      case "area":
      case "category":
      case "criticality":
        return e.value === originalContent;
      default:
        return false;
    }
  })();

  // If content is reverted, reset editing state
  if (isReverted) {
    setCurrentEditedRow(-1); // Reset currentEditedRow
    setIsEditing(false);
    setEditedObservations([]);
    setConfirmationShown(false); // Reset confirmation flag
  }

  // Update edited fields array based on the field edited
  const fieldMap = {
    category: "CA",
    check_points: "CH",
    observation: "O",
    criticality: "CR",
    recommendations: "R",
    is_reference: "I",
  };

  if (fieldMap[field]) {
    updateEditedFields(fieldMap[field]);
  }
};


  // const handleCellEdit = (e, field, originalContent, observationObj) => {
  //   // Handle the confirmation dialog
  //   const handleConfirmation = () => {
  //     if (!confirmationShown) {
  //       const confirmEdit = window.confirm(
  //         "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
  //       );
  //       if (!confirmEdit) {
  //         e.target.textContent = originalContent; // Revert content
  //         return false;
  //       } else {
  //         setConfirmationShown(true); // User confirmed
  //         return true;
  //       }
  //     }
  //     return true;
  //   };

  //   // Show confirmation for "observation" field
  //   if (field === "observation" && !handleConfirmation()) {
  //     setIsEditing(false);
  //     return; // Exit if the user cancels the confirmation
  //   }

  //   setIsEditing(true);

  //   const updateEditedFields = (newField) => {
  //     if (!editedFields.includes(newField)) {
  //       setEditedFields((prevFields) => [...prevFields, newField]);
  //     }
  //   };

  //   let currentContent;
  //   if (field === "area" || field === "category" || field === "criticality") {
  //     currentContent = e.value;
  //   } else {
  //     currentContent = e.target.textContent;
  //   }

  //   // Validate character limits
  //   const charLimitExceeded = (limit) => {
  //     if (currentContent.length > limit) {
  //       toast.warning(`Only ${limit} characters are allowed in this field.`);
  //       setIsEditing(false);
  //       return true;
  //     }
  //     return false;
  //   };

  //   if (field === "area" || field === "category") {
  //     if (charLimitExceeded(50)) return;
  //   } else if (field === "observation" || field === "recommendations") {
  //     if (charLimitExceeded(7000)) {
  //       e.target.textContent = currentContent.substring(0, 7000);
  //       const range = document.createRange();
  //       const sel = window.getSelection();
  //       range.setStart(e.target.childNodes[0], 7000);
  //       range.collapse(true);
  //       sel.removeAllRanges();
  //       sel.addRange(range);
  //       e.preventDefault();
  //       return;
  //     }
  //   }

  //   setIsReportEdited(true);

  //   // Find the observation using sr_no
  //   const observationIndex = selectedObservations.findIndex(
  //     (obs) => obs.sr_no === observationObj.sr_no
  //   );

  //   // Check if a different row is being edited without saving the current one
  //   if (currentEditedRow !== -1 && currentEditedRow !== observationObj.sr_no) {
  //     toast.warning(
  //       "Please save changes in the currently edited row before editing another row."
  //     );
  //     return;
  //   }

  //   // Update the currentEditedRow state with the new sr_no
  //   setCurrentEditedRow(observationObj.sr_no);

  //   let value = currentContent;

  //   const updatedObservations = [...editedObservations];
  //   let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
  //   updatedObservations[i] = {
  //     ...observationObj,
  //     ...updatedObservations[i],
  //     [field]: value,
  //     selectedRefIndex: observationIndex,
  //   };

  //   setEditedObservations(updatedObservations);

  //   // Check if the content has been reverted to the original
  //   const isReverted = (() => {
  //     switch (field) {
  //       case "observation":
  //       case "recommendations":
  //       case "is_reference":
  //         return e.target.textContent === originalContent;
  //       case "area":
  //       case "category":
  //       case "criticality":
  //         return e.value === originalContent;
  //       default:
  //         return false;
  //     }
  //   })();

  //   // If content is reverted, reset editing state
  //   if (isReverted) {
  //     setCurrentEditedRow(-1); // Reset currentEditedRow
  //     setIsEditing(false);
  //     setEditedObservations([]);
  //     setConfirmationShown(false); // Reset confirmation flag
  //   }

  //   // Update edited fields array based on the field edited
  //   const fieldMap = {
  //     category: "CA",
  //     check_points: "CH",
  //     observation: "O",
  //     criticality: "CR",
  //     recommendations: "R",
  //     is_reference: "I",
  //   };

  //   if (fieldMap[field]) {
  //     updateEditedFields(fieldMap[field]);
  //   }
  // };

  // const handleCellEdit = (e, index, field, originalContent, observationObj) => {
  //   // Function to handle the confirmation dialog
  //   const handleConfirmation = () => {
  //     if (!confirmationShown) {
  //       const confirmEdit = window.confirm(
  //         "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
  //       );
  //       if (!confirmEdit) {
  //         // Revert the text content if the user cancels
  //         e.target.textContent = originalContent;
  //         return false;
  //       } else {
  //         setConfirmationShown(true); // User confirmed, set the flag
  //         return true;
  //       }
  //     }
  //     return true;
  //   };

  //   // Show the confirmation dialog if the field is "observation"
  //   if (field === "observation") {
  //     if (!handleConfirmation()) {
  //       setIsEditing(false);
  //       return; // Exit if the user cancels the confirmation
  //     }
  //   }

  //   setIsEditing(true);

  //   // Function to update edited fields array
  //   const updateEditedFields = (newField) => {
  //     if (!editedFields.includes(newField)) {
  //       setEditedFields((prevFields) => [...prevFields, newField]);
  //     }
  //   };

  //   if (field === "area") {
  //     let area = [];
  //     area.push(e.value);
  //     setArea(area);
  //     updateEditedFields("A");
  //   }

  //   let currentContent;
  //   if (field === "area" || field === "category" || field === "criticality") {
  //     currentContent = e.value;
  //   } else {
  //     currentContent = e.target.textContent;
  //   }

  //   // Check if the current content exceeds the character limit
  //   if (
  //     (field === "area" || field === "category") &&
  //     currentContent.length > 50
  //   ) {
  //     toast.warning("Only 50 characters are allowed in this field.");
  //     setIsEditing(false);
  //     return; // Do nothing and exit the function
  //   }

  //   if (currentContent.length > 7000) {
  //     toast.warning("Maximum character limit reached.");
  //     e.target.textContent = currentContent.substring(0, 7000);
  //     const range = document.createRange();
  //     const sel = window.getSelection();
  //     range.setStart(e.target.childNodes[0], 7000);
  //     range.collapse(true);
  //     sel.removeAllRanges();
  //     sel.addRange(range);
  //     e.preventDefault();
  //     return;
  //   }

  //   setIsReportEdited(true);
  //   if (currentEditedRow !== -1 && currentEditedRow !== index) {
  //     toast.warning(
  //       "Please save changes in the currently edited row before editing another row."
  //     );
  //     return;
  //   }

  //   setCurrentEditedRow(index);

  //   let value;
  //   if (field === "area" || field === "category" || field === "criticality") {
  //     value = e.value;
  //   } else {
  //     value = e.target.textContent;
  //   }

  //   const updatedObservations = [...editedObservations];
  //   let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
  //   updatedObservations[i] = {
  //     ...observationObj,
  //     ...updatedObservations[i],
  //     [field]: value,
  //     selectedRefIndex: index,
  //   };

  //   setEditedObservations(updatedObservations);

  //   // Check if the content has been reverted to the original
  //   const isReverted =
  //     field === "observation"
  //       ? e.target.textContent === originalContent
  //       : field === "recommendations"
  //       ? e.target.textContent === originalContent
  //       : field === "area"
  //       ? e.value === originalContent
  //       : field === "criticality"
  //       ? e.value === originalContent
  //       : field === "is_reference"
  //       ? e.target.textContent === originalContent
  //       : field === "category"
  //       ? e.value === originalContent
  //       : false;

  //   // If content is reverted, disable editing and enable other rows
  //   if (isReverted) {
  //     setCurrentEditedRow(-1);
  //     setIsEditing(false);
  //     setEditedObservations([]);
  //     setConfirmationShown(false); // Reset confirmation shown when content is reverted
  //   }

  //   // Update edited fields array based on the field edited
  //   switch (field) {
  //     case "category":
  //       updateEditedFields("CA");
  //       break;
  //     case "check_points":
  //       updateEditedFields("CH");
  //       break;
  //     case "observation":
  //       updateEditedFields("O");
  //       break;
  //     case "criticality":
  //       updateEditedFields("CR");
  //       break;
  //     case "recommendations":
  //       updateEditedFields("R");
  //       break;
  //     case "is_reference":
  //       updateEditedFields("I");
  //       break;
  //     // Add cases for other fields as needed
  //     default:
  //       break;
  //   }
  // };

  const handleChange = (e, name) => {
    setIsSaved(false);
    if (name === "background") {
      setBackgroundBrief(e.target.value);
    } else if (name === "contents") {
      setContents(e.target.value);
    } else if (name === "exe") {
      setExeSummary(e.target.value);
    } else if (name === "conclusion") {
      setConclusion(e.target.value);
    } else if (name === "other details") {
      setOtherDetails(e.target.value);
    } else if (name === "best_practice") {
      setbestPractice(e.target.value);
    } else if (name === "the_way_forward") {
      setTheWayForward(e.target.value);
    } else if (name === "intro") {
      setIntroduction(e.target.value);
    }
  };

  // const handleChangeFacilityInfo = (e, field) => {
  //   setFacilityInfo({
  //     ...facilityInfo,
  //     [field]: e.target.value,
  //   });
  // };

  const handleChangeFacilityInfo = (event) => {
    const { name, value } = event.target;
    setFacilityInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleNewKeyChange = (event) => {
    setNewKey(event.target.value);
  };

  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
  };

  const handleAddNewField = () => {
    if (newKey && newValue) {
      const keyExists = facilityInfo.hasOwnProperty(newKey);
      const valueExists = facilityInfo[newKey] === newValue;
  
      if (keyExists) {
        alert("This field already exists.");
        return;
      }
  
      setFacilityInfo((prevInfo) => ({
        ...prevInfo,
        [newKey]: newValue,
      }));
      setNewKey("");
      setNewValue("");
    } else {
      alert("Please fill both the fields before adding.");
    }
  };

  // const handleAddNewField = () => {
  //   if (newKey && newValue) {
  //     setFacilityInfo((prevInfo) => ({
  //       ...prevInfo,
  //       [newKey]: newValue,
  //     }));
  //     setNewKey("");
  //     setNewValue("");
  //   } else {
  //     alert("Please fill both the fields before adding.");
  //   }
  // };

  const handleNext = () => {
    if (isEditing) {
      toast.warning("Please save changes before proceeding.");
      return;
    }
    setScreenNumber(screenNumber + 1);
  };

  const handlePrev = () => {
    if (isEditing) {
      toast.warning("Please save changes before going back.");
      return;
    }
    setScreenNumber(screenNumber - 1);
  };

  const handleEdit = () => {
    setOpenModal(false);
    setOpenCreateReportModal(true);
  };

  const removeItem = (index) => {
    const updatedObservations = [...criticalObservations];
    updatedObservations.splice(index, 1);
    setCriticalObservations(updatedObservations);
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const MAX_WIDTH = 800; // Maximum width for the compressed image
        const MAX_HEIGHT = 800; // Maximum height for the compressed image

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.7); // 0.7 is the image quality (0.0 - 1.0)
      };

      img.src = URL.createObjectURL(file);
    });
  };

  // const handleImageUpload = async (index, files) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...observations];

  //   if (!selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls = [];
  //   }

  //   try {
  //     setLoading(true);
  //     for (const file of Array.from(files)) {
  //       if (!file.type.startsWith("image/")) {
  //         throw new Error("Only image files are allowed.");
  //       }

  //       const compressedImage = await compressImage(file);

  //       const formData = new FormData();
  //       formData.append("image", compressedImage);

  //       const response = await axios.post(
  //         `${config.PATH}/api/upload/image `,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const imageUrl = response.data.imageUrl;
  //       selectedObsCopy[index].imageUrls.push(imageUrl);
  //     }

  //     selectedObsCopy.forEach((e) => {
  //       obsCopy[e.refIndex] = {
  //         ...obsCopy[e.refIndex],
  //         imageUrls: e.imageUrls,
  //       };
  //     });

  //     setSelectedObservations(selectedObsCopy);
  //     setObservations(obsCopy);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error uploading image:", error);
  //     toast.error(error.message);
  //   }
  // };

  // const handleImageUpload = async (index, files) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...observations];
  //   const editedObsCopy = [...editedObservations];

  //   if (!selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls = [];
  //   }

  //   try {
  //     setLoading(true);

  //     for (const file of Array.from(files)) {
  //       if (!file.type.startsWith("image/")) {
  //         throw new Error("Only image files are allowed.");
  //       }

  //       const compressedImage = await compressImage(file);

  //       const formData = new FormData();
  //       formData.append("image", compressedImage);

  //       const response = await axios.post(
  //         `${config.PATH}/api/upload/image `,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const imageUrl = response.data.imageUrl;
  //       selectedObsCopy[index].imageUrls.push(imageUrl);

  //       // Update editedObservations if it has values
  //       if (editedObsCopy.length > 0) {
  //         const editedIndex = editedObsCopy.findIndex(
  //           (observation) =>
  //             observation.refIndex === selectedObsCopy[index].refIndex
  //         );
  //         if (editedIndex !== -1) {
  //           editedObsCopy[editedIndex].imageUrls =
  //             selectedObsCopy[index].imageUrls;
  //         }
  //       }
  //     }

  //     selectedObsCopy.forEach((e) => {
  //       obsCopy[e.refIndex] = {
  //         ...obsCopy[e.refIndex],
  //         imageUrls: e.imageUrls,
  //       };
  //     });

  //     setSelectedObservations(selectedObsCopy);
  //     setObservations(obsCopy);

  //     // Update editedObservations only if it has values
  //     if (editedObsCopy.length > 0) {
  //       setEditedObservations(editedObsCopy);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error uploading image:", error);
  //     toast.error(error.message);
  //   }
  // };

  // const handleImageUpload = async (table_type, identifier, area, files) => {
  //   // Create deep copies to avoid mutation
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...observations];
  //   const editedObsCopy = [...editedObservations];

  //   // Ensure the correct structure for deep copying
  //   selectedObsCopy.forEach((item) => {
  //     if (!item.imageUrls) item.imageUrls = [];
  //   });

  //   obsCopy.forEach((item) => {
  //     if (!item.imageUrls) item.imageUrls = [];
  //   });

  //   editedObsCopy.forEach((item) => {
  //     if (!item.imageUrls) item.imageUrls = [];
  //   });

  //   try {
  //     setLoading(true);

  //     for (const file of Array.from(files)) {
  //       if (!file.type.startsWith("image/")) {
  //         throw new Error("Only image files are allowed.");
  //       }

  //       const compressedImage = await compressImage(file);

  //       const formData = new FormData();
  //       formData.append("image", compressedImage);

  //       const response = await axios.post(
  //         `${config.PATH}/api/upload/image`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const imageUrl = response.data.imageUrl;

  //       // Update the imageUrls in selectedObservations
  //       const selectedObs = selectedObsCopy.find(
  //         (item) =>
  //           item.table_type === table_type &&
  //           (item.sr_no === identifier || item.id === identifier) &&
  //           item.area === area
  //       );
  //       if (selectedObs) {
  //         selectedObs.imageUrls = [...(selectedObs.imageUrls || []), imageUrl];
  //       }

  //       // Update the imageUrls in observations
  //       const obs = obsCopy.find(
  //         (item) =>
  //           item.table_type === table_type &&
  //           (item.sr_no === identifier || item.id === identifier) &&
  //           item.area === area
  //       );
  //       if (obs) {
  //         obs.imageUrls = [...(obs.imageUrls || []), imageUrl];
  //       }

  //       // Update editedObservations if it has values
  //       const editedObs = editedObsCopy.find(
  //         (item) =>
  //           item.table_type === table_type &&
  //           (item.sr_no === identifier || item.id === identifier) &&
  //           item.area === area
  //       );
  //       if (editedObs) {
  //         editedObs.imageUrls = [...(editedObs.imageUrls || []), imageUrl];
  //       }
  //     }

  //     setSelectedObservations(selectedObsCopy);
  //     setObservations(obsCopy);

  //     // Update editedObservations only if it has values
  //     if (editedObsCopy.length > 0) {
  //       setEditedObservations(editedObsCopy);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error uploading image:", error);
  //     toast.error(error.message);
  //   }
  // };

  const handleImageUpload = async (table_type, identifier, area, files) => {
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...observations];
    const editedObsCopy = [...editedObservations];
  
    // Ensure imageUrls is initialized
    selectedObsCopy.forEach((item) => {
      if (!item.imageUrls) item.imageUrls = [];
    });
  
    obsCopy.forEach((item) => {
      if (!item.imageUrls) item.imageUrls = [];
    });
  
    editedObsCopy.forEach((item) => {
      if (!item.imageUrls) item.imageUrls = [];
    });
  
    try {
      setLoading(true);
  
      for (const file of Array.from(files)) {
        if (!file.type.startsWith("image/")) {
          throw new Error("Only image files are allowed.");
        }
  
        const compressedImage = await compressImage(file);
  
        const formData = new FormData();
        formData.append("image", compressedImage);
  
        const response = await axios.post(
          `${config.PATH}/api/upload/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        const imageUrl = response.data.imageUrl;
  
        // Update selectedObservations
        const selectedObs = selectedObsCopy.find(
          (item) =>
            item.table_type === table_type &&
            (item.sr_no === identifier || item.id === identifier) &&
            item.area === area
        );
        if (selectedObs && !selectedObs.imageUrls.includes(imageUrl)) {
          selectedObs.imageUrls.push(imageUrl);
        }
  
        // Update observations
        const obs = obsCopy.find(
          (item) =>
            item.table_type === table_type &&
            (item.sr_no === identifier || item.id === identifier) &&
            item.area === area
        );
        if (obs && !obs.imageUrls.includes(imageUrl)) {
          obs.imageUrls.push(imageUrl);
        }
  
        // Update editedObservations
        const editedObs = editedObsCopy.find(
          (item) =>
            item.table_type === table_type &&
            (item.sr_no === identifier || item.id === identifier) &&
            item.area === area
        );
        if (editedObs && !editedObs.imageUrls.includes(imageUrl)) {
          editedObs.imageUrls.push(imageUrl);
        }
      }
  
      setSelectedObservations(selectedObsCopy);
      setObservations(obsCopy);
  
      if (editedObsCopy.length > 0) {
        setEditedObservations(editedObsCopy);
      }
  
      setLoading(false);
    } catch (error) {
      console.log("Error uploading image:", error);
      toast.error(error.message);
    }
  };
  

  // const handleRemoveImage = (index, imageIndex) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...observations];

  //   if (selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls.splice(imageIndex, 1);

  //     // If there are no more images in the array, remove the property
  //     if (selectedObsCopy[index].imageUrls.length === 0) {
  //       delete selectedObsCopy[index].imageUrls;
  //     }

  //     // Update the corresponding observation in obsCopy
  //     obsCopy[selectedObsCopy[index].refIndex] = {
  //       ...obsCopy[selectedObsCopy[index].refIndex],
  //       imageUrls: selectedObsCopy[index].imageUrls,
  //     };

  //     // Update state
  //     setSelectedObservations(selectedObsCopy);
  //     setObservations(obsCopy);
  //   }
  // };

  const handleRemoveImage = (table_type, area, identifier, imageIndex) => {
    // Create deep copies to avoid mutation
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...observations];

    // Find the observation with the matching table_type, area, and identifier
    const observationToUpdate = selectedObsCopy.find(
      (obs) =>
        obs.table_type === table_type &&
        obs.area === area &&
        (obs.sr_no === identifier || obs.id === identifier)
    );

    if (observationToUpdate && observationToUpdate.imageUrls) {
      // Remove the image URL at the specified index
      observationToUpdate.imageUrls.splice(imageIndex, 1);

      // If there are no more images in the array, remove the imageUrls property
      if (observationToUpdate.imageUrls.length === 0) {
        delete observationToUpdate.imageUrls;
      }

      // Update the corresponding observation in obsCopy
      const obsIndex = obsCopy.findIndex(
        (obs) =>
          obs.table_type === table_type &&
          obs.area === area &&
          (obs.sr_no === identifier || obs.id === identifier)
      );

      if (obsIndex !== -1) {
        obsCopy[obsIndex] = {
          ...obsCopy[obsIndex],
          imageUrls: observationToUpdate.imageUrls,
        };
      }

      // Update state
      setSelectedObservations(selectedObsCopy);
      setObservations(obsCopy);
    } else {
      toast.error("Observation not found.");
    }
  };

  const keyNotToCheck = [
    "remarks",
    "score",
    "refIndex",
    "selectedRefIndex",
    "equipment",
    "is_selected",
    "imageUrls",
    "edited_fields",
    "status",
    "category",
  ];

  // const handleSave = async (complete) => {
  //   try {
  //     if (isAnyScoreEmpty()) {
  //       toast.warning("Please fill the score table before saving the report.");
  //       return;
  //     }

  //     let updatedSelectedObservations = [...selectedObservations];
  //     let updatedAllObservations = [...observations];

  //     if (editedObservations[0]) {
  //       if (
  //         Object.entries(editedObservations[0]).filter(
  //           (e) => !(keyNotToCheck.includes(e[0]) || e[1])
  //         ).length > 0
  //       ) {
  //         toast.error("Table fields can't be empty.");
  //         return;
  //       }

  //       if (
  //         selectedObservations.filter(
  //           (obj) => obj.observation === editedObservations[0].observation
  //         ).length > 0
  //       ) {
  //         toast.error(
  //           "Duplicate observations detected. Please modify observations to ensure uniqueness."
  //         );
  //         return;
  //       }

  //       editedObservations.forEach((obj) => {
  //         updatedSelectedObservations[obj.selectedRefIndex] = obj;
  //         updatedAllObservations[obj.refIndex] = { ...obj, is_selected: 1 };
  //       });

  //       if (isReportEdited) {
  //         const payload = editedObservations
  //           // .filter(({ note_type }) => !note_type)
  //           .map(({ sr_no, score, ...rest }) => rest);

  //         if (payload.length > 0) {
  //           await axios.post(`${config.PATH}/api/insert-new-row`, payload);
  //         }
  //       } else {
  //         //pass
  //       }

  //       setSelectedObservations(updatedSelectedObservations);
  //       setObservations(updatedAllObservations);
  //     }

  //     const reportData = {
  //       report_id: ReportUID,
  //       user_id: userId,
  //       date_time: selectedDateTime,
  //       organization: selectedOrganization
  //         ? selectedOrganization.label
  //         : selectedOrganization,
  //       site: selectedSite ? selectedSite.label : selectedSite,
  //       org_id: selectedOrganization
  //         ? selectedOrganization.value
  //         : selectedOrganization,
  //       area: selectedArea,
  //       category: selectedCategory,
  //       background_brief: backgroundBrief,
  //       contents: contents,
  //       exe_summary: exeSummary,
  //       conclusion: conclusion,
  //       // image_urls: JSON.stringify(imageUrlsByRow),
  //       scores: scores,
  //       cumulative: cumulativeScore,
  //       is_edited: isReportEdited,
  //       other_details: otherDetails,
  //       is_saved: 1,
  //       is_complete: complete===true?true:false,
  //       start_date:startDate,
  //       end_date:endDate
  //     };
  //     // const reportEndpoint = complete===true
  //     // ? `${config.PATH}/api/save-update-cmv-report`
  //     // : `${config.PATH}/api/save-update-report`;

  //     const cmvEndPOint = `${config.PATH}/api/save-update-hse-cmv-report`;
  //     const reportEndPoint = `${config.PATH}/api/save-update-hse-report`;

  //     if (complete === true) {
  //       await axios.post(reportEndPoint, reportData);
  //       await axios.post(cmvEndPOint, reportData);
  //     }

  //     await axios.post(reportEndPoint, reportData);

  //     const observationsData = {
  //       report_id: ReportUID,
  //       all_observations: updatedAllObservations.map((e) => {
  //         if (e.id) {
  //           e.sr_no = e.id;
  //         }
  //         return e;
  //       }),
  //       organization: selectedOrganization.label,
  //       site: selectedSite.label,
  //     };

  //     const observationEndpoint = complete===true
  //     ? `${config.PATH}/api/save-update-hse-cmv-observations`
  //     : `${config.PATH}/api/save-update-hse-observations`;

  //     await axios.post(
  //       observationEndpoint,
  //       observationsData
  //     );

  //     setIsSaved(true);
  //     setEditedObservations([]);
  //     setCurrentEditedRow(-1);
  //     setIsEditing(false);
  //     setDisableSaveNext(false);
  //     getAllData();
  //     toast.success(`${complete===true?"Report Completed and Saved":"Report Saved"}`);
  //     saveCriticalObservations(complete);
  //   } catch (error) {
  //     console.log("Error saving report:", error);
  //   }
  // };

  // const handleSave = async (complete) => {
  //   try {
  //     setLoading(true);
  //     let updatedSelectedObservations = [...selectedObservations];
  //     let updatedAllObservations = [...observations];

  //     if (editedObservations[0]) {
  //       if (
  //         Object.entries(editedObservations[0]).filter(
  //           (e) => !(keyNotToCheck.includes(e[0]) || e[1])
  //         ).length > 0
  //       ) {
  //         toast.error("Table fields can't be empty.");
  //         setLoading(false);
  //         return;
  //       }

  //       // if (
  //       //   selectedObservations.filter(
  //       //     (obj) => obj.observation === editedObservations[0].observation
  //       //   ).length > 0
  //       // ) {
  //       //   toast.error(
  //       //     "Duplicate observations detected. Please modify observations to ensure uniqueness."
  //       //   );
  //       //   setLoading(false);
  //       //   return;
  //       // }

  //       editedObservations.forEach((obj) => {
  //         updatedSelectedObservations[obj.selectedRefIndex] = obj;
  //         updatedAllObservations[obj.refIndex] = { ...obj, is_selected: 1 };
  //       });

  //       // if (isReportEdited) {
  //       //   const payload = editedObservations
  //       //     // .filter(({ note_type }) => !note_type)
  //       //     .map(({ sr_no, score, ...rest }) => rest);

  //       //   if (payload.length > 0) {
  //       //     await axios.post(`${config.PATH}/api/insert-new-hse-row`, payload);
  //       //   }
  //       // } else {
  //       //   //pass
  //       // }

  //       if (isReportEdited) {
  //         const payload = editedObservations
  //           .map(({ sr_no, score, edited_fields, ...rest }) => ({
  //             ...rest,
  //             edited_fields: editedFields, // Include edited_fields in the payload
  //           }))
  //           .filter((item) => Object.keys(item).length > 0); // Filter out items with no properties

  //         if (payload.length > 0) {
  //           await axios.post(`${config.PATH}/api/insert-new-hse-row`, payload);
  //         }
  //       } else {
  //         //pass
  //       }

  //       setSelectedObservations(updatedSelectedObservations);
  //       setObservations(updatedAllObservations);
  //     }

  //     const reportData = {
  //       report_id: ReportUID,
  //       user_id: userId,
  //       date_time: selectedDateTime,
  //       organization: selectedOrganization
  //         ? selectedOrganization.label
  //         : selectedOrganization,
  //       site: selectedSite ? selectedSite.label : selectedSite,
  //       org_id: selectedOrganization
  //         ? selectedOrganization.value
  //         : selectedOrganization,
  //       area: selectedArea,
  //       category: selectedCategory,
  //       background_brief: backgroundBrief,
  //       contents: contents,
  //       exe_summary: exeSummary,
  //       conclusion: conclusion,
  //       best_practice: bestPractice,
  //       the_way_forward: theWayForward,
  //       is_edited: isReportEdited,
  //       other_details: otherDetails,
  //       is_saved: 1,
  //       is_complete: complete === true ? true : false,
  //       start_date: startDate,
  //       end_date: endDate,
  //       sector_type: selectedSector.value,
  //     };

  //     if (module === "cmv") {
  //       reportData.type = "cmv";
  //     } else {
  //       reportData.type = "primary";
  //     }

  //     const cmvEndPOint = `${config.PATH}/api/save-update-hse-cmv-report`;
  //     const reportEndPoint = `${config.PATH}/api/save-update-hse-report`;

  //     if (complete === true) {
  //       await axios.post(reportEndPoint, reportData);
  //       await axios.post(cmvEndPOint, reportData);
  //     }

  //     await axios.post(reportEndPoint, reportData);

  //     const observationsData = {
  //       report_id: ReportUID,
  //       all_observations: updatedAllObservations,
  //       organization: selectedOrganization.label,
  //       site: selectedSite.label,
  //       user_id: userId,
  //     };

  //     const observationEndpoint =
  //       complete === true
  //         ? `${config.PATH}/api/save-update-hse-cmv-observations`
  //         : `${config.PATH}/api/save-update-hse-observations`;

  //     await axios.post(observationEndpoint, observationsData);

  //     setIsSaved(true);
  //     setEditedObservations([]);
  //     setCurrentEditedRow(-1);
  //     setIsEditing(false);
  //     setDisableSaveNext(false);
  //     getAllHseData();
  //     saveCriticalObservations(complete);
  //     saveFacilityInfo();
  //     setLoading(false);
  //     updateOrgReportStatus();
  //     updateSiteReportStatus();
  //     setEditedFields([]);
  //     setConfirmationShown(false);
  //     // getAllHseReports();
  //     toast.success(
  //       `${complete === true ? "Report Completed and Saved" : "Report Saved"}`
  //     );
  //   } catch (error) {
  //     console.log("Error saving report:", error);
  //   }
  // };

  const handleSave = async (complete) => {
    try {
      setLoading(true);
      let updatedSelectedObservations = [...selectedObservations];
      let updatedAllObservations = [...observations];
  
      // Trimming fields in observations and report data
      const trimObjectFields = (obj) => {
        const trimmedObj = { ...obj };
        Object.keys(trimmedObj).forEach((key) => {
          if (typeof trimmedObj[key] === 'string') {
            trimmedObj[key] = trimmedObj[key].trim();
          }
        });
        return trimmedObj;
      };
  
      if (editedObservations[0]) {
        // Check for empty fields after trimming
        if (
          Object.entries(trimObjectFields(editedObservations[0])).filter(
            (e) => !(keyNotToCheck.includes(e[0]) || e[1])
          ).length > 0
        ) {
          toast.error("Table fields can't be empty.");
          setLoading(false);
          return;
        }
  
        editedObservations.forEach((obj) => {
          const trimmedObj = trimObjectFields(obj);
          updatedSelectedObservations[obj.selectedRefIndex] = trimmedObj;
          updatedAllObservations[obj.refIndex] = { ...trimmedObj, is_selected: 1 };
        });
  
        if (isReportEdited) {
          const payload = editedObservations
            .map(({ sr_no, score, edited_fields, ...rest }) => ({
              ...trimObjectFields(rest), // Trimmed rest of the fields
              edited_fields: editedFields, // Include edited_fields in the payload
            }))
            .filter((item) => Object.keys(item).length > 0); // Filter out items with no properties
  
          if (payload.length > 0) {
            await axios.post(`${config.PATH}/api/insert-new-hse-row`, payload);
          }
        }
  
        setSelectedObservations(updatedSelectedObservations);
        setObservations(updatedAllObservations);
      }
  
      // Trim fields in reportData
      const reportData = trimObjectFields({
        report_id: ReportUID,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        area: selectedArea,
        category: selectedCategory,
        background_brief: backgroundBrief,
        contents: contents,
        exe_summary: exeSummary,
        conclusion: conclusion,
        best_practice: bestPractice,
        the_way_forward: theWayForward,
        is_edited: isReportEdited,
        other_details: otherDetails,
        is_saved: 1,
        is_complete: complete === true ? true : false,
        start_date: startDate,
        end_date: endDate,
        sector_type: selectedSector.value,
      });
  
      if (module === "cmv") {
        reportData.type = "cmv";
      } else {
        reportData.type = "primary";
      }
  
      const cmvEndPOint = `${config.PATH}/api/save-update-hse-cmv-report`;
      const reportEndPoint = `${config.PATH}/api/save-update-hse-report`;
  
      if (complete === true) {
        await axios.post(reportEndPoint, reportData);
        await axios.post(cmvEndPOint, reportData);
      }
  
      await axios.post(reportEndPoint, reportData);
  
      // Trim fields in observationsData
      const observationsData = trimObjectFields({
        report_id: ReportUID,
        all_observations: updatedAllObservations,
        organization: selectedOrganization.label,
        site: selectedSite.label,
        user_id: userId,
      });
  
      const observationEndpoint =
        complete === true
          ? `${config.PATH}/api/save-update-hse-cmv-observations`
          : `${config.PATH}/api/save-update-hse-observations`;
  
      await axios.post(observationEndpoint, observationsData);
  
      setIsSaved(true);
      setEditedObservations([]);
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setDisableSaveNext(false);
      getAllHseData();
      saveCriticalObservations(complete);
      saveFacilityInfo();
      setLoading(false);
      updateOrgReportStatus();
      updateSiteReportStatus();
      setEditedFields([]);
      setConfirmationShown(false);
      toast.success(
        `${complete === true ? "Report Completed and Saved" : "Report Saved"}`
      );
    } catch (error) {
      console.log("Error saving report:", error);
      setLoading(false);
    }
  };
  

  // const saveCriticalObservations = async () => {
  //   try {
  //     // Create a new array with objects excluding the 'id' field
  //     const observationsToSave = criticalObservations.map(
  //       ({ id, ...observation }) => observation
  //     );

  //     if (observationsToSave.length === 0) {
  //       console.log("No observations to save.");
  //       return;
  //     }

  //     const payload = {
  //       criticalObservations: observationsToSave,
  //       report_id: ReportUID,
  //     };

  //     await axios.post(
  //       `${config.PATH}/api/save-hse-critical-observations`,
  //       payload
  //     );
  //     console.log("Critical observations saved successfully.");
  //   } catch (error) {
  //     console.error("Error saving critical observations:", error.message);
  //   }
  // };

  const saveCriticalObservations = async (complete) => {
    try {
      // Create a new array with objects excluding the 'id' field
      const observationsToSave = criticalObservations.map(
        ({ id, ...observation }) => observation
      );

      // if (observationsToSave.length === 0) {
      //   console.log("No observations to save.");
      //   return;
      // }

      const payload = {
        criticalObservations: observationsToSave,
        report_id: ReportUID,
      };

      const endpoint =
        complete === true
          ? `${config.PATH}/api/save-critical-hse-cmv-observations`
          : `${config.PATH}/api/save-hse-critical-observations`;

      await axios.post(endpoint, payload);

      console.log("Critical observations saved successfully.");
    } catch (error) {
      console.error("Error saving critical observations:", error.message);
    }
  };

  const closeReport = () => {
    setOpenModal(false);
    setSelectedObservations([]);
    setRecommendations([]);
    setSelectedArea([]);
    setSelectedCategory([]);
    setSelectedOrganization([]);
    setSelectedSite([]);
    setAreasToDisplay([]);
    setCategoriesToDisplay([]);
    setEndDate(null);
    setStartDate(null);
    generateUniqueId();
    getCurrentDateTime();
    setSelectedSector();
    setSelectedParam();
  };

  const handleClose = () => {
    if (isEditing && screenNumber === 8) {
      toast.warning("Please save changes before closing the report.");
      return;
    } else if (!isSaved) {
      toast.warning("Please save the report before closing.");
    } else {
      closeReport();
    }
  };

  // const handleScoreChange = (event, tableType, sr_no) => {
  //   setIsSaved(false);
  //   const newScore = parseInt(event.target.value, 10);

  //   // Validate the new score
  //   if ((newScore >= 0 && newScore <= 5) || event.target.value === "") {
  //     // Clone the observations and selectedObservations arrays
  //     const newObservations = [...observations];
  //     const newSelectedObservations = [...selectedObservations];

  //     // Find the relevant observation in observations using sr_no and tableType
  //     const globalIndex = newObservations.findIndex(
  //       (obs) => obs.table_type === tableType && obs.sr_no === sr_no
  //     );

  //     if (globalIndex !== -1) {
  //       newObservations[globalIndex].score =
  //         event.target.value === "" ? null : newScore;
  //     }

  //     // Find the relevant observation in selectedObservations using sr_no and tableType
  //     const selectedIndex = newSelectedObservations.findIndex(
  //       (obs) => obs.table_type === tableType && obs.sr_no === sr_no
  //     );

  //     if (selectedIndex !== -1) {
  //       newSelectedObservations[selectedIndex].score =
  //         event.target.value === "" ? null : newScore;
  //     }

  //     // Update the state with the modified arrays
  //     setObservations(newObservations);
  //     setSelectedObservations(newSelectedObservations);
  //   } else {
  //     alert("Invalid score. Please enter a value between 0 and 5.");
  //     // Find the observation to restore the original score in the input field
  //     const originalObservation = observations.find(
  //       (obs) => obs.table_type === tableType && obs.sr_no === sr_no
  //     );
  //     event.target.value = originalObservation ? originalObservation.score : "";
  //   }
  // };

  // const handleScoreChange = (event, index) => {
  //   setIsSaved(false);
  //   const newScore = parseInt(event.target.value, 10);

  //   if ((newScore >= 0 && newScore <= 5) || event.target.value === "") {
  //     const newObservations = [...observations];
  //     const newSelectedObservations = [...selectedObservations];

  //     newObservations[index].score =
  //       event.target.value === "" ? null : newScore;
  //     newSelectedObservations[index].score =
  //       event.target.value === "" ? null : newScore;

  //     setObservations(newObservations);
  //     setSelectedObservations(newSelectedObservations);
  //   } else {
  //     alert("Invalid score. Please enter a value between 0 and 5.");
  //     event.target.value = observations[index].score || "";
  //   }
  // };

  const handleScoreChange = (event, tableType, sr_no) => {
    setIsSaved(false);
    const newScore = parseInt(event.target.value, 10);
  
    // Validate the new score
    if ((newScore >= 0 && newScore <= 5) || event.target.value === "") {
      // Clone the observations and selectedObservations arrays
      const newObservations = [...observations];
      const newSelectedObservations = [...selectedObservations];
  
      // Find the relevant observation in observations using either sr_no or id
      const globalIndex = newObservations.findIndex(
        (obs) => obs.table_type === tableType && (obs.sr_no === sr_no || obs.id === sr_no)
      );
  
      if (globalIndex !== -1) {
        newObservations[globalIndex].score =
          event.target.value === "" ? null : newScore;
  
        // Log the updated observation
        console.log("Updated Observation:", newObservations[globalIndex]);
      } else {
        console.log("Observation not found in observations");
      }
  
      // Find the relevant observation in selectedObservations using either sr_no or id
      const selectedIndex = newSelectedObservations.findIndex(
        (obs) => obs.table_type === tableType && (obs.sr_no === sr_no || obs.id === sr_no)
      );
  
      if (selectedIndex !== -1) {
        newSelectedObservations[selectedIndex].score =
          event.target.value === "" ? null : newScore;
  
        // Log the updated selected observation
        console.log("Updated Selected Observation:", newSelectedObservations[selectedIndex]);
      } else {
        console.log("Observation not found in selectedObservations");
      }
      // Update the state with the modified arrays
      setObservations(newObservations);
      setSelectedObservations(newSelectedObservations);
    } else {
      alert("Invalid score. Please enter a value between 0 and 5.");
      // Find the observation to restore the original score in the input field
      const originalObservation = observations.find(
        (obs) => obs.table_type === tableType && (obs.sr_no === sr_no || obs.id === sr_no)
      );  
      event.target.value = originalObservation ? originalObservation.score : "";
    }
  };
  

  const handleDuplicateRow = (table_type, area, identifier) => {
    if (isEditing) {
      toast.warn(
        "Please finish editing the current row before adding a new row."
      );
      return;
    }

    // Find the row to duplicate
    const selectedOriginalRow = selectedObservations.find(
      (obs) =>
        obs.table_type === table_type &&
        obs.area === area &&
        (obs.sr_no === identifier || obs.id === identifier)
    );

    if (!selectedOriginalRow) {
      toast.error("Observation not found.");
      return;
    }

    // Generate a unique identifier based on the current timestamp
    const newSrNo = Date.now() % 1000000; // Using timestamp as unique identifier

    // Create a duplicated row with the new unique identifier
    const duplicatedRowForSelected = {
      ...selectedOriginalRow,
      sr_no: newSrNo,
      refIndex: (selectedOriginalRow.refIndex || 0) + 1, // Adjust refIndex or handle it based on your requirement
      imageUrls: selectedOriginalRow.imageUrls
        ? [...selectedOriginalRow.imageUrls]
        : [],
    };

    // Create copies of state arrays
    const updatedAllObservations = [...observations];
    const updatedSelectedObservations = [...selectedObservations];

    // Insert duplicated row into observations and selectedObservations
    updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
      ...duplicatedRowForSelected,
      is_selected: 1,
    });
    updatedSelectedObservations.splice(
      updatedSelectedObservations.findIndex(
        (obs) =>
          obs.table_type === table_type &&
          obs.area === area &&
          (obs.sr_no === identifier || obs.id === identifier)
      ) + 1,
      0,
      duplicatedRowForSelected
    );

    // Update state
    setObservations(updatedAllObservations);
    setSelectedObservations(updatedSelectedObservations);

    toast.success("New row added");
  };

  // const handleDuplicateRow = (table_type, area, identifier) => {
  //   if (isEditing) {
  //     toast.warn(
  //       "Please finish editing the current row before adding a new row."
  //     );
  //     return;
  //   }

  //   // Find the row to duplicate
  //   const selectedOriginalRow = selectedObservations.find(
  //     (obs) =>
  //       obs.table_type === table_type &&
  //       obs.area === area &&
  //       (obs.sr_no === identifier || obs.id === identifier)
  //   );

  //   if (!selectedOriginalRow) {
  //     toast.error("Observation not found.");
  //     return;
  //   }

  //   // Create a duplicated row
  //   const duplicatedRowForSelected = {
  //     ...selectedOriginalRow,
  //     refIndex: selectedOriginalRow.refIndex + 1, // Adjust refIndex or handle it based on your requirement
  //     imageUrls: selectedOriginalRow.imageUrls
  //       ? [...selectedOriginalRow.imageUrls]
  //       : [],
  //   };

  //   // Create copies of state arrays
  //   const updatedAllObservations = [...observations];
  //   const updatedSelectedObservations = [...selectedObservations];

  //   // Insert duplicated row into observations and selectedObservations
  //   updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
  //     ...duplicatedRowForSelected,
  //     is_selected: 1,
  //   });
  //   updatedSelectedObservations.splice(
  //     updatedSelectedObservations.findIndex(
  //       (obs) =>
  //         obs.table_type === table_type &&
  //         obs.area === area &&
  //         (obs.sr_no === identifier || obs.id === identifier)
  //     ) + 1,
  //     0,
  //     duplicatedRowForSelected
  //   );

  //   // Update state
  //   setObservations(updatedAllObservations);
  //   setSelectedObservations(updatedSelectedObservations);

  //   toast.success("New row added");
  // };

  // const handleDuplicateRow = (index) => {
  //   if (isEditing) {
  //     toast.warn(
  //       "Please finish editing the current row before adding a new row."
  //     );
  //     return;
  //   }

  //   const selectedOriginalRow = selectedObservations[index];

  //   // Check if imageUrls is defined, and create a deep copy if it is, or an empty array otherwise
  //   const duplicatedRowForSelected = {
  //     ...selectedOriginalRow,
  //     refIndex: selectedOriginalRow.refIndex + 1,
  //     imageUrls: selectedOriginalRow.imageUrls
  //       ? [...selectedOriginalRow.imageUrls]
  //       : [],
  //   };

  //   const updatedAllObservations = [...observations];
  //   const updatedSelectedObservations = [...selectedObservations];

  //   updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
  //     ...duplicatedRowForSelected,
  //     is_selected: 1,
  //   });
  //   updatedSelectedObservations.splice(index + 1, 0, duplicatedRowForSelected);

  //   setObservations(updatedAllObservations);
  //   setSelectedObservations(updatedSelectedObservations);

  //   toast.success("New row added");
  // };

  // const handleDeleteRow = (index) => {
  //   if (isEditing && currentEditedRow !== index) {
  //     toast.warn(
  //       "Please finish editing the current row before deleting the row."
  //     );
  //     return;
  //   }
  //   const updatedSelectedObservations = [...selectedObservations];
  //   const updatedAllObservations = [...observations];

  //   // Check if there is only one row left
  //   if (updatedSelectedObservations.length === 1) {
  //     toast.warn("Cannot delete the last row.");
  //     return;
  //   }

  //   const refIndex = selectedObservations[index].refIndex;

  //   updatedSelectedObservations.splice(index, 1);
  //   updatedAllObservations.splice(refIndex, 1);
  //   updatedSelectedObservations.forEach(
  //     (e) => (e.refIndex = e.refIndex > refIndex ? e.refIndex - 1 : e.refIndex)
  //   );
  //   // updatedAllObservations.forEach((e,index) => e.index = e.index>refIndex?e.index-1:e.index)
  //   setSelectedObservations(updatedSelectedObservations);
  //   setObservations(updatedAllObservations);
  //   setIsEditing(false);
  //   setEditedObservations([]);
  //   setCurrentEditedRow(-1);
  //   toast.error("Row deleted");
  // };

  const handleDeleteRow = (table_type, area, identifier) => {
    if (isEditing && currentEditedRow !== -1) {
      toast.warn(
        "Please finish editing the current row before deleting the row."
      );
      return;
    }

    // Create deep copies to avoid mutation
    const updatedSelectedObservations = [...selectedObservations];
    let updatedAllObservations = [...observations]; // Use let instead of const

    // Check if there is only one row left
    if (updatedSelectedObservations.length === 1) {
      toast.warn("Cannot delete the last row.");
      return;
    }

    // Find the index to delete using table_type, area, and identifier (sr_no or id)
    const rowIndex = updatedSelectedObservations.findIndex(
      (obs) =>
        obs.table_type === table_type &&
        obs.area === area &&
        (obs.sr_no === identifier || obs.id === identifier)
    );

    if (rowIndex === -1) {
      toast.error("Row not found.");
      return;
    }

    // Find the observation to delete
    const observationToDelete = updatedSelectedObservations[rowIndex];
    const refIndex = observationToDelete.refIndex;

    // Remove the observation from both arrays
    updatedSelectedObservations.splice(rowIndex, 1);
    updatedAllObservations = updatedAllObservations.filter(
      (obs) =>
        !(
          obs.table_type === table_type &&
          obs.area === area &&
          (obs.sr_no === identifier || obs.id === identifier)
        )
    );

    // Adjust refIndex for the remaining observations
    updatedSelectedObservations.forEach(
      (e) => (e.refIndex = e.refIndex > refIndex ? e.refIndex - 1 : e.refIndex)
    );

    setSelectedObservations(updatedSelectedObservations);
    setObservations(updatedAllObservations);
    setIsEditing(false);
    setEditedObservations([]);
    setCurrentEditedRow(-1);
    toast.error("Row deleted");
  };

  const getScoreColor = (percentage) => {
    if (percentage <= 33) {
      return "#FF0000"; // Red
    } else if (percentage > 33 && percentage <= 66) {
      return "#FFA500"; // Orange
    } else {
      return "#006400"; // Dark Green
    }
  };

  const pieOptions = {
    labels: [
      `Obtained Score(${scorePercent}%)`,
      `Remaining Score(${100 - scorePercent}%)`,
    ],
    colors: [getScoreColor(scorePercent), "grey"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 16,
        height: 16,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const areaCounts = data.reduce((counts, entry) => {
    const area = entry.area;
    counts[area] = (counts[area] || 0) + 1;
    return counts;
  }, {});

  const areasForAreaChart = Object.keys(areaCounts);
  const counts = areasForAreaChart.map((area) => {
    const areaScore = data
      .filter((entry) => entry.area === area)
      .reduce((acc, entry) => acc + (entry.score || 0), 0);
    const totalPossibleScore =
      data.filter((entry) => entry.area === area).length * 5;
    const percentage =
      totalPossibleScore > 0
        ? Math.floor((areaScore / totalPossibleScore) * 100)
        : 0;
    return percentage.toFixed(0);
  });

  const barOptions = {
    chart: {
      id: "bar-chart",
    },
    colors: ["#005cdb"],
    xaxis: {
      categories: areasForAreaChart.map((area) => {
        // Truncate the area name if it's too long
        const maxChars = 15; // Adjust the maximum characters as needed
        return area.length > maxChars
          ? area.substring(0, maxChars) + "..."
          : area;
      }),
      labels: {
        style: {
          fontSize: "10px", // Adjust the font size as needed
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: ["#304758"],
      },
    },
    tooltip: {
      y: {
        formatter: function (value, { dataPointIndex }) {
          // Display the full area name on hover
          const area = areasForAreaChart[dataPointIndex];
          return `${area}: ${value}%`; // Show area name along with percentage
        },
      },
    },
  };

  // Group the data by area and severity
  const areaSeverityData = data.reduce((result, entry) => {
    const area = entry.area;
    const severity = entry.criticality;

    if (!result[area]) {
      result[area] = { High: 0, Medium: 0, Low: 0 };
    }

    result[area][severity] += 1;
    return result;
  }, {});

  // Extract areas and severity counts
  const areas = Object.keys(areaSeverityData);
  const severityChartData = Object.keys(areaSeverityData).map((area) => ({
    name: area,
    High: areaSeverityData[area].High,
    Medium: areaSeverityData[area].Medium,
    Low: areaSeverityData[area].Low,
  }));

  // Transpose the data to match the series structure
  const transposedData = {
    High: [],
    Medium: [],
    Low: [],
  };

  severityChartData.forEach((area) => {
    transposedData.High.push(area.High);
    transposedData.Medium.push(area.Medium);
    transposedData.Low.push(area.Low);
  });

  // Prepare series data with specific colors for each severity level
  const seriesData = [
    { name: "High", data: [] },
    { name: "Medium", data: [] },
    { name: "Low", data: [] },
  ];

  areas.forEach((area) => {
    seriesData[0].data.push(areaSeverityData[area]?.High || 0);
    seriesData[1].data.push(areaSeverityData[area]?.Medium || 0);
    seriesData[2].data.push(areaSeverityData[area]?.Low || 0);
  });

  // Severity chart options
  const severityChartOptions = {
    chart: {
      id: "severity-chart",
      stacked: true,
    },
    xaxis: {
      categories: areas.map((area) => {
        // Truncate the area name if it's too long
        const maxChars = 15; // Adjust the maximum characters as needed
        return area.length > maxChars
          ? area.substring(0, maxChars) + "..."
          : area;
      }),
      labels: {
        style: {
          fontSize: "10px", // Adjust the font size as needed
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "10px", // Adjust the font size for the total data label
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels
    },
    colors: ["#FF0000", "#006400", "#005cdb"],
    tooltip: {
      y: {
        formatter: function (value, { dataPointIndex }) {
          // Display the full area name on hover
          const area = areas[dataPointIndex];
          return `${area}:${value}`;
        },
      },
    },
  };

  const handleObservationEdit = (index, e) => {
    setDisableSaveNext(true);
    const updatedObservations = [...criticalObservations];
    updatedObservations[index].observation = e.target.value;
    setCriticalObservations(updatedObservations);
  };

  const updateOrgReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      axios.post(`${config.PATH}/api/update-hse-org-report-status/${org_id}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateSiteReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      const site_name = selectedSite.value;
      axios.post(
        `${config.PATH}/api/update-hse-site-report-status/${org_id}/${site_name}`
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleComplete = async () => {
    if (!isSaved) {
      toast.warning("Please save the report before marking as complete.");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to mark this report as complete? Once done action cannot be reversed and you cannot edit the report.`
    );

    if (!userConfirmed) {
      return;
    }
    setIsComplete(true);
    handleSave(true);
    closeReport();
  };

  // const uniqueCategories = new Set();
  // const categoryOptions = [];

  // allData.data.forEach((e) => {
  //   if (!uniqueCategories.has(e.category)) {
  //     categoryOptions.push({ value: e.category, label: e.category });
  //     uniqueCategories.add(e.category);
  //   }
  // });

  useEffect(() => {
    const uniqueCategories = new Set();
    const filteredOptions = [];

    allData.data.forEach((e) => {
      if (area.includes(e.area) && !uniqueCategories.has(e.category)) {
        filteredOptions.push({ value: e.category, label: e.category });
        uniqueCategories.add(e.category);
      }
    });

    setCategoryOptions(filteredOptions);
  }, [getAllHseData, area]);

  const customSelectStylesCreatable = {
    control: (provided) => ({
      ...provided,
      // borderBottom: "none", // Hide the separator
      boxShadow: "none", // Hide the box shadow
      cursor: "pointer", // Show the pointer cursor
      width: "150px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      borderLeft: 0, // Hide the vertical separator beside the dropdown indicator
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index
      position: "absolute",
    }),
  };

  const closeVariantModal = () => {
    setOpenVariantModal(false);
  };

  // const handleConfirmSelection = (selectedVariants, removedItems) => {
  //   let map = {};
  //   // Update both states with the selected variants
  //   setSelectedObservations(
  //     [...selectedObservations, ...selectedVariants].filter((e) => {
  //       if (!(map[e.sr_no] || removedItems.includes(e.sr_no))) {
  //         map[e.sr_no] = true;
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //   );
  //   map = {};
  //   setObservations(
  //     [...observations, ...selectedVariants].filter((e) => {
  //       if (!(map[e.sr_no] || removedItems.includes(e.sr_no))) {
  //         map[e.sr_no] = true;
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //   );
  // };

  const handleConfirmSelection = (selectedVariants, removedItems) => {
    let map = {};

    const getId = (item) => item.sr_no || item.id;

    // Update both states with the selected variants
    setSelectedObservations(
      [...selectedObservations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );

    map = {};
    setObservations(
      [...observations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );
  };

  const getObservationVariants = async (observation, index) => {
    try {
      if (isEditing && currentEditedRow !== index) {
        toast.warn("Please finish editing the current row.");
        return;
      }
      setOpenVariantModal(true);
      const payload = {
        observation: observation,
        report: "new",
      };
      const response = await axios.post(
        `${config.PATH}/api/search-hse-data-by-observation`,
        payload
      );
      setObservationVariants(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const criticalityOptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const groupedObservations = selectedObservations.reduce(
    (acc, observation) => {
      if (!acc[observation.table_type]) {
        acc[observation.table_type] = [];
      }
      acc[observation.table_type].push(observation);
      return acc;
    },
    {}
  );

  const saveFacilityInfo = async () => {
    try {
      const payload = {
        report_id: ReportUID,
        facility_info: facilityInfo,
      };
      const res = await axios.post(
        `${config.PATH}/api/save-facility-info`,
        payload
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleRemoveField = (key) => {
    if (Object.keys(facilityInfo).length === 1) {
      alert("Cannot remove the last field.");
      return;
    }
  
    const updatedFacilityInfo = { ...facilityInfo };
    delete updatedFacilityInfo[key];
    setFacilityInfo(updatedFacilityInfo);
  };

  const handleChangeNewRow = (tableType, e, field) => {

    if (field === "score" && (e.target.value < 0 || e.target.value > 5)) {
      alert("Invalid score. Please enter a value between 0 and 5.");
      return; // Exit the function if the score is invalid
    }

    if (
      (field === "area" || field === "category" || field === "criticality") &&
      e.target.value.length > 100
    ) {
      alert(`${field} cannot exceed 100 characters.`);
      return; // Do not update the state if validation fails
    }

    setNewRowInputs((prev) => ({
      ...prev,
      [tableType]: {
        ...prev[tableType],
        [field]: e.target.value,
      },
    }));
  };

  // const handleImageUploadNewRow = (tableType, files) => {
  //   const fileUrls = Array.from(files).map((file) => URL.createObjectURL(file));
  //   setNewRowInputs((prev) => ({
  //     ...prev,
  //     [tableType]: {
  //       ...prev[tableType],
  //       imageUrls: [...prev[tableType].imageUrls, ...fileUrls]
  //     }
  //   }));
  // };

  //   const handleAddRow = (tableType) => {
  //     // Create the new row with a fixed key and unique ID
  //     const newRow = {
  //       ...newRowInputs[tableType],
  //       table_type: tableType,
  //       is_selected:1,
  //       sector_type:selectedSector.value
  //     };

  //     const handleAddRow = (tableType) => {
  //   // Create the new row with a fixed key and unique ID
  //   const newRow = {
  //     ...newRowInputs[tableType],
  //     table_type: tableType,
  //     is_selected: 1,
  //     sector_type: selectedSector.value
  //   };

  //   // Replace empty fields with "N/A"
  //   const updatedRow = {
  //     ...newRow,
  //     area: newRow.area?.trim() || "N/A",
  //     category: newRow.category?.trim() || "N/A",
  //     check_points: newRow.check_points?.trim() || "N/A",
  //     observation: newRow.observation?.trim() || "N/A",
  //     criticality: newRow.criticality?.trim() || "N/A",
  //     recommendations: newRow.recommendations?.trim() || "N/A",
  //     is_reference: newRow.is_reference?.trim() || "N/A",
  //     imageUrls: newRow.imageUrls?.length > 0 ? newRow.imageUrls : [],
  //   };

  //   // Validate the area and observation fields
  //   if (!updatedRow.area || !updatedRow.observation) {
  //     alert("Area and Observation fields cannot be empty.");
  //     return;
  //   }

  //   // Update the state by pushing the new row into the array
  //   setSelectedObservations((prev) => [
  //     ...prev,  // Spread the existing rows
  //     updatedRow // Add the new row
  //   ]);

  //   setObservations((prev) => [
  //     ...prev,  // Spread the existing rows
  //     updatedRow // Add the new row
  //   ]);

  //   // Clear the new row inputs
  //   initializeNewRowInputs(tableType, selectedSector.value);
  // };

  //   // Validate the area and observation fields
  //   if (!newRow.area || !newRow.observation || !newRow.area.trim() || !newRow.observation.trim()) {
  //     alert("Area and Observation fields cannot be empty.");
  //     return;
  //   }
  //     // Update the state by pushing the new row into the array
  //     setSelectedObservations((prev) => [
  //       ...prev,  // Spread the existing rows
  //       newRow    // Add the new row
  //     ]);

  //     setObservations((prev) => [
  //       ...prev,  // Spread the existing rows
  //       newRow    // Add the new row
  //     ]);

  //     // Clear the new row inputs
  //     initializeNewRowInputs(tableType, selectedSector.value);
  //   };

  // const handleAddRow = (tableType) => {
  //   // Create the new row with a fixed key and unique ID
  //   const newRow = {
  //     ...newRowInputs[tableType],
  //     table_type: tableType,
  //     is_selected: 1,
  //     sector_type: selectedSector.value
  //   };

  //     // Replace empty fields with "N/A"
  //     const updatedRow = {
  //       ...newRow,
  //       area: newRow.area,
  //       category: newRow.category?.trim() || "N/A",
  //       check_points: newRow.check_points?.trim() || "N/A",
  //       observation: newRow.observation,
  //       criticality: newRow.criticality?.trim() || "N/A",
  //       recommendations: newRow.recommendations,
  //       is_reference: newRow.is_reference,
  //       score: newRow.score || 0,
  //       imageUrls: newRow.imageUrls?.length > 0 ? newRow.imageUrls : [],
  //     };

  //     // Validate the area and observation fields
  //     if (!updatedRow.area || !updatedRow.observation || !updatedRow.recommendations || !updatedRow.is_reference) {
  //       alert("Area, Observation, Recommendation and IS Reference fields cannot be empty.");
  //       return;
  //     }

  //   // Update the state by pushing the new row into the array
  //   setSelectedObservations((prev) => [
  //     ...prev,  // Spread the existing rows
  //     updatedRow // Add the new row
  //   ]);

  //   setObservations((prev) => [
  //     ...prev,  // Spread the existing rows
  //     updatedRow // Add the new row
  //   ]);

  //   // Clear the new row inputs
  //   initializeNewRowInputs(tableType, selectedSector.value);
  // };

  const handleAddRow = (tableType) => {
    // Generate a unique sr_no
    const newSrNo = Date.now() % 1000000;

    // Create the new row with a fixed key and unique ID
    const newRow = {
      ...newRowInputs[tableType],
      sr_no: newSrNo,
      table_type: tableType,
      is_selected: 1,
      sector_type: selectedSector.value,
    };

    // Replace empty fields with "N/A"
    const updatedRow = {
      ...newRow,
      area: newRow.area,
      category: newRow.category?.trim() || "N/A",
      check_points: newRow.check_points?.trim() || "N/A",
      observation: newRow.observation,
      criticality: newRow.criticality?.trim() || "N/A",
      recommendations: newRow.recommendations?.trim() || "N/A",
      is_reference: newRow.is_reference?.trim() || "N/A",
      score: parseInt(newRow.score, 10) || 0,
      imageUrls: newRow.imageUrls?.length > 0 ? newRow.imageUrls : [],
    };

    // Validate the area, observation, recommendations, and is_reference fields
    if (
      !updatedRow.area ||
      !updatedRow.observation ||
      !updatedRow.recommendations ||
      !updatedRow.is_reference
    ) {
      alert(
        "Area, Observation, Recommendation, and IS Reference fields cannot be empty."
      );
      return;
    }

    setEditedObservations((prevObservations) => [...prevObservations, updatedRow]);
    setIsReportEdited(true);

    // Update the state by pushing the new row into the array
    setSelectedObservations((prev) => [
      ...prev, // Spread the existing rows
      updatedRow, // Add the new row
    ]);

    setObservations((prev) => [
      ...prev, // Spread the existing rows
      updatedRow, // Add the new row
    ]);

    // Clear the new row inputs
    initializeNewRowInputs(tableType, selectedSector.value);
  };

  const renderNewRowInputs = (tableType) => (
    <TableRow key={`new-row-${tableType}`}>
      <TableCell>{groupedObservations[tableType]?.length + 1}</TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.area || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "area")}
          placeholder="Area"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.check_points || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "check_points")}
          placeholder="Check Point"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.observation || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "observation")}
          placeholder="Observation"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.criticality || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "criticality")}
          placeholder="Criticality"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.recommendations || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "recommendations")}
          placeholder="Recommendations"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.is_reference || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "is_reference")}
          placeholder="IS Reference"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <input
          // className="input-field"
          value={newRowInputs[tableType]?.score || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "score")}
          placeholder="Score"
          type="number"
          style={{height:"31px", marginTop:"-2%"}}
        />
      </TableCell>
      <TableCell>
        <div className="image-container">
          <div className="upload-container">
            <input
              type="file"
              accept="image/*"
              // onChange={(e) => handleImageUploadNewRow(tableType, e.target.files)}
              multiple
              style={{ color: "transparent" }}
              disabled={true}
            />
            {newRowInputs[tableType]?.imageUrls?.length === 0 && (
              <div className="no-file-chosen">No file chosen</div>
            )}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CheckIcon
            onClick={() => handleAddRow(tableType)}
            style={{ cursor: "pointer", color: "green" }}
          />
        </div>
      </TableCell>
    </TableRow>
  );

  if (screenNumber === 1) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">COVER PAGE</div>
                    <br />
                    <div className="image-container">
                      <img src={HSE_Cover} alt="Image" className="image" />
                      <div className="hse-cover-details">
                        <p className="cover-text">
                          {selectedOrganization.label}
                        </p>
                        <p className="cover-text">HSE</p>
                        {/* <p className="cover-text">
                          {`${startDate.getDate()}-${
                            startDate.getMonth() + 1
                          }-${startDate.getFullYear()} to ${endDate.getDate()}-${
                            endDate.getMonth() + 1
                          }-${endDate.getFullYear()}`}
                        </p> */}
                        <p className="cover-text">
                          {startDate.getTime() === endDate.getTime()
                            ? `${startDate.getDate()}-${
                                startDate.getMonth() + 1
                              }-${startDate.getFullYear()}`
                            : `${startDate.getDate()}-${
                                startDate.getMonth() + 1
                              }-${startDate.getFullYear()} to ${endDate.getDate()}-${
                                endDate.getMonth() + 1
                              }-${endDate.getFullYear()}`}
                        </p>
                        <p className="cover-text">{selectedSite.label}</p>

                        <a
                          style={{ textTransform: "lowercase" }}
                          href="https://www.momentumindia.in/"
                        >
                          www.momentumindia.in
                        </a>
                      </div>
                    </div>
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handleEdit}
                  style={{ background: "#efc71d" }}
                >
                  Back
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 2) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      BACKGROUND &#8208; PROJECT BRIEF
                    </div>
                    <br />
                    <TextareaAutosize
                      value={backgroundBrief}
                      onChange={(e) => handleChange(e, "background")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 3) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      UNDERSTANDING OF THE REVIEW REPORT &#8208; CONTENTS.
                    </div>
                    <br />
                    <TextareaAutosize
                      value={contents}
                      onChange={(e) => handleChange(e, "contents")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 4) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">INTRODUCTION</div>
                    <br />
                    <TextareaAutosize
                      value={introduction}
                      onChange={(e) => handleChange(e, "intro")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 5) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">EXECUTIVE SUMMARY</div>
                    <br />
                    <TextareaAutosize
                      value={exeSummary}
                      onChange={(e) => handleChange(e, "exe")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 6) {
    return (
      // <Modal open={true} onClose={handleClose}>
      //   <div className="review-modal-container">
      //     <div className="review-modal-content">
      //       <div className="review-modal-header">
      //         <Typography variant="h5">Preview Report</Typography>
      //         <button className="custom-close-button" onClick={handleClose}>
      //           &#10005; {/* Unicode for 'X' */}
      //         </button>
      //       </div>
      //       <div className="review-modal-body" style={{ overflowY: "auto" }}>
      //         <Typography variant="body1">
      //           <div className="sub-headings">ACADEMIC INFORMATION</div>
      //           <br />
      //           <TableContainer component={Paper}>
      //             <Table>
      //               <TableBody>
      //                 {facilityInfo &&
      //                   Object.entries(facilityInfo).map(
      //                     ([key, value], index) => (
      //                       <TableRow key={index}>
      //                         <TableCell>{key}:</TableCell>
      //                         <TableCell>
      //                           <ReactQuill
      //                             value={value}
      //                             onChange={(value) =>
      //                               handleChangeFacilityInfo({
      //                                 target: { name: key, value },
      //                               })
      //                             }
      //                             placeholder="Enter your text here"
      //                           />
      //                         </TableCell>
      //                       </TableRow>
      //                     )
      //                   )}

      //                 <TableRow>
      //                   <TableCell>
      //                     <TextField
      //                       label="New Field Name"
      //                       value={newKey}
      //                       onChange={handleNewKeyChange}
      //                       variant="outlined"
      //                       style={{ width: "100%" }}
      //                     />
      //                   </TableCell>
      //                   <TableCell>
      //                     <div
      //                       style={{ display: "flex", alignItems: "center" }}
      //                     >
      //                       <ReactQuill
      //                         value={newValue}
      //                         onChange={(value) =>
      //                           handleNewValueChange({ target: { value } })
      //                         }
      //                         placeholder="Enter your text here"
      //                         style={{ width: "100%" }}
      //                       />
      //                       <button
      //                         onClick={handleAddNewField}
      //                         className="button-styles"
      //                         style={{
      //                           background: "#efc71d",
      //                           marginLeft: "10px",
      //                           height: "56px",
      //                         }}
      //                       >
      //                         Add Field
      //                       </button>
      //                     </div>
      //                   </TableCell>
      //                 </TableRow>
      //               </TableBody>
      //             </Table>
      //           </TableContainer>
      //         </Typography>
      //       </div>
      //       <hr />
      //       <div className="review-modal-footer">
      //         <button
      //           className="button-styles"
      //           onClick={handleSave}
      //           style={{ background: "#efc71d" }}
      //         >
      //           Save
      //         </button>
      //         <button
      //           className="button-styles"
      //           onClick={handlePrev}
      //           style={{ background: "#efc71d" }}
      //         >
      //           &#171; Prev
      //         </button>
      //         <button
      //           className="button-styles"
      //           color="primary"
      //           onClick={handleNext}
      //           style={{ background: "#efc71d" }}
      //         >
      //           Next &#187;
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      // </Modal>
      <Modal open={true} onClose={handleClose}>
  <div className="review-modal-container">
    <div className="review-modal-content">
      <div className="review-modal-header">
        <Typography variant="h5">Preview Report</Typography>
        <button className="custom-close-button" onClick={handleClose}>
          &#10005; {/* Unicode for 'X' */}
        </button>
      </div>
      <div className="review-modal-body" style={{ overflowY: "auto" }}>
        <Typography variant="body1">
          <div className="sub-headings">ACADEMIC INFORMATION</div>
          <br />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>FACILITY INFORMATION</TableCell>
                  <TableCell>COMMENTS & NOTES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facilityInfo &&
                  Object.entries(facilityInfo).map(([key, value], index) => (
                    <TableRow key={index}>
                      <TableCell>{key}:</TableCell>
                      <TableCell style={{display:"flex"}}>
                        <textarea
                          value={value}
                          onChange={(e) =>
                            handleChangeFacilityInfo({
                              target: { name: key, value: e.target.value },
                            })
                          }
                          placeholder="Enter your text here"
                          style={{ width: "100%", minHeight: "80px" }}
                        />
                        <IconButton
              onClick={() => handleRemoveField(key)}
              style={{ color: "red", marginLeft: "10px" }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell>
                    <TextField
                      label="New Field Name"
                      value={newKey}
                      onChange={handleNewKeyChange}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <textarea
                        value={newValue}
                        onChange={(e) =>
                          handleNewValueChange({ target: { value: e.target.value } })
                        }
                        placeholder="Enter your text here"
                        style={{ width: "100%", minHeight: "80px" }}
                      />
                      <button
                        onClick={handleAddNewField}
                        className="button-styles"
                        style={{
                          background: "#efc71d",
                          marginLeft: "10px",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </div>
      <hr />
      <div className="review-modal-footer">
        <button
          className="button-styles"
          onClick={handleSave}
          style={{ background: "#efc71d" }}
        >
          Save
        </button>
        <button
          className="button-styles"
          onClick={handlePrev}
          style={{ background: "#efc71d" }}
        >
          &#171; Prev
        </button>
        <button
          className="button-styles"
          color="primary"
          onClick={handleNext}
          style={{ background: "#efc71d" }}
        >
          Next &#187;
        </button>
      </div>
    </div>
  </div>
</Modal>

    );

    // return (
    //   <Modal open={open} onClose={handleClose}>
    //     <div className="review-modal-container">
    //       <div className="review-modal-content">
    //         <div className="review-modal-header">
    //           <Typography variant="h5">Preview Report</Typography>
    //           <button className="custom-close-button" onClick={handleClose}>
    //             &#10005; {/* Unicode for 'X' */}
    //           </button>
    //         </div>
    //         <div className="review-modal-body" style={{ overflowY: "auto" }}>
    //           <Typography variant="body1">
    //             <div className="sub-headings">FACILITY INFORMATION</div>
    //             <br />
    //             <TableContainer component={Paper}>
    //               <Table>
    //                 <TableBody>
    //                   <TableRow>
    //                     <TableCell style={{ fontSize: "16px" }}>
    //                       Facility information
    //                     </TableCell>
    //                     <TableCell style={{ fontSize: "16px" }}>
    //                       Comments & Notes
    //                     </TableCell>
    //                   </TableRow>

    //                   <TableRow>
    //                     <TableCell>Name of Facility:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.name}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "name")
    //                         }
    //                         placeholder="Enter your text here"
    //                         // style={{ width: '100%', background: "whitesmoke", maxHeight:"5vh", overflow:"scroll" }}
    //                         className="facility-info-text-area"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Address and Location:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.address}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "address")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>
    //                       Geographical Co-ordinates: Seismic Zone
    //                     </TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.coordinates}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "coordinates")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Brief Property Description:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.propertyDescription}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "propertyDescription")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Type of Construction:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.constructionType}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "constructionType")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Average Worker Foot Fall:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.workerFootFall}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "workerFootFall")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>No Objection Certificate:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.noc}
    //                         onChange={(e) => handleChangeFacilityInfo(e, "noc")}
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Reference enforcement guidelines:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.guidelines}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "guidelines")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>National Building Code category:</TableCell>
    //                     <TableCell>
    //                       <TextareaAutosize
    //                         value={facilityInfo.category}
    //                         onChange={(e) =>
    //                           handleChangeFacilityInfo(e, "category")
    //                         }
    //                         placeholder="Enter your text here"
    //                         style={{ width: "100%", background: "whitesmoke" }}
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                 </TableBody>
    //               </Table>
    //             </TableContainer>
    //           </Typography>
    //         </div>
    //         <hr />
    //         <div className="review-modal-footer">
    //           <button
    //             className="button-styles"
    //             onClick={handleSave}
    //             style={{ background: "#efc71d" }}
    //           >
    //             Save
    //           </button>
    //           <button
    //             className="button-styles"
    //             onClick={handlePrev}
    //             style={{ background: "#efc71d" }}
    //           >
    //             &#171; Prev
    //           </button>
    //           <button
    //             className="button-styles"
    //             color="primary"
    //             onClick={handleNext}
    //             style={{ background: "#efc71d" }}
    //           >
    //             Next &#187;
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </Modal>
    // );
  } else if (screenNumber === 7) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CRITICAL OBSERVATIONS</div>
                    <br />
                    <div className="critical-observations-div">
                      {criticalObservations.length === 0 ? (
                        <div className="no-observations">
                          <em>No critical observations</em>
                        </div>
                      ) : (
                        criticalObservations.map((observation, index) => (
                          <div key={index} className="observation-item">
                            {/* <li>
                              <span>{observation.observation}</span>
                            </li> */}
                            <textarea
                              onChange={(e) => handleObservationEdit(index, e)}
                              style={{ width: "100%", fontFamily: "inherit" }}
                              value={observation.observation}
                            />
                            &nbsp;
                            <CancelIcon
                              onClick={() => removeItem(index)}
                              className="cancel-icon"
                            >
                              &#10005;
                            </CancelIcon>
                          </div>
                        ))
                      )}
                    </div>
                    <br />
                    <textarea
                      onChange={(e) => handleChange(e, "other details")}
                      value={otherDetails}
                      placeholder="Other Details..."
                      style={{
                        width: "99%",
                        height: "10vh",
                        fontFamily: "inherit",
                      }}
                    />
                  </div>
                </Typography>
              </div>

              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  disabled={disableSaveNext}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  disabled={disableSaveNext}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 8) {
    return (
      <>
      <VariantsModal
          data={observationVariants}
          open={openVairantModal}
          handleClose={closeVariantModal}
          handleConfirmSelection={handleConfirmSelection}
        />
        <ImageViewerModal
          imageUrl={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
        <Modal open={open} onClose={handleClose}>
        <div className="review-modal-container">
          <div className="review-modal-content">
            <div className="review-modal-header">
              <Typography variant="h5">Preview Report</Typography>
              <button className="custom-close-button" onClick={handleClose}>
                &#10005; {/* Unicode for 'X' */}
              </button>
            </div>
            <div style={{ fontWeight: "600" }} className="sub-headings">
              CRITICAL HSE OBSERVATIONS, PHOTOS & RECOMMENDATIONS
            </div>
            <div style={{ overflowY: "scroll" }} className="review-modal-body">
              <div className="table-container">
                {Object.keys(groupedObservations).map((tableType) => (
                  <div key={tableType}>
                    <Typography style={{ fontWeight: "500" }} gutterBottom>
                      {tableType}
                    </Typography>
                    <TableContainer component={Paper} className="table-scroll">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sr. No.</TableCell>
                            <TableCell>Areas</TableCell>
                            {/* <TableCell>Categories</TableCell> */}
                            <TableCell>Check Point</TableCell>
                            <TableCell>Observation</TableCell>
                            <TableCell>Criticality</TableCell>
                            <TableCell>Recommendation</TableCell>
                            <TableCell>IS Reference</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Photo Evidences</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupedObservations[tableType].map(
                            (observation, index) => (
                              // <TableRow
                              //   key={`${observation.table_type}-${
                              //     observation.area
                              //   }-${observation.sr_no || observation.id}`}
                              //   className={
                              //     (isEditing &&
                              //       currentEditedRow ===
                              //         selectedObservations.findIndex(
                              //           (obs) =>
                              //             obs.table_type ===
                              //               observation.table_type &&
                              //             obs.area === observation.area &&
                              //             (obs.sr_no === observation.sr_no ||
                              //               obs.id === observation.id)
                              //         )) ||
                              //     !isEditing
                              //       ? "even-row"
                              //       : "odd-row"
                              //   }
                              //   style={
                              //     observation.variant === true
                              //       ? { backgroundColor: "#f2f2f2" }
                              //       : {}
                              //   }
                              // >
                              <TableRow
                                key={`${observation.table_type}-${observation.area}-${observation.sr_no || observation.id}`}
                                className={
                                  (isEditing &&
                                    currentEditedRow === (observation.sr_no || observation.id )) ||
                                  !isEditing
                                    ? "even-row"
                                    : "odd-row"
                                }
                                style={
                                  observation.variant === true
                                    ? { backgroundColor: "#f2f2f2" }
                                    : {}
                                }
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell
                                  className="editable-cell"
                                  style={{ height: "100px" }}
                                >
                                  <div
                                    className="cell-content"
                                    // style={{
                                    //   color:
                                    //     (isEditing &&
                                    //       currentEditedRow !==
                                    //         selectedObservations.findIndex(
                                    //           (obs) =>
                                    //             obs.table_type ===
                                    //               observation.table_type &&
                                    //             obs.area === observation.area &&
                                    //             (obs.sr_no ===
                                    //               observation.sr_no ||
                                    //               obs.id === observation.id)
                                    //         )) ||
                                    //     observation.variant === true
                                    //       ? "grey"
                                    //       : "black",
                                    // }}
                                    style={{
                                      color:
                                        (isEditing &&
                                          currentEditedRow !==
                                            (observation.sr_no ||
                                              observation.id)) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                  >
                                    <CreatableSelect
                                      styles={customSelectStylesCreatable}
                                      placeholder="Area"
                                      options={areaOptions()}
                                      defaultValue={{
                                        label: observation.area,
                                        value: observation.area,
                                      }}
                                      isSearchable
                                      onChange={(e) =>
                                        handleCellEdit(
                                          e,
                                          "area",
                                          observation.area,
                                          observation
                                        )
                                      }
                                      // isDisabled={
                                      //   (isEditing &&
                                      //     currentEditedRow !==
                                      //       selectedObservations.findIndex(
                                      //         (obs) =>
                                      //           obs.table_type ===
                                      //             observation.table_type &&
                                      //           obs.area === observation.area &&
                                      //           (obs.sr_no ===
                                      //             observation.sr_no ||
                                      //             obs.id === observation.id)
                                      //       )) ||
                                      //   observation.variant === true
                                      // }
                                      isDisabled={
                                        (isEditing &&
                                          currentEditedRow !==
                                            (observation.sr_no ||
                                              observation.id)) ||
                                        observation.variant === true
                                      }
                                    />
                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell>

                                {/* Repeat similar structure for other fields */}

                                <TableCell
                                  className="editable-cell"
                                  style={{ height: "100px" }}
                                >
                                  <div
                                    className="cell-content"
                                    // style={{
                                    //   color:
                                    //     (isEditing &&
                                    //       currentEditedRow !==
                                    //         selectedObservations.findIndex(
                                    //           (obs) =>
                                    //             obs.table_type ===
                                    //               observation.table_type &&
                                    //             obs.area === observation.area &&
                                    //             (obs.sr_no ===
                                    //               observation.sr_no ||
                                    //               obs.id === observation.id)
                                    //         )) ||
                                    //     observation.variant === true
                                    //       ? "grey"
                                    //       : "black",
                                    // }}
                                    style={{
                                      color:
                                        (isEditing && currentEditedRow !== (observation.sr_no || observation.id)) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                    
                                  >
                                    {/* <span
                                      contentEditable={
                                        !observation.variant &&
                                        ((isEditing &&
                                          currentEditedRow ===
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                          !isEditing)
                                      }
                                      onInput={(e) =>
                                        handleCellEdit(
                                          e,
                                          "check_points",
                                          observation.check_points,
                                          observation
                                        )
                                      }
                                    >
                                      {observation.check_points}
                                    </span> */}
                                    <span
  contentEditable={
    !observation.variant &&
    ((isEditing && currentEditedRow === (observation.sr_no || observation.id)) ||
    !isEditing)
  }
  onInput={(e) =>
    handleCellEdit(
      e,
      "check_points",
      observation.check_points,
      observation
    )
  }
>
  {observation.check_points}
</span>

                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      onClick={(e) =>
                                        isEditing
                                          ? handleCellEdit(
                                              e,
                                              "check_points",
                                              observation.check_points
                                                ? observation.check_points
                                                : "Check Point",
                                              observation
                                            )
                                          : null
                                      }
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell>

                                {/* <TableCell
                                  className="editable-cell"
                                  style={{ height: "100px" }}
                                >
                                  <div
                                    className="cell-content"
                                    style={{
                                      color:
                                        (isEditing &&
                                          currentEditedRow !==
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                  >
                                    <span
                                      contentEditable={
                                        !observation.variant &&
                                        ((isEditing &&
                                          currentEditedRow ===
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                          !isEditing)
                                      }
                                      onInput={(e) =>
                                        handleCellEdit(
                                          e,
                                          "observation",
                                          observation.observation,
                                          observation
                                        )
                                      }
                                    >
                                      {observation.observation}
                                    </span>
                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      onClick={(e) =>
                                        isEditing
                                          ? handleCellEdit(
                                              e,
                                              "observation",
                                              observation.observation,
                                              observation
                                            )
                                          : null
                                      }
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell> */}
                                <TableCell className="editable-cell" style={{ height: "100px" }}>
  <div
    className="cell-content"
    style={{
      color:
        (isEditing && currentEditedRow !== (observation.sr_no || observation.id)) ||
        observation.variant === true
          ? "grey"
          : "black",
    }}
  >
    <span
      contentEditable={
        !observation.variant &&
        ((isEditing && currentEditedRow === (observation.sr_no || observation.id)) ||
        !isEditing)
      }
      onInput={(e) =>
        handleCellEdit(
          e,
          "observation",
          observation.observation,
          observation
        )
      }
    >
      {observation.observation}
    </span>
  </div>
  {!observation.variant && (
    <EditOutlinedIcon
      onClick={(e) =>
        isEditing
          ? handleCellEdit(
              e,
              "observation",
              observation.observation,
              observation
            )
          : null
      }
      className="edit-icon"
      fontSize="small"
    />
  )}
</TableCell>


                                {/* <TableCell
                                  className="editable-cell"
                                  style={{ height: "100px" }}
                                >
                                  <div
                                    className="cell-content"
                                    style={{
                                      color:
                                        (isEditing &&
                                          currentEditedRow !==
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                  >
                                    <Select
                                      styles={customSelectStylesCreatable}
                                      placeholder="Criticality"
                                      options={criticalityOptions}
                                      defaultValue={{
                                        label: observation.criticality,
                                        value: observation.criticality,
                                      }}
                                      isSearchable
                                      onChange={(e) =>
                                        handleCellEdit(
                                          e,
                                          "criticality",
                                          observation.criticality,
                                          observation
                                        )
                                      }
                                      isDisabled={
                                        (isEditing &&
                                          currentEditedRow !==
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                        observation.variant === true
                                      }
                                    />
                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell> */}

                                <TableCell className="editable-cell" style={{ height: "100px" }}>
                                  <div
                                    className="cell-content"
                                    style={{
                                      color:
                                        (isEditing && currentEditedRow !== (observation.sr_no || observation.id)) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                  >
                                    <Select
                                      styles={customSelectStylesCreatable}
                                      placeholder="Criticality"
                                      options={criticalityOptions}
                                      defaultValue={{
                                        label: observation.criticality,
                                        value: observation.criticality,
                                      }}
                                      isSearchable
                                      onChange={(e) =>
                                        handleCellEdit(
                                          e,
                                          "criticality",
                                          observation.criticality,
                                          observation
                                        )
                                      }
                                      isDisabled={
                                        (isEditing && currentEditedRow !== (observation.sr_no || observation.id)) ||
                                        observation.variant === true
                                      }
                                    />
                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell>

{/* 
                                <TableCell className="editable-cell">
                                  <div
                                    className="cell-content"
                                    style={{
                                      color:
                                        (isEditing &&
                                          currentEditedRow !==
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                  >
                                    <span
                                      contentEditable={
                                        !observation.variant &&
                                        ((isEditing &&
                                          currentEditedRow ===
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                          !isEditing)
                                      }
                                      onInput={(e) =>
                                        handleCellEdit(
                                          e,
                                          "recommendations",
                                          observation.recommendations,
                                          observation
                                        )
                                      }
                                    >
                                      {observation.recommendations}
                                    </span>
                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      onClick={(e) =>
                                        isEditing
                                          ? handleCellEdit(
                                              e,
                                              "recommendations",
                                              observation.recommendations,
                                              observation
                                            )
                                          : null
                                      }
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell> */}

<TableCell className="editable-cell">
  <div
    className="cell-content"
    style={{
      color:
        (isEditing && currentEditedRow !== (observation.sr_no || observation.id)) ||
        observation.variant === true
          ? "grey"
          : "black",
    }}
  >
    <span
      contentEditable={
        !observation.variant &&
        ((isEditing && currentEditedRow === (observation.sr_no || observation.id)) ||
          !isEditing)
      }
      onInput={(e) =>
        handleCellEdit(
          e,
          "recommendations",
          observation.recommendations,
          observation
        )
      }
    >
      {observation.recommendations}
    </span>
  </div>
  {!observation.variant && (
    <EditOutlinedIcon
      onClick={(e) =>
        isEditing
          ? handleCellEdit(
              e,
              "recommendations",
              observation.recommendations,
              observation
            )
          : null
      }
      className="edit-icon"
      fontSize="small"
    />
  )}
</TableCell>


                                {/* <TableCell
                                  className="editable-cell"
                                  style={{ height: "100px" }}
                                >
                                  <div
                                    className="cell-content"
                                    style={{
                                      color:
                                        (isEditing &&
                                          currentEditedRow !==
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                        observation.variant === true
                                          ? "grey"
                                          : "black",
                                    }}
                                  >
                                    <span
                                      contentEditable={
                                        !observation.variant &&
                                        ((isEditing &&
                                          currentEditedRow ===
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )) ||
                                          !isEditing)
                                      }
                                      onInput={(e) =>
                                        handleCellEdit(
                                          e,
                                          "is_reference",
                                          observation.is_reference,
                                          observation
                                        )
                                      }
                                    >
                                      {observation.is_reference}
                                    </span>
                                  </div>
                                  {!observation.variant && (
                                    <EditOutlinedIcon
                                      onClick={(e) =>
                                        isEditing
                                          ? handleCellEdit(
                                              e,
                                              "is_reference",
                                              observation.is_reference,
                                              observation
                                            )
                                          : null
                                      }
                                      className="edit-icon"
                                      fontSize="small"
                                    />
                                  )}
                                </TableCell> */}

<TableCell className="editable-cell" style={{ height: "100px" }}>
  <div
    className="cell-content"
    style={{
      color:
        (isEditing &&
          currentEditedRow !== (observation.sr_no || observation.id)) ||
        observation.variant === true
          ? "grey"
          : "black",
    }}
  >
    <span
      contentEditable={
        !observation.variant &&
        ((isEditing && currentEditedRow === (observation.sr_no || observation.id)) ||
          !isEditing)
      }
      onInput={(e) =>
        handleCellEdit(
          e,
          "is_reference",
          observation.is_reference,
          observation
        )
      }
    >
      {observation.is_reference}
    </span>
  </div>
  {!observation.variant && (
    <EditOutlinedIcon
      onClick={(e) =>
        isEditing
          ? handleCellEdit(
              e,
              "is_reference",
              observation.is_reference,
              observation
            )
          : null
      }
      className="edit-icon"
      fontSize="small"
    />
  )}
</TableCell>


                                {/* <TableCell>
                                  <input
                                    style={{ width: "35px" }}
                                    value={observation.score}
                                    onChange={(e) =>
                                      handleScoreChange(
                                        e,
                                        observation.table_type,
                                        observation.sr_no || observation.id
                                      )
                                    }
                                    placeholder="Score"
                                    disabled={
                                      isEditing &&
                                      currentEditedRow !==
                                        selectedObservations.findIndex(
                                          (obs) =>
                                            obs.table_type ===
                                              observation.table_type &&
                                            obs.area === observation.area &&
                                            (obs.sr_no === observation.sr_no ||
                                              obs.id === observation.id)
                                        )
                                    }
                                  />
                                </TableCell> */}

<TableCell>
  <input
    style={{ width: "35px" }}
    value={observation.score}
    onChange={(e) =>
      handleScoreChange(
        e,
        observation.table_type, // Keep this unchanged
        observation.sr_no || observation.id // Keep this unchanged
      )
    }
    placeholder="Score"
    disabled={
      isEditing &&
      currentEditedRow !== (observation.sr_no || observation.id)
    }
  />
</TableCell>


                                {/* <TableCell>
                                  <div className="image-container">
                                    {observation.imageUrls?.length > 0 ? (
                                      <div className="image-item">
                                        {observation.imageUrls.map(
                                          (imageUrl, imgIndex) => (
                                            <div
                                              style={{ display: "flex" }}
                                              key={imgIndex}
                                            >
                                              <img
                                                src={imageUrl}
                                                alt={`Image ${imgIndex + 1}`}
                                                className="photo-image-saved"
                                                onClick={() =>
                                                  setSelectedImage(imageUrl)
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                              <CancelIcon
                                                onClick={() =>
                                                  handleRemoveImage(
                                                    observation.table_type,
                                                    observation.area,
                                                    observation.sr_no ||
                                                      observation.id,
                                                    imgIndex
                                                  )
                                                }
                                                className="cancel-icon"
                                                style={{
                                                  pointerEvents:
                                                    isEditing &&
                                                    currentEditedRow !==
                                                      selectedObservations.findIndex(
                                                        (obs) =>
                                                          obs.table_type ===
                                                            observation.table_type &&
                                                          obs.area ===
                                                            observation.area &&
                                                          (obs.sr_no ===
                                                            observation.sr_no ||
                                                            obs.id ===
                                                              observation.id)
                                                      )
                                                      ? "none"
                                                      : "auto",
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="upload-container">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleImageUpload(
                                          observation.table_type,
                                          observation.sr_no || observation.id,
                                          observation.area,
                                          e.target.files
                                        )
                                      }
                                      multiple
                                      style={{
                                        color: "transparent",
                                        padding: "5px",
                                        fontSize: "12px",
                                        width: "85px",
                                      }}
                                      disabled={
                                        isEditing &&
                                        currentEditedRow !==
                                          selectedObservations.findIndex(
                                            (obs) =>
                                              obs.table_type ===
                                                observation.table_type &&
                                              obs.area === observation.area &&
                                              (obs.sr_no ===
                                                observation.sr_no ||
                                                obs.id === observation.id)
                                          )
                                      }
                                    />
                                    {observation.imageUrls?.length === 0 && (
                                      <div className="no-file-chosen">
                                        No file chosen
                                      </div>
                                    )}
                                  </div>
                                </TableCell> */}

<TableCell>
  <div className="image-container">
    {observation.imageUrls?.length > 0 ? (
      <div className="image-item">
        {observation.imageUrls.map((imageUrl, imgIndex) => (
          <div style={{ display: "flex" }} key={imgIndex}>
            <img
              src={imageUrl}
              alt={`Image ${imgIndex + 1}`}
              className="photo-image-saved"
              onClick={() => setSelectedImage(imageUrl)}
              style={{ cursor: "pointer" }}
            />
            <CancelIcon
              onClick={() =>
                handleRemoveImage(
                  observation.table_type,
                  observation.area,
                  observation.sr_no || observation.id,
                  imgIndex
                )
              }
              className="cancel-icon"
              style={{
                pointerEvents:
                  isEditing &&
                  currentEditedRow !==
                    selectedObservations.findIndex(
                      (obs) => obs.sr_no === observation.sr_no
                    )
                    ? "none"
                    : "auto",
              }}
            />
          </div>
        ))}
      </div>
    ) : (
      ""
    )}
  </div>
  <div className="upload-container">
    <input
      type="file"
      accept="image/*"
      onChange={(e) =>
        handleImageUpload(
          observation.table_type,
          observation.sr_no || observation.id,
          observation.area,
          e.target.files
        )
      }
      multiple
      style={{
        color: "transparent",
        padding: "5px",
        fontSize: "12px",
        width: "85px",
      }}
      disabled={
        isEditing &&
        currentEditedRow !== (observation.sr_no || observation.id)
      }
    />
    {observation.imageUrls?.length === 0 && (
      <div className="no-file-chosen">No file chosen</div>
    )}
  </div>
</TableCell>


                                <TableCell className="table-actions">
                                  <div style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                  {!observation.variant && <InfoIcon
                                    onClick={() =>
                                      getObservationVariants(observation.observation, observation.sr_no || observation.id)
                                    }
                                  />}
                                  {!observation.variant && <PlaylistAddCircleIcon
                                    onClick={() =>
                                      handleDuplicateRow(
                                        observation.table_type,
                                        observation.area,
                                        observation.sr_no || observation.id
                                      )
                                    }
                                  />}
                                  <DeleteForeverIcon
                                    onClick={() =>
                                      handleDeleteRow(
                                        observation.table_type,
                                        observation.area,
                                        observation.sr_no || observation.id
                                      )
                                    }
                                  />
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                          {renderNewRowInputs(tableType)}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div
              style={{ marginBottom: "10px" }}
              className="review-modal-footer"
            >
              <button
                className="button-styles"
                onClick={handleSave}
                style={{ background: "#efc71d" }}
              >
                Save
              </button>
              <button
                className="button-styles"
                onClick={handlePrev}
                style={{ background: "#efc71d" }}
              >
                &#171; Prev
              </button>
              <button
                className="button-styles"
                color="primary"
                onClick={() => handleNext()}
                style={{ background: "#efc71d" }}
              >
                Next &#187;
              </button>
            </div>
          </div>
        </div>
      </Modal>
      </> 
    );
    // return (
    //   <>
    //     <VariantsModal
    //       data={observationVariants}
    //       open={openVairantModal}
    //       handleClose={closeVariantModal}
    //       handleConfirmSelection={handleConfirmSelection}
    //     />
    //     <ImageViewerModal
    //       imageUrl={selectedImage}
    //       onClose={() => setSelectedImage(null)}
    //     />
    //     <Modal open={open} onClose={handleClose}>
    //       <div className="review-modal-container">
    //         <div className="review-modal-content">
    //           <div className="review-modal-header">
    //             <Typography variant="h5">Preview Report</Typography>
    //             <button className="custom-close-button" onClick={handleClose}>
    //               &#10005; {/* Unicode for 'X' */}
    //             </button>
    //           </div>
    //           <div className="sub-headings">
    //             CRITICAL HSE OBSERVATIONS, PHOTOS & RECOMMENDATIONS
    //           </div>
    //           <div className="review-modal-body">
    //             <div className="table-container">
    //               <TableContainer component={Paper} className="table-scroll">
    //                 <Table>
    //                   <TableHead>
    //                     <TableRow>
    //                       <TableCell>Sr. No.</TableCell>
    //                       <TableCell>Areas</TableCell>
    //                       <TableCell>Categories</TableCell>
    //                       <TableCell>Check Point</TableCell>
    //                       <TableCell>Observation</TableCell>
    //                       <TableCell>Criticality</TableCell>
    //                       <TableCell>Recommendation</TableCell>
    //                       <TableCell>IS Reference</TableCell>
    //                       <TableCell>Score</TableCell>
    //                       <TableCell>Photo Evidences</TableCell>
    //                       <TableCell>Actions</TableCell>
    //                     </TableRow>
    //                   </TableHead>
    //                   <TableBody>
    //                     {selectedObservations.map((observation, index) => (
    //                       <TableRow
    //                         key={index}
    //                         className={
    //                           (isEditing && currentEditedRow === index) ||
    //                           !isEditing
    //                             ? "even-row"
    //                             : "odd-row"
    //                         }
    //                         style={
    //                           observation.variant === true
    //                             ? { backgroundColor: "#f2f2f2" }
    //                             : {}
    //                         }
    //                       >
    //                         <TableCell>{index + 1}</TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <CreatableSelect
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Area"
    //                               options={areaOptions()}
    //                               defaultValue={{
    //                                 label: observation.area,
    //                                 value: observation.area,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "area",
    //                                   observation.area,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <CreatableSelect
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Category..."
    //                               options={categoryOptions}
    //                               defaultValue={{
    //                                 label: observation.category,
    //                                 value: observation.category,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "category",
    //                                   observation.category,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         {/* <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "area",
    //                                   observation.area,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.area}
    //                             </span>
    //                           </div>
    //                           <EditIcon
    //                             onClick={(e) =>
    //                               isEditing
    //                                 ? handleCellEdit(
    //                                     e,
    //                                     index,
    //                                     "area",
    //                                     observation.area,
    //                                     observation
    //                                   )
    //                                 : null
    //                             }
    //                             className="edit-icon"
    //                             fontSize="small"
    //                           />
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "category",
    //                                   observation.category,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.category}
    //                             </span>
    //                           </div>
    //                           <EditIcon
    //                             onClick={(e) =>
    //                               isEditing
    //                                 ? handleCellEdit(
    //                                     e,
    //                                     index,
    //                                     "category",
    //                                     observation.category,
    //                                     observation
    //                                   )
    //                                 : null
    //                             }
    //                             className="edit-icon"
    //                             fontSize="small"
    //                           />
    //                         </TableCell> */}
    //                         {/* <TableCell>{observation.check_points}</TableCell> */}
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "check_points",
    //                                   observation.check_points,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.check_points}
    //                             </span>
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "check_points",
    //                                       observation.check_points
    //                                         ? observation.check_points
    //                                         : "Check Point",
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "observation",
    //                                   observation.observation,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.observation}
    //                             </span>
    //                           </div>

    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "observation",
    //                                       observation.observation,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             {/* <select
    //                               value={
    //                                 currentEditedRow === index
    //                                   ? editedObservations[0]?.criticality
    //                                   : observation.criticality
    //                               }
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "criticality",
    //                                   observation.criticality,
    //                                   observation
    //                                 )
    //                               }
    //                               disabled={
    //                                 isEditing && currentEditedRow !== index
    //                               }
    //                             >
    //                               <option value="High">High</option>
    //                               <option value="Medium">Medium</option>
    //                               <option value="Low">Low</option>
    //                             </select> */}
    //                             <Select
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Criticality"
    //                               options={criticalityOptions}
    //                               defaultValue={{
    //                                 label: observation.criticality,
    //                                 value: observation.criticality,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "criticality",
    //                                   observation.criticality,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell className="editable-cell">
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "recommendations",
    //                                   observation.recommendations,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.recommendations}
    //                             </span>
    //                           </div>

    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "recommendations",
    //                                       observation.recommendations,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "is_reference",
    //                                   observation.is_reference,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.is_reference}
    //                             </span>
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "is_reference",
    //                                       observation.is_reference,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell>
    //                           <input
    //                             style={{ width: "35px" }}
    //                             value={observation.score}
    //                             onChange={(e) => handleScoreChange(e, index)}
    //                             placeholder="Score"
    //                             disabled={
    //                               isEditing && currentEditedRow !== index
    //                             }
    //                           />
    //                         </TableCell>
    //                         {/* <TableCell>
    //                           <div className="image-container">
    //                             {imageUrlsByRow[index] &&
    //                             imageUrlsByRow[index].length > 0 ? (
    //                               <div className="image-item">
    //                                 {imageUrlsByRow[index].map(
    //                                   (imageUrl, imgIndex) => (
    //                                     <div
    //                                       style={{ display: "flex" }}
    //                                       key={imgIndex}
    //                                     >
    //                                       <img
    //                                         src={imageUrl}
    //                                         alt={`Image ${imgIndex + 1}`}
    //                                         className="photo-image-saved"
    //                                       />
    //                                       <CancelIcon
    //                                         onClick={() =>
    //                                           handleRemoveImage(index, imgIndex)
    //                                         }
    //                                         className="cancel-icon"
    //                                       />
    //                                     </div>
    //                                   )
    //                                 )}
    //                               </div>
    //                             ) : (
    //                               ""
    //                             )}
    //                           </div>
    //                           <div className="upload-container">
    //                             <input
    //                               type="file"
    //                               accept="image/*"
    //                               onChange={(e) =>
    //                                 handleImageUpload(index, e.target.files)
    //                               }
    //                               multiple
    //                               style={{ color: "transparent" }}
    //                             />
    //                             {selectedObservations[index].photoEvidence &&
    //                               selectedObservations[index].photoEvidence
    //                                 .length === 0 && (
    //                                 <div className="no-file-chosen">
    //                                   No file chosen
    //                                 </div>
    //                               )}
    //                           </div>
    //                         </TableCell> */}
    //                         <TableCell>
    //                           <div className="image-container">
    //                             {observation.imageUrls?.length > 0 ? (
    //                               <div className="image-item">
    //                                 {observation.imageUrls.map(
    //                                   (imageUrl, imgIndex) => (
    //                                     <div
    //                                       style={{ display: "flex" }}
    //                                       key={imgIndex}
    //                                     >
    //                                       <img
    //                                         src={imageUrl}
    //                                         alt={`Image ${imgIndex + 1}`}
    //                                         className="photo-image-saved"
    //                                         onClick={() =>
    //                                           setSelectedImage(imageUrl)
    //                                         } // Set selected image on click
    //                                         style={{ cursor: "pointer" }}
    //                                       />
    //                                       <CancelIcon
    //                                         onClick={() =>
    //                                           handleRemoveImage(index, imgIndex)
    //                                         }
    //                                         className="cancel-icon"
    //                                         style={{
    //                                           pointerEvents:
    //                                             isEditing &&
    //                                             currentEditedRow !== index
    //                                               ? "none"
    //                                               : "auto",
    //                                         }}
    //                                       />
    //                                     </div>
    //                                   )
    //                                 )}
    //                               </div>
    //                             ) : (
    //                               ""
    //                             )}
    //                           </div>
    //                           <div className="upload-container">
    //                             <input
    //                               type="file"
    //                               accept="image/*"
    //                               onChange={(e) =>
    //                                 handleImageUpload(index, e.target.files)
    //                               }
    //                               multiple
    //                               style={{
    //                                 color: "transparent",
    //                                 padding: "5px", // You can adjust the padding to reduce the size
    //                                 fontSize: "12px", // You can adjust the font size to make it smaller
    //                                 // Add any other styling as needed
    //                                 width: "85px",
    //                               }}
    //                               disabled={
    //                                 isEditing && currentEditedRow !== index
    //                               }
    //                             />
    //                             {observation.imageUrls?.length === 0 && (
    //                               <div className="no-file-chosen">
    //                                 No file chosen
    //                               </div>
    //                             )}
    //                           </div>
    //                         </TableCell>
    //                         <TableCell>
    //                           <div
    //                             style={{
    //                               display: "flex",
    //                               justifyContent: "center",
    //                             }}
    //                           >
    //                             {!observation.variant && (
    //                               <InfoIcon
    //                                 onClick={() =>
    //                                   getObservationVariants(
    //                                     observation.observation,
    //                                     index
    //                                   )
    //                                 }
    //                                 style={{ cursor: "pointer" }}
    //                               />
    //                             )}
    //                             {!observation.variant && (
    //                               <PlaylistAddCircleIcon
    //                                 onClick={() => handleDuplicateRow(index)}
    //                                 style={{ cursor: "pointer" }}
    //                               />
    //                             )}
    //                             <DeleteForeverIcon
    //                               onClick={() => handleDeleteRow(index)}
    //                               style={{ cursor: "pointer" }}
    //                             />
    //                           </div>
    //                         </TableCell>
    //                       </TableRow>
    //                     ))}
    //                   </TableBody>
    //                 </Table>
    //               </TableContainer>
    //             </div>
    //           </div>
    //           <hr />
    //           <div className="review-modal-footer">
    //             <button
    //               className="button-styles"
    //               onClick={handleSave}
    //               style={{ background: "#efc71d" }}
    //             >
    //               Save
    //             </button>
    //             <button
    //               className="button-styles"
    //               onClick={handlePrev}
    //               style={{ background: "#efc71d" }}
    //             >
    //               &#171; Prev
    //             </button>
    //             <button
    //               className="button-styles"
    //               color="primary"
    //               onClick={() => handleNext()}
    //               style={{ background: "#efc71d" }}
    //             >
    //               Next &#187;
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </Modal>
    //   </>
    // );
  } else if (screenNumber === 9) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">GLOBAL BEST PRACTICES</div>
                    <br />
                    <TextareaAutosize
                      value={bestPractice}
                      onChange={(e) => handleChange(e, "best_practice")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 10) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Charts</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <div
                  id="chart-container-for-report"
                  className="chart-container-for-report"
                  ref={chartContainerRef}
                >
                  <div className="total-serverity-div-for-report">
                    <div className="severity-item-for-report">
                      Total Observations
                      <br />
                      <span>{data.length}</span>
                      <hr />
                    </div>
                    <div
                      style={{ color: "#FF0000" }}
                      className="severity-item-for-report"
                    >
                      High Severity Observations
                      <br />
                      <span>
                        {data.filter((e) => e.criticality === "High").length}
                      </span>
                      <hr />
                    </div>
                    <div
                      style={{ color: "#006400" }}
                      className="severity-item-for-report"
                    >
                      Medium Severity Observations
                      <br />
                      <span>
                        {data.filter((e) => e.criticality === "Medium").length}
                      </span>
                      <hr />
                    </div>
                    <div
                      style={{ color: "#005cdb" }}
                      className="severity-item-for-report"
                    >
                      Low Severity Observations
                      <br />
                      <span>
                        {data.filter((e) => e.criticality === "Low").length}
                      </span>
                      <hr />
                    </div>
                  </div>
                  <div className="area-chart-for-report">
                    Area Chart
                    <Chart
                      options={barOptions}
                      series={[
                        {
                          name: "",
                          data: counts,
                        },
                      ]}
                      type="bar"
                      height={300}
                    />
                  </div>
                  <div className="severity-chart-for-report">
                    Severity Chart
                    <Chart
                      options={severityChartOptions}
                      series={seriesData}
                      type="bar"
                      height={300}
                    />
                  </div>
                  <div className="pie-chart-for-report">
                    Audit Score
                    <Chart
                      options={pieOptions}
                      series={[scorePercent, 100 - scorePercent]}
                      type="pie"
                      style={{ width: "100%" }}
                      height={250}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  disabled={waitForCharts}
                  style={{
                    background: waitForCharts ? "lightgrey" : "#efc71d",
                  }}
                >
                  {waitForCharts ? "Wait..." : "Save"}
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  disabled={waitForCharts}
                  style={{
                    background: waitForCharts ? "lightgrey" : "#efc71d",
                  }}
                >
                  &#171;{waitForCharts ? "Wait..." : "Prev"}
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  disabled={waitForCharts}
                  style={{
                    background: waitForCharts ? "lightgrey" : "#efc71d",
                  }}
                >
                  {waitForCharts ? "Wait..." : "Next"} &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 11) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">THE WAY FORWARD</div>
                    <br />
                    <TextareaAutosize
                      value={theWayForward}
                      onChange={(e) => handleChange(e, "the_way_forward")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 12) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CONCLUSION</div>
                    <br />
                    <TextareaAutosize
                      value={conclusion}
                      onChange={(e) => handleChange(e, "conclusion")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  onClick={handleComplete}
                  // disabled={isComplete}
                  style={{ background: "#efc71d" }}
                >
                  Complete
                </button>
                <ExportSavedReportPDF
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  reportType="HSE"
                  isSaved={isSaved}
                  chartImage={chartImage}
                  otherDetails={otherDetails}
                  ReportUID={ReportUID}
                  startDate={startDate}
                  endDate={endDate}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  name={name}
                  facilityInfo={facilityInfo}
                  introduction={introduction}
                />
                <ExportExcel
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={isSaved}
                  reportType="HSE"
                  otherDetails={otherDetails}
                  ReportUID={ReportUID}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  facilityInfo={facilityInfo}
                />
                <ExportWordDoc
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={isSaved}
                  otherDetails={otherDetails}
                  reportType="HSE"
                  chartImage={chartImage}
                  ReportUID={ReportUID}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  startDate={startDate}
                  endDate={endDate}
                  name={name}
                  facilityInfo={facilityInfo}
                  introduction={introduction}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};

export default HsePreviewReportModal;