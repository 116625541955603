import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@mui/material";
import "./ViewReportDetails.css";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { config } from "../../config";
import axios from "../../APIs/axios";
import ImageViewerModal from "../ImageViewerModal/ImageViewerModal";

const ViewReportDetails = ({
  selectedReportData,
  setOpenViewReport,
  reportHeader,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [criticalObservation, setCriticalObservations] = useState([]);
  const [facilityInfo, setFacilityInfo] = useState({
    "Name of Facility": "", 
    "Address & Location": "",
    "Geographical Co-ordinates Seismic Zone": "",
    "Brief Property Description:": "",
    "Type of Construction": "",
    "Number of Floors":"",
    "Average Worker Foot Fall:": "",
    "No Objection Certificate": "",
  });
  const formattedDateTime = `${
    selectedReportData.date_time.split("T")[0].split("-")[2]
  }-${selectedReportData.date_time.split("T")[0].split("-")[1]}-${
    selectedReportData.date_time.split("T")[0].split("-")[0]
  } ${selectedReportData.date_time.split("T")[1]}`;

  const formattedStartDate = `${new Date(
    selectedReportData.start_date
  ).getDate()}-${
    new Date(selectedReportData.start_date).getMonth() + 1
  }-${new Date(selectedReportData.start_date).getFullYear()}`;

  const formattedEndDate = `${new Date(
    selectedReportData.end_date
  ).getDate()}-${
    new Date(selectedReportData.end_date).getMonth() + 1
  }-${new Date(selectedReportData.end_date).getFullYear()}`;

  const handleClose = () => {
    setOpenViewReport(false);
  };

  useEffect(() => {
    getCriticalObservations();
    getFacilityInfo();
  }, []);

  console.log(facilityInfo)

  const getFacilityInfo = async () => {
    const response = await axios.get(
      `${config.PATH}/api/get-electrical-facility-info/${selectedReportData.report_id}`
    );
    const data = response.data;
    // Update each field individually
    setFacilityInfo(data);
  };

  const getCriticalObservations = async () => {
    try {
      const res = await axios.get(
        `${config.PATH}/api/get-critical-observations/${selectedReportData.report_id}`
      );
      setCriticalObservations(res.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <ImageViewerModal
        imageUrl={selectedImage}
        onClose={() => setSelectedImage(null)}
      />
      <Modal open={true} onClose={handleClose}>
        <div className="modal-container">
          <div className="modal-header">
            <Typography variant="h5">Report Details</Typography>
            <IconButton
                size="small"
                onClick={handleClose}
                className="close-icon"
                style={{backgroundColor:"#efc71d"}}
              >
                <CloseIcon style={{color:"#307248"}} />
              </IconButton>
          </div>

          <div className="modal-content">
            <div className="modal-body">
              <Typography variant="body1">
                {" "}
                <div className="sub-headings">BASIC DETAILS</div>
                <div className="basic-detals">
                  Report ID: {selectedReportData.report_id}
                  <br />
                  Date & Time: {formattedDateTime}
                  <br />
                  Organization: {selectedReportData.organization}
                  <br />
                  Site: {selectedReportData.site}
                  <br />
                  Start Date & End Date:{" "}
                  {formattedStartDate + " & " + formattedEndDate}
                </div>
                <br />
                <br />
                <div className="sub-headings">BACKGROUND BRIEF</div>
                <TextareaAutosize
                  value={
                    selectedReportData.background_brief
                      ? selectedReportData.background_brief
                      : reportHeader.background_brief
                  }
                  placeholder="Enter your text here"
                  className="text-area-for-view"
                  readOnly
                />
                <br />
                <br />
                <div className="sub-headings">
                  UNDERSTANDING OF THE REVIEW REPORT ‐ CONTENTS.
                </div>
                <TextareaAutosize
                  value={
                    selectedReportData.contents
                      ? selectedReportData.contents
                      : reportHeader.contents
                  }
                  placeholder="Enter your text here"
                  className="text-area-for-view"
                  style={{ background: "whitesmoke" }}
                  readOnly
                />
                <br />
                <br />
                <div className="sub-headings">EXECUTIVE SUMMARY</div>
                <TextareaAutosize
                  value={
                    selectedReportData.exe_summary
                      ? selectedReportData.exe_summary
                      : reportHeader.exe_summary
                  }
                  placeholder="Enter your text here"
                  className="text-area-for-view"
                  style={{ background: "whitesmoke" }}
                  readOnly
                />
                <br />
                <br />

 {/* Academic Info Section */}
<div className="sub-headings">ACADEMIC INFO</div>
<TableContainer component={Paper} className="facility-info-table">
  <Table>
    <TableBody>
      {Object.entries(facilityInfo).map(([key, value], index) => (
        <TableRow key={index}>
          <TableCell style={{ fontWeight: "bold", width: "30%" }}>
            {key}
          </TableCell>
          <TableCell style={{ width: "70%" }}>
            {value}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

<br/>
<br/>

                <div className="sub-headings">CRITICAL OBSERVATIONS</div>
                <div
                  className="critical-observations-div"
                  style={{
                    fontFamily: "inherit",
                    fontSize: "13px",
                  }}
                >
                  {criticalObservation.length === 0 ? (
                    <div className="no-observations">
                      <em>No critical observations</em>
                    </div>
                  ) : (
                    criticalObservation.map((observation, index) => (
                      <div key={index} className="observation-item">
                        <li>
                          <span>{observation.observation}</span>
                        </li>
                      </div>
                    ))
                  )}
                  {selectedReportData.other_details}
                </div>
                <br />
                <div className="sub-headings" style={{fontWeight:500}}>
                CRITICAL OBSERVATIONS, RECOMMENDATIONS & REASONING - ELECTRICAL SAFETY
                </div>
                <div className="table-container-for-view ">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell>Areas</TableCell>
                          <TableCell>Categories</TableCell>
                          <TableCell>Check Point</TableCell>
                          <TableCell>Observation</TableCell>
                          <TableCell>Criticality</TableCell>
                          <TableCell>Recommendation</TableCell>
                          <TableCell>IS Reference</TableCell>
                          <TableCell>Photo Evidences</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedReportData.AllObservations.filter(
                          (e) => e.is_selected === 1
                        ).map((observation, index) => (
                          <TableRow
                            key={index}
                            className={index % 2 === 0 ? "even-row" : "odd-row"}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{observation.area}</TableCell>
                            <TableCell>{observation.category}</TableCell>
                            <TableCell>{observation.check_points}</TableCell>
                            <TableCell>{observation.observation}</TableCell>
                            <TableCell>{observation.criticality}</TableCell>
                            <TableCell>{observation.recommendations}</TableCell>
                            <TableCell>{observation.is_reference}</TableCell>
                            <TableCell>
                              <div className="image-container">
                                {observation.imageUrls?.length > 0 ? (
                                  <div className="image-item">
                                    {observation.imageUrls.map(
                                      (imageUrl, imgIndex) => (
                                        <div
                                          style={{ display: "flex" }}
                                          key={imgIndex}
                                        >
                                          <img
                                            src={imageUrl}
                                            alt={`Image ${imgIndex + 1}`}
                                            className="photo-image-saved"
                                            onClick={() =>
                                              setSelectedImage(imageUrl)
                                            } // Set selected image on click
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <br/>
                <br/>
                {/* <div className="sub-headings">GLOBAL BEST PRACTICES</div>
                <TextareaAutosize
                  value={
                    selectedReportData.best_practice
                      ? selectedReportData.best_practice
                      : reportHeader.best_practice
                  }
                  placeholder="Enter your text here"
                  className="text-area-for-view"
                  style={{ background: "whitesmoke" }}
                  readOnly
                />
                <br />
                <br/> */}
                <div className="sub-headings">ELECTRICAL SAFETY SCORE TABLE</div>
                <div className="table-container-for-view ">
                  <Typography variant="body1">
                    <div>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow style={{ background: "grey" }}>
                              <TableCell>Electrical Safety</TableCell>
                              <TableCell>Max Score</TableCell>
                              <TableCell>Score Obtained</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {JSON.parse(selectedReportData.scores).map(
                              (row, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {row["Electrical Safety"]}
                                  </TableCell>
                                  <TableCell>{row["Max Score"]}</TableCell>
                                  <TableCell>{row["Score Obtained"]}</TableCell>
                                </TableRow>
                              )
                            )}
                            <TableRow style={{ background: "#efc71d" }}>
                              <TableCell>Cumulative</TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                10
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                {selectedReportData.cumulative}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Typography>
                </div>
                <br />
                <br/>
                <div className="sub-headings">THE WAY FORWARD</div>
                <TextareaAutosize
                  value={
                    selectedReportData.the_way_forward
                      ? selectedReportData.the_way_forward
                      : reportHeader.the_way_forward
                  }
                  placeholder="Enter your text here"
                  className="text-area-for-view"
                  style={{ background: "whitesmoke" }}
                  readOnly
                />
                <br/>
                <br/>
                <div className="sub-headings">CONCLUSION</div>
                <TextareaAutosize
                  value={
                    selectedReportData.conclusion
                      ? selectedReportData.conclusion
                      : reportHeader.conclusion
                  }
                  placeholder="Enter your text here"
                  className="text-area-for-view"
                  style={{ background: "whitesmoke" }}
                  readOnly
                />
              </Typography>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewReportDetails;
