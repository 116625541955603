import React, { useState, useEffect } from "react";
import { Button, Modal, TextField, Typography } from "@mui/material";
import "./HseReportModal.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import axios from "../../APIs/axios";
import HsePreviewReportModal from "../HsePreviewReportModal/HsePreviewReportModal.js";
import { config } from "../../config";
import { getAccountDetails } from "../Services/localStorage";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ObservationsDrawer from "../ObservationsDrawer/ObservationsDrawer.js";

const HseReportModal = ({
  open,
  setOpenHseModal,
  hseAreaList,
  allData,
  getAllHseData,
  setLoading,
  getAllHseReports,
  selectedSector,
  setSelectedSector,
  selectedParam,
  setSelectedParam,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(["Category"]);
  const [observations, setObservations] = useState([]);
  const [selectedObservations, setSelectedObservations] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [ReportUID, setReportUID] = useState("");
  const [newReportUID, setNewReportUID] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orgList, setOrgList] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [reportHeader, setReportHeaders] = useState();
  const [criticalObservations, setCriticalObservations] = useState([]);
  const { userId } = getAccountDetails();
  const [areasToDisplay, setAreasToDisplay] = useState([]);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [paramList, setParamList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    getOrgList();
    getReportHeaders();
    getParamList();
  }, [open, selectedOrganization, selectedSector]);

  useEffect(() => {
    generateUniqueId();
    getCurrentDateTime();
    getAllNotes();
  }, []);

  useEffect(() => {
    const getSitesByOrganization = async (orgId) => {
      try {
        const response = await axios.get(
          `${config.PATH}/api/hse-organizations/${orgId}/sites`
        );
        const siteOptions = response.data.map((site) => ({
          label: site.site_name,
          value: site.site_name,
        }));
        setSiteOptions(siteOptions);
      } catch (error) {
        console.log("Error:", error.response?.data?.error || error.message);
      }
    };
    if (selectedOrganization) {
      getSitesByOrganization(selectedOrganization.value);
    }
  }, [selectedOrganization, selectedSite]);

  useEffect(() => {
    updateReportUID();
  }, [selectedOrganization, selectedSite, selectedDateTime]);

  const getParamList = () => {
    if (!selectedSector || !selectedSector.value) {
      setParamList([]);
      setSelectedParam(null);
      setSelectedArea(null);
      setAreasToDisplay(null);
      setCategoriesToDisplay(null);
      return [];
    }

    const list = [
      ...new Set(
        allData.data
          .filter((e) => e.sector_type === selectedSector.value) // Filter based on selectedSector
          .map((e) => e.table_type)
          .filter(
            (table_type) =>
              table_type !== null &&
              table_type !== undefined &&
              table_type !== ""
          )
      ),
    ];

    setParamList(list);
    return list;
  };

  const getAllNotes = async () => {
    try {
      const response = await axios.get(
        `${config.PATH}/api/get-all-notes/${userId}`
      );
      setNotes(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getReportHeaders = async () => {
    try {
      const response = await axios.get(`${config.PATH}/api/get-hse-headers`);
      setReportHeaders(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const getReportHeaders = async () => {
  //   try {
  //     const response = await axios.get(`${config.PATH}/api/get-headers`);
  //     setReportHeaders(response.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const getOrgList = async () => {
    try {
      const response = await axios.get(`${config.PATH}/api/get-hse-orgs`);
      setOrgList(response.data);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const handleCloseWithoutSaving = () => {
    setShowConfirmationModal(false);
    setOpenHseModal(false);
    setSelectedObservations([]);
    setRecommendations([]);
    setSelectedArea([]);
    setSelectedCategory([]);
    setSelectedOrganization(null);
    setSelectedSite(null);
    setAreasToDisplay([]);
    setCategoriesToDisplay([]);
    generateUniqueId();
    getCurrentDateTime();
    setStartDate(null);
    setEndDate(null);
    setSelectedSector(null);
    setSelectedParam(null);
  };

  const handleClose = () => {
    if (selectedObservations.length > 0) {
      setShowConfirmationModal(true);
    } else {
      handleCloseWithoutSaving();
    }
  };

  const sectors = () => {
    try {
      const options = [];
      const list = [
        ...new Set(
          allData.data
            .map((e) => e.sector_type)
            .filter(
              (sector_type) =>
                sector_type !== null &&
                sector_type !== undefined &&
                sector_type !== ""
            )
        ),
      ];
      list.map((area) => options.push({ label: area, value: area }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const areaOptions = () => {
    try {
      const options = [];
      hseAreaList.map((area) => options.push({ label: area, value: area }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const categoryOptions = () => {
    try {
      const options = [];
      let uniqueCategories = [...new Set(categoryList)];
      uniqueCategories.map((cat) => options.push({ label: cat, value: cat }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const paramsOption = () => {
    try {
      const options = [];
      paramList.map((param) => options.push({ label: param, value: param }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const organizationOptions = orgList.map((e) => ({
    label: e.org_name,
    value: e.id,
  }));

  const handleOrganizationSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
        selectedOption.label
      );

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidOrganizationName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidOrganizationName) {
          errorMessage =
            "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Organization name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        org_name: selectedOption.label,
      };
      try {
        const response = await axios.post(
          `${config.PATH}/api/create-hse-org`,
          payload
        );
        const newOrganization = response.data; // Assuming the API returns the newly created organization object
        setSelectedOrganization({
          label: newOrganization.org_name,
          value: newOrganization.id,
        });
        setSelectedSite(null);
      } catch (error) {
        console.log("Failed to create organization:", error);
        return;
      }
    } else {
      setSelectedOrganization(selectedOption);
      setSelectedSite(null);
    }
  };

  const handleSiteSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidSiteName = /^[a-zA-Z0-9_-]+$/.test(selectedOption.label);

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidSiteName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidSiteName) {
          errorMessage =
            "Invalid Site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Site name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        site_name: selectedOption.label,
        org_id: selectedOrganization.value,
      };
      try {
        await axios.post(`${config.PATH}/api/create-hse-site`, payload);
        updateReportUID();
      } catch (error) {
        console.log("Failed to create site:", error);
        return;
      }
    }
    setSelectedSite(selectedOption);
    updateReportUID();
  };

  const handleChangeSector = (e) => {
    setSelectedSector(e);
  };

  const handleChangeParam = (e) => {
    if (e === null || e.length === 0 || e === undefined) {
      setSelectedArea([]);
      setAreasToDisplay([]);
    }
    setSelectedParam(e);
  };

  const handleChangeArea = (areas) => {
    try {
      setAreasToDisplay(areas);
      const selectedArea = areas.map((e) => e.value);
      setSelectedArea(selectedArea);

      if (areas.length === 0) {
        // If the 'areas' array is empty, clear observations and selected observations
        setObservations([]);
        setSelectedObservations([]);
      } else {
        // Filter observations based on the selected areas
        const combinedData = allData.data
        .filter(
          (e) =>
            e.edited_fields === null ||
            e.edited_fields.includes("O") ||
            (JSON.parse(e.edited_fields).length === 0)
        ).concat(
            notes
              .filter((note) => note.note_type === "hse")
              .map((note) => ({ ...note, isNote: true }))
          );

          console.log("combinedData",combinedData)

        const filteredObservations = combinedData.filter((item) =>
          selectedArea.includes(item.area)
        );

        console.log("filteredObservations",filteredObservations)

        const updatedObservations = filteredObservations.map((newObs) => {
          const existingObs = observations.find(
            (obs) => obs.observation === newObs.observation
          );
          return existingObs
            ? {
                ...newObs,
                is_selected: existingObs.is_selected,
                imageUrls: existingObs.imageUrls,
                score: existingObs.score,
              }
            : newObs;
        });

        console.log("updatedObservations",updatedObservations)

        setObservations(updatedObservations);
        setSelectedObservations(
          selectedObservations.filter((selectedObs) =>
            updatedObservations.some(
              (e) => e.sr_no === selectedObs.sr_no || e.id === selectedObs.id
            )
          )
        );

        // Optionally, remove selected observations not in the filtered areas
        const removedAreas = selectedObservations.filter(
          (selectedObs) => !selectedArea.includes(selectedObs.area)
        );
        if (removedAreas.length > 0) {
          const updatedSelectedObservations = selectedObservations.filter(
            (selectedObs) =>
              !removedAreas.some(
                (removedArea) => removedArea.area === selectedObs.area
              )
          );
          setSelectedObservations(updatedSelectedObservations);
        }
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const handleChangeCategory = async (cat) => {
    setCategoriesToDisplay(cat);
    const selectedCat = cat.map((e) => e.value);
    setSelectedCategory(selectedCat);

    const combinedData = allData.data
      .filter((e) => e.edited_fields === null || e.edited_fields.includes("O"))
      .concat(
        notes
          .filter((note) => note.note_type === "hse")
          .map((note) => ({ ...note, isNote: true }))
      );

    // Filter based on selected category and area
    const filteredObservations = combinedData.filter(
      (item) =>
        selectedCat.includes(item.category) && selectedArea.includes(item.area)
    );

    // Preserve the selection status (is_selected) from the old observations
    const updatedObservations = filteredObservations.map((newObs) => {
      const existingObs = observations.find(
        (obs) => obs.observation === newObs.observation
      );
      return existingObs
        ? {
            ...newObs,
            is_selected: existingObs.is_selected,
            imageUrls: existingObs.imageUrls,
            score: existingObs.score,
          }
        : newObs;
    });

    // Update the observations state with the filtered observations.
    setObservations(updatedObservations);
    setSelectedObservations(
      selectedObservations.filter((selectedObs) =>
        updatedObservations.some(
          (e) => e.sr_no === selectedObs.sr_no || e.id === selectedObs.id
        )
      )
    );
    // Check if any observations in selectedObservations are related to removed categories
    const removedCategories = selectedObservations.filter(
      (selectedObs) => !selectedCat.includes(selectedObs.category)
    );
    if (removedCategories.length > 0) {
      // Remove observations corresponding to removed categories
      const updatedSelectedObservations = selectedObservations.filter(
        (selectedObs) =>
          !removedCategories.some(
            (removedCat) =>
              removedCat.category === selectedObs.category ||
              removedCat.category === selectedObs.category
          )
      );
      setSelectedObservations(updatedSelectedObservations);
    }
  };

  // const handleObservationSelection = (tableType, position) => {
  //   // Clone the observations array to avoid direct mutation
  //   const tempObs = [...observations];

  //   // Find the observations matching the given tableType
  //   const tableObservations = tempObs.filter(
  //     (obs) => obs.table_type === tableType
  //   );

  //   // Ensure the position is within bounds
  //   if (position < 0 || position >= tableObservations.length) {
  //     return; // Invalid position, return early
  //   }

  //   // Get the specific observation based on position
  //   const observation = tableObservations[position];
  //   const isAlreadySelected = observation.is_selected;

  //   // Toggle the selection state
  //   tempObs.forEach((obs, i) => {
  //     if (obs.table_type === tableType && obs === observation) {
  //       tempObs[i] = { ...obs, is_selected: isAlreadySelected ? 0 : 1 };
  //     }
  //   });

  //   // Update the observations state with the modified array
  //   setObservations(tempObs);

  //   if (isAlreadySelected) {
  //     // Unselecting: Remove the observation from selectedObservations and criticalObservations
  //     setSelectedObservations(
  //       selectedObservations.filter(
  //         (e) => !(e.table_type === tableType && e.sr_no === observation.sr_no)
  //       )
  //     );
  //     setCriticalObservations(
  //       criticalObservations.filter(
  //         (e) => !(e.table_type === tableType && e.sr_no === observation.sr_no)
  //       )
  //     );
  //   } else {
  //     // Selecting: Add the observation to selectedObservations and criticalObservations
  //     setSelectedObservations([
  //       ...selectedObservations,
  //       { ...observation, table_type: tableType },
  //     ]);
  //     if (observation.criticality === "High") {
  //       setCriticalObservations([
  //         ...criticalObservations,
  //         { ...observation, table_type: tableType },
  //       ]);
  //     }
  //   }
  // };

  const handleObservationSelection = (tableType, identifier) => {
    // Clone the observations array to avoid direct mutation
    const tempObs = [...observations];
  
    // Find the specific observation using sr_no or id
    const observationIndex = tempObs.findIndex(
      (obs) => obs.table_type === tableType && (obs.sr_no === identifier || obs.id === identifier)
    );
  
    if (observationIndex === -1) {
      return; // Observation not found
    }
  
    // Toggle the selection state
    const observation = tempObs[observationIndex];
    const isAlreadySelected = observation.is_selected;
  
    tempObs[observationIndex] = {
      ...observation,
      is_selected: isAlreadySelected ? 0 : 1,
    };
  
    // Update the observations state with the modified array
    setObservations(tempObs);
  
    if (isAlreadySelected) {
      // Unselecting: Remove the observation from selectedObservations and criticalObservations
      setSelectedObservations(
        selectedObservations.filter(
          (e) =>
            !(e.table_type === tableType &&
              (e.sr_no === identifier || e.id === identifier))
        )
      );
      setCriticalObservations(
        criticalObservations.filter(
          (e) =>
            !(e.table_type === tableType &&
              (e.sr_no === identifier || e.id === identifier))
        )
      );
    } else {
      // Selecting: Add the observation to selectedObservations and criticalObservations
      setSelectedObservations([
        ...selectedObservations,
        { ...observation, table_type: tableType },
      ]);
      if (observation.criticality === "High") {
        setCriticalObservations([
          ...criticalObservations,
          { ...observation, table_type: tableType },
        ]);
      }
    }
  };
  

  const handleProceed = async () => {
    try {
      setLoading(true);
      setOpenReviewModal(true);
      setOpenHseModal(false);
      const reportData = {
        report_id: ReportUID,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        area: selectedArea,
        category: selectedCategory,
        start_date: startDate,
        end_date: endDate,
        type: "primary",
        sector_type: selectedSector.value,
      };
      await axios.post(`${config.PATH}/api/save-update-hse-report`, reportData);

      const updatedObservations = [
        ...observations.filter((obs) => obs.is_selected === 1),
        ...observations.filter((obs) => obs.is_selected !== 1),
      ];

      setObservations(updatedObservations);

      const observationsData = {
        report_id: ReportUID,
        // observation: selectedObservations,
        // critical_observations: criticalObservations,
        all_observations: updatedObservations,
      };
      await axios.post(
        `${config.PATH}/api/save-update-hse-observations`,
        observationsData
      );
      setLoading(false);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const updateReportUID = () => {
    if (
      selectedOrganization &&
      selectedOrganization.label &&
      selectedSite &&
      selectedSite.label &&
      selectedDateTime
    ) {
      const formattedDateTime = selectedDateTime.split("T")[0];
      setReportUID(
        `${newReportUID}-${selectedOrganization.label}_${selectedSite.label}_${formattedDateTime}`
      );
    } else if (!selectedSite) {
      // If selectedSite is null, revert back to the initial ReportUID
      setReportUID(newReportUID);
    }
  };

  const generateUniqueId = () => {
    let id = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }

    setReportUID(id);
    setNewReportUID(id); // Set newReportUID initially to the generated ID
  };

  const handleDateTime = (e) => {
    setSelectedDateTime(e.target.value);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    setSelectedDateTime(`${year}-${month}-${day}T${hours}:${minutes}`);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderBottom: "none", // Hide the separator
      boxShadow: "none", // Hide the box shadow
      cursor: "pointer", // Show the pointer cursor
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none", // Hide the down arrow
    }),
  };

  const handleStartEndDate = (date, name) => {
    if (!date) {
      // Handle case when date is null or undefined
      if (name === "start-date") {
        setStartDate(null);
      } else if (name === "end-date") {
        setEndDate(null);
      } else {
        if (startDate) setEndDate(null);
      }
      return;
    }

    // Ensure that the date part is set to midnight (00:00:00)
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);

    if (name === "start-date") {
      if (endDate && adjustedDate > endDate) {
        toast.warning("Start date cannot be after the end date.");
        setStartDate(null);
        return;
      }
      setStartDate(adjustedDate);
    } else {
      if (startDate && adjustedDate < startDate) {
        toast.warning("End date cannot be before the start date.");
        setEndDate(null);
        return;
      }
      setEndDate(adjustedDate);
    }
  };

  const filteredObservations = observations.filter(
    (observation) =>
      observation.table_type !== null &&
      observation.table_type !== undefined &&
      (observation.observation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        observation.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
        observation.table_type.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Group filtered observations by table_type
  const groupedByTableType = filteredObservations.reduce(
    (groups, observation) => {
      const { table_type } = observation;
      if (!groups[table_type]) {
        groups[table_type] = [];
      }
      groups[table_type].push(observation);
      return groups;
    },
    {}
  );

  const allDataForGlobalSearch = allData.data.filter(
    (e) => e.table_type !== null
  );
  const filteredAllData = allDataForGlobalSearch.filter(
    (observation) =>
      observation.observation
        .toLowerCase()
        .includes(globalSearchTerm.toLowerCase()) ||
      observation.area.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
      (observation.table_type &&
        observation.table_type
          .toLowerCase()
          .includes(globalSearchTerm.toLowerCase()))
  );
  // Group filtered observations by area
  const groupedData = filteredAllData.reduce((groups, observation) => {
    const { table_type } = observation;
    if (!groups[table_type]) {
      groups[table_type] = [];
    }
    groups[table_type].push(observation);
    return groups;
  }, {});

  if (open) {
    return (
      <div>
        <Modal open={open} onClose={handleClose}>
          <div className="modal-container">
            <div className="modal-header">
              <Typography variant="h5">Create HSE Report</Typography>
              <button className="custom-close-button" onClick={handleClose}>
                &#10005; {/* Unicode for 'X' */}
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-body">
                Report ID: {`${ReportUID}`}
                <Typography variant="body1">
                  <div className="select-container">
                    <CreatableSelect
                      placeholder="Organization"
                      options={organizationOptions}
                      onChange={handleOrganizationSelection}
                      value={selectedOrganization}
                      isSearchable
                      isClearable
                    />
                    <CreatableSelect
                      placeholder="Site"
                      options={siteOptions}
                      onChange={handleSiteSelection}
                      value={selectedSite}
                      isSearchable
                      isClearable
                      isDisabled={!selectedOrganization}
                    />
                  </div>
                  <div className="flex-container-start-end-date">
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={startDate}
                        onChange={(e) => handleStartEndDate(e, "start-date")}
                        placeholderText="Audit Start Date"
                        dateFormat="dd-MM-yyyy"
                        showTimeSelect={false}
                        utcOffset={0}
                        className="class-for-date-pickers"
                        maxDate={endDate}
                        todayButton={"Today"}
                      />
                    </div>
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={endDate}
                        onChange={(e) => handleStartEndDate(e, "end-date")}
                        className="class-for-date-pickers"
                        placeholderText="Audit End Date"
                        dateFormat="dd-MM-yyyy"
                        utcOffset={0}
                        minDate={startDate}
                        todayButton={"Today"}
                      />
                    </div>
                  </div>
                  <div style={{ gap: "10px" }} className="flex-container">
                    <Select
                      className="select"
                      placeholder="Sector"
                      options={sectors()}
                      onChange={(e) => handleChangeSector(e)}
                      // isMulti={true}
                      value={selectedSector}
                      isClearable
                    />
                    <Select
                      className="select"
                      placeholder="Parameter"
                      options={paramsOption()}
                      onChange={(e) => handleChangeParam(e)}
                      // isMulti={true}
                      value={selectedParam}
                      isClearable
                      isMulti
                    />
                    <Select
                      className="select"
                      placeholder="Area"
                      options={areaOptions()}
                      onChange={(e) => handleChangeArea(e)}
                      isMulti={true}
                      value={areasToDisplay}
                    />
                  </div>
                </Typography>
                <div className="observation-and-global-search">
                  <div className="observations-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search Observations"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                      />
                      <div>
                      <button onClick={handleOpenDrawer} className="search-bar-button">
                      <span className="search-icon">&#128269;</span> {/* Unicode for search icon */}
                      <span className="search-text">All Observations</span>
                      </button>

                        <ObservationsDrawer
                          isOpen={isDrawerOpen}
                          onClose={handleCloseDrawer}
                          groupedData={groupedData}
                          globalSearchTerm={globalSearchTerm}
                          setGlobalSearchTerm={setGlobalSearchTerm}
                        />
                        {/* Other components and content here */}
                      </div>
                    </div>
                    {(Object.keys(groupedByTableType) &&
                      Object.keys(groupedByTableType).length) > 0 &&
                    (selectedArea && selectedArea.length) > 0 &&
                    selectedArea ? (
                      <div className="observations-list">
                        {Object.entries(groupedByTableType).map(
                          ([table_type, observationsInTableType]) => (
                            <div key={table_type} className="area-group">
                              <h4>
                                <u>
                                  <em>{table_type}</em>
                                </u>
                              </h4>
                              {observationsInTableType.map(
                                (observation, index) => (
                                  <div
                                    key={index}
                                    className="observation-item-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedObservations
                                        .map((e) => e.observation)
                                        .includes(observation.observation)}
                                      onChange={() =>
                                        handleObservationSelection(
                                          observation.table_type,
                                          observation.sr_no || observation.id
                                        )
                                      }
                                    />
                                    <span>
                                      {observation.observation} (
                                      <span style={{ fontWeight: "bold" }}>
                                        {/* {observation.area}, {observation.category} */}
                                        {observation.area}
                                      </span>
                                      ){" "}
                                    </span>
                                    {observation.isNote && (
                                      <span className="note-label">(Note)</span>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="no-observations">
                        No observations available.
                      </div>
                    )}
                  </div>
                </div>
                <div className="get-recommendations-container">
                  <button
                    className="button-styles"
                    onClick={handleProceed}
                    disabled={
                      selectedObservations.length === 0 ||
                      !selectedOrganization ||
                      !selectedSite ||
                      !startDate ||
                      !endDate
                    }
                    style={{
                      background:
                        selectedObservations.length === 0 ||
                        !selectedOrganization ||
                        !selectedSite ||
                        !startDate ||
                        !endDate
                          ? "lightgrey"
                          : "#efc71d",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )}
      </div>
    );
  } else if (openReviewModal) {
    return (
      <HsePreviewReportModal
        open={openReviewModal}
        setOpenModal={setOpenReviewModal}
        setOpenCreateReportModal={setOpenHseModal}
        reportHeader={reportHeader}
        selectedObservations={selectedObservations}
        recommendations={recommendations}
        selectedArea={selectedArea}
        selectedCategory={selectedCategory}
        setSelectedObservations={setSelectedObservations}
        setRecommendations={setRecommendations}
        setSelectedArea={setSelectedArea}
        setSelectedCategory={setSelectedCategory}
        setSelectedOrganization={setSelectedOrganization}
        setSelectedSite={setSelectedSite}
        selectedOrganization={selectedOrganization}
        selectedSite={selectedSite}
        ReportUID={ReportUID}
        selectedDateTime={selectedDateTime}
        observations={observations}
        setAreasToDisplay={setAreasToDisplay}
        setCategoriesToDisplay={setCategoriesToDisplay}
        setObservations={setObservations}
        getAllHseData={getAllHseData}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        generateUniqueId={generateUniqueId}
        getCurrentDateTime={getCurrentDateTime}
        setLoading={setLoading}
        areaOptions={areaOptions}
        allData={allData}
        getAllHseReports={getAllHseReports}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        setSelectedParam={setSelectedParam}
      />
    );
  }
};

export default HseReportModal;