import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  AlignmentType,
  ExternalHyperlink,
  Footer,
  PageNumber,
  NumberFormat,
  BorderStyle,
  PageOrientation,
  VerticalAlign
} from "docx";
import Electrical_Cover from "../../Electrical_Cover.jpg";
import HSE_Cover from "../../HSE_Cover.jpg"
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "../../APIs/axios";
import { config } from "../../config";
import logo from "../../mi_logo_report.png";

const imageToBase64 = async (url) => {

  try {
    const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  } catch (error) {
    console.log("Error:",error.message)
  }
};

const createBreaks = (num) => {
  const breaks = [];
  for (let i = 0; i < num; i++) {
    breaks.push(new Paragraph({ break: 1 }));
  }
  return breaks;
};


const ExportWordDoc = ({
  backgroundBrief,
  contents,
  isSaved,
  exeSummary,
  criticalObservations,
  selectedObservations,
  scores,
  cumulativeScore,
  conclusion,
  // imageUrlsByRow,
  selectedDateTime,
  selectedOrganization,
  selectedSite,
  otherDetails,
  reportType,
  chartImage,
  ReportUID,
  bestPractice,
  theWayForward,
  startDate,
  endDate,
  name,
  facilityInfo,
  introduction
}) => {
  console.log("chartImage",chartImage)
  const [coverImage, setCoverImage] = useState(null);
  const [base64logo, setBase64Logo] = useState(null)
  const [selectedObsCopy, setSelectedObsCopy] = useState([
    ...selectedObservations,
  ]);
  const [loading, setLoading] = useState(false);
  // const groupedObservations = selectedObsCopy.reduce((acc, observation) => {
  //   const { table_type } = observation;
  //   if (!acc[table_type]) {
  //     acc[table_type] = [];
  //   }
  //   acc[table_type].push(observation);
  //   return acc;
  // }, {});

  const groupedObservations = selectedObsCopy.reduce((acc, observation) => {
    const { table_type } = observation;
  
    // Ensure table_type is defined and not null or an empty string
    if (table_type) {
      if (!acc[table_type]) {
        acc[table_type] = [];
      }
      acc[table_type].push(observation);
    } else {
      console.warn("Observation without a valid table_type:", observation);
      // Optionally handle observations without table_type here
    }
  
    return acc;
  }, {});
  

  useEffect(() => {
    fetchImage();
    fetchBase64Images();
  }, []);

  const fetchImage = async () => {
    const base64Image = await imageToBase64(reportType==="HSE"?HSE_Cover:Electrical_Cover);
    const base64logo = await imageToBase64(logo)
    setBase64Logo(base64logo)
    setCoverImage(base64Image);
  };

  const fetchBase64Images = async () => {
    try {
      setLoading(true);
      const selectedObsCopyWithImages = await Promise.all(selectedObservations.map(async observation => {
        let images = [];
        if (observation.imageUrls && observation.imageUrls.length > 0) {
          images = await Promise.all(observation.imageUrls.map(async imageUrl => {
            try {
              const response = await axios.get(`${config.PATH}/api/image-to-base64?imageUrl=${encodeURIComponent(imageUrl)}`);
              return response.data; // Assuming the backend returns base64-encoded images
            } catch (error) {
              console.error(`Error fetching image from ${imageUrl}:`, error.message);
              return null;
            }
          }));
        }
        return { ...observation, imageUrls: images };
      }));
      setSelectedObsCopy(selectedObsCopyWithImages);
    } catch (error) {
      console.error("Error fetching base64 images:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const createWordDocument = () => {
    try {
      const backgroundBriefParagraphs = backgroundBrief
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      const contentsParagraphs = contents
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      // const introductionParagraphs = introduction
      // .split("\n")
      // .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      const introductionParagraphs = introduction
      ? introduction
      .split("\n")
      .map((text) => new Paragraph({ children: [new TextRun(text)] }))
      : [];
      const exeSummaryParagraphs = exeSummary
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      const conclusionParagraphs = conclusion
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      const bestPracticeParagraph = bestPractice
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      const theWaForwardParagraph = theWayForward
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
        
      const otherDetailsParagraphs = otherDetails && otherDetails
        .split("\n")
        .map((text) => new Paragraph({ children: [new TextRun(text)] }));
      const disclaimer = `This report is based on information provided to us and our own observations during the audit. We have conducted
the audit in accordance with generally accepted auditing standards. This report is provided for informational purposes only and
should not be relied upon as a complete representation of the Safety of the organization's information systems. By using this
information, you agree that Momentum shall be held harmless in any event.`
      const doc = new Document({
        sections: [
          {
            properties: {
              page: {
                pageNumbers: {
                  start: 1,
                  formatType: NumberFormat.DECIMAL,
                },
              },
            },

            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        children: [
                          selectedDateTime.split("-").reverse().join("-"),
                          '                                                        Prepared by Momentum India                                                       ',
                          PageNumber.CURRENT,
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            },

            
            
            children: [
              coverImage &&
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: coverImage,
                      transformation: {
                        width: 590,
                        height: 800,
                      },
                    }),
                  ],
                }),
              new Paragraph({}),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun(`Client: ${selectedOrganization.label}`),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [new TextRun(`Service: ${reportType === "HSE"?"Health, Safety & Environment Audit":"Electrical Audit"}`)],
              }),
      
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun(
                    startDate.getTime() === endDate.getTime()
                      ? `Dated: ${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`
                      : `Dated: ${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()} to ${endDate.getDate()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getFullYear()}`
                  ),
                ],
              }),
              
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun(`Location: ${selectedSite.label}`),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        text: "www.momentumindia.in",
                        style: "Hyperlink",
                      }),
                    ],
                    link: "https://www.momentumindia.in/",
                  }),
                ],
              }),
            ],
          },        
          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff", // Adjust text color for visibility
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
              
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
                  // New Table for Particulars Details
              new Paragraph({
                children: [
                  new TextRun({
                    text: "DOCUMENT HISTORY",
                    font: "inherit",
                    color: "#307268",
                    size: 25,
                    bold:true
                  }),
                ],
                break: 1,
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
              new Table({
                width: { size: "100%", type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment:AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "PARTICULARS",
                                bold: true,
                                // color: "#efc71d",
                              }),
                            ],
                            shading: {
                              fill: "#efc71d",
                            },
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment:AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "DETAILS",
                                bold: true,
                                // color: "#efc71d",
                              }),
                            ],
                            shading: {
                              fill: "#efc71d",
                            },
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "Date of Visit",
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: startDate.getTime() === endDate.getTime()
                                  ? `${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`
                                  : `${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()} to ${endDate.getDate()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getFullYear()}`,
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "Document Prepared By",
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: name,
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "Date of Document Submission",
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: selectedDateTime.split("-").reverse().join("-"),
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "Document Version",
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: `Enter Document Version Here`,
                              }),
                            ],
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                    ],
                  }),
                ],
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "BACKGROUND - PROJECT BRIEF",
                    font: "inherit",
                    color: "#307268",
                    size: 25,
                    bold:true
                  }),
                ],
                break: 1,
              }),
              new Paragraph({ break: 1 }),
              ...backgroundBriefParagraphs,
              ...createBreaks(5),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Disclaimer: ",
                    font: "inherit",
                    bold:true,
                    size:18,
                    italics:true
                  }),
                  new TextRun({
                    text: disclaimer,
                    font: "inherit",
                    size:18,
                    italics:true
                  }),
                ],
                break: 1,
              }),
            ],
          },          

          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff", // Adjust text color for visibility
                              }),
                            ],
                          }),
                        ],
                        // shading: {
                        //   fill: "#02075d",
                        // },
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
             new Paragraph({
               children: [new TextRun("")],
               break: 1             
             }),
             new Paragraph({
               children: [new TextRun({
                text:"UNDERSTANDING OF REVIEW REPORT - CONTENTS",
                font:"inherit",
                color:"#307268",
                size:25,
                bold:true,
              })],
               break: 1               
             }),
              new Paragraph({ break: 1 }), // Line break after the heading
              ...contentsParagraphs,
            ],
          },

          ...(introduction ? [{
            properties: {
              page: {
                margin: {
                  top: 500,
                  left: 500,
                  right: 500,
                },
              },
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff", // Adjust text color for visibility
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "1. Introduction",
                    font: "inherit",
                    color: "#307268",
                    size: 25,
                    bold: true,
                  }),
                ],
                break: 1,
              }),
              new Paragraph({ break: 1 }), // Line break after the heading
              ...introductionParagraphs,
            ],
          }] : []),

          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff", // Adjust text color for visibility
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
             new Paragraph({
               children: [new TextRun("")],
               break: 1             
             }),
             new Paragraph({
               children: [new TextRun({
                text:reportType === "HSE"?"2. Executive Summary":"1. Executive Summary",
                font:"inherit",
                color:"#307268",
                size:25,
                bold:true,
              })],
               break: 1               
             }),
              new Paragraph({ break: 1 }), // Line break after the heading
              ...exeSummaryParagraphs,
            ],
          },

          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left: 500,
                  right: 500,
                },
              },
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff",
                              }),
                            ],
                          }),
                        ],
                        // shading: {
                        //   fill: "#02075d",
                        // },
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
             new Paragraph({
               children: [new TextRun("")],
               break: 1             
             }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: reportType === "HSE"?"3. Academic Information":"2. Academic Information",
                    font: "inherit",
                    color: "#307268",
                    size: 25,
                    bold: true,
                  }),
                ],
                break: 1,
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
              new Table({
                width: { size: "100%", type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 40, // Set fixed width for the "FACILITY INFORMATION" column
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "FACILITY INFORMATION",
                                bold: true,
                                color: "#ffffff",
                              }),
                            ],
                            shading: {
                              fill: "#307268",
                            },
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "COMMENTS & NOTES",
                                bold: true,
                                color: "#ffffff",
                              }),
                            ],
                            shading: {
                              fill: "#307268",
                            },
                          }),
                        ],
                        borders: {
                          top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                        },
                      }),
                    ],
                  }),
          
                  // Dynamic rows based on the provided object
                  ...Object.entries(facilityInfo).map(([key, value]) =>
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [new TextRun({ text: key })],
                            }),
                          ],
                          borders: {
                            top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                            bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                            left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                            right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [new TextRun({ text: value })],
                            }),
                          ],
                          borders: {
                            top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                            bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                            left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                            right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
                          },
                        }),
                      ],
                    })
                  ),
                ],
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
            ],
          },         
          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff", // Adjust text color for visibility
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1
              }),
              new Paragraph({
                children: [new TextRun({
                  text: reportType === "HSE"?"4. Critical Observations":"3. Critical Observations",
                  font: "inherit",
                  color: "#307268",
                  size: 25,
                  bold:true,
                })],
                break: 1
              }),
              new Paragraph({ break: 1 }), // Line break after the heading
              ...criticalObservations.map((observation) => {
                const lines = observation.observation.split('\n');
                return new Paragraph({
                  children: [
                    // Bullet point and the first line
                    new TextRun({
                      text: `\u2022 ${lines[0]}`,
                    }),
                    // Additional lines with adjusted indentation
                    ...lines.slice(1).map((line) => 
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `    ${line}`,
                          }),
                        ],
                        indent: {
                          left: 220,
                        },
                      })
                    ),
                  ],
                  indent: {
                    left: 200,
                  },
                });
              }),
              
              new Paragraph({ break: 1 }),
              ...otherDetailsParagraphs,
            ],
          },
          reportType === "HSE"?
          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left: 500,
                  right: 500,
                },
                size:{
                  orientation:PageOrientation.LANDSCAPE
                }
              },
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff",
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        shading: {
                          fill: "#ffffff",
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        shading: {
                          fill: "#ffffff",
                        },
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: reportType === "HSE"?"5. Critical Observations, Recommendations & Reasoning - HSE Report":"4. Critical Observations, Recommendations & Reasoning - Electrical Report" ,
                    font: "inherit",
                    color: "#307268",
                    size: 25,
                    bold: true,
                  }),
                ],
                break: 1,
              }),
              new Paragraph({ break: 1 }),
              ...(groupedObservations && typeof groupedObservations === 'object' ? Object.keys(groupedObservations).flatMap((tableType, index) => {
                const observations = groupedObservations[tableType];
                return [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${index + 1}. ${tableType}`,
                        font: "Arial",
                        size: 20,
                        bold: true,
                        color: "#307268",
                      }),
                    ],
                    spacing: { after: 200 },
                  }),
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Sr. No",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 0.5 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Area",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 3 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Observation",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 6.25 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Criticality",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 2.5 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Recommendations",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 7 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Is Reference",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 2.5 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          ...(reportType === "HSE"
                            ? [
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      alignment: AlignmentType.CENTER,
                                      children: [
                                        new TextRun({
                                          text: "Score",
                                          bold: true,
                                          size: 25,
                                          color: "#efc71d",
                                        }),
                                      ],
                                    }),
                                  ],
                                  shading: { fill: "#307268" },
                                  width: { size: 2.5 * 1440, type: WidthType.DXA },
                                  borders: {
                                    top: { style: BorderStyle.SINGLE },
                                    bottom: { style: BorderStyle.SINGLE },
                                    left: { style: BorderStyle.SINGLE },
                                    right: { style: BorderStyle.SINGLE },
                                  },
                                  verticalAlign: VerticalAlign.CENTER,
                                }),
                              ]
                            : []),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: "Photo Evidence",
                                    bold: true,
                                    size: 25,
                                    color: "#efc71d",
                                  }),
                                ],
                              }),
                            ],
                            shading: { fill: "#307268" },
                            width: { size: 4 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                        ],
                      }),
                      ...observations.map((obs, index) => new TableRow({
                        children: [
                          new TableCell({
                            children: [new Paragraph({ text: `${index + 1}`, alignment: AlignmentType.CENTER })],
                            width: { size: 0.5 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                          }),
                          new TableCell({
                            children: [new Paragraph(obs.area)],
                            width: { size: 3 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                          }),
                          new TableCell({
                            children: [new Paragraph(obs.observation)],
                            width: { size: 6.25 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                          }),
                          new TableCell({
                            children: [new Paragraph({ text: obs.criticality || "N/A", alignment: AlignmentType.CENTER })],
                            width: { size: 2.5 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [new Paragraph(obs.recommendations || "N/A")],
                            width: { size: 7 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                          }),
                          new TableCell({
                            children: [new Paragraph(obs.is_reference || "N/A")],
                            width: { size: 2.5 * 1440, type: WidthType.DXA },
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                          }),
                          ...(reportType === "HSE"
                            ? [
                                new TableCell({
                                  children: [
                                    new Paragraph({text:obs.score ? obs.score.toString() : "N/A",alignment:AlignmentType.CENTER}),
                                  ],
                                  width: { size: 2.5 * 1440, type: WidthType.DXA },
                                  borders: {
                                    top: { style: BorderStyle.SINGLE },
                                    bottom: { style: BorderStyle.SINGLE },
                                    left: { style: BorderStyle.SINGLE },
                                    right: { style: BorderStyle.SINGLE },
                                  },
                                  verticalAlign: VerticalAlign.CENTER,
                                }),
                              ]
                            : []),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: Array.isArray(obs.imageUrls) && obs.imageUrls.length > 0
                                  ? obs.imageUrls.map(image => new ImageRun({
                                      data: image,
                                      transformation: { width: 100, height: 70 },
                                    }))
                                  : [new TextRun("N/A")],
                              }),
                            ],
                            width: { size: 4 * 1440, type: WidthType.DXA },
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                              top: { style: BorderStyle.SINGLE },
                              bottom: { style: BorderStyle.SINGLE },
                              left: { style: BorderStyle.SINGLE },
                              right: { style: BorderStyle.SINGLE },
                            },
                          }),
                        ],
                      })),
                    ],
                    width: { size: 100, type: WidthType.PERCENTAGE },
                    borders: {
                      top: { style: BorderStyle.SINGLE },
                      bottom: { style: BorderStyle.SINGLE },
                      left: { style: BorderStyle.SINGLE },
                      right: { style: BorderStyle.SINGLE },
                      insideHorizontal: { style: BorderStyle.SINGLE },
                      insideVertical: { style: BorderStyle.SINGLE },
                    },
                  }),
                ];
              }):[]),                    
            ],
          }:{
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                },
                size:{
                  orientation:PageOrientation.LANDSCAPE
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff",
                              }),
                            ],
                          }),
                        ],
                        
                        verticalAlign: "center",
                        
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        
                        verticalAlign: "center",
                        
                      }),
                    ],
                    tableHeader: true,
                    
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
             new Paragraph({
               children: [new TextRun("")],
               break: 1             
             }),
             new Paragraph({
               children: [new TextRun({
                text: reportType === "HSE"?"5. Critical Observations, Recommendations & Reasoning - HSE Report":"4. Critical Observations, Recommendations & Reasoning - Electrical Report" ,
                font:"inherit",
               color:"#307268",
               size:25,
               bold:true,
              })],
               break: 1               
             }),
              new Paragraph({ break: 1 }),
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Sr. No",
                                bold: true,
                                size: 25,
                                color: "#efc71d",
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 0.5 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Area",
                                bold: true,
                                size: 25,
                                color: "#efc71d",
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 3 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Observation",
                                bold: true,
                                size: 25,
                                color: "#efc71d",
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 6.25 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Criticality",
                                bold: true,
                                color: "#efc71d",
                                size: 25,
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 2.5 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Recommendations",
                                bold: true,
                                color: "#efc71d",
                                size: 25,
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 7 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Is Reference",
                                bold: true,
                                size: 25,
                                color: "#efc71d",
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 2.5 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      ...(reportType === "HSE"
                        ? [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                    new TextRun({
                                      text: "Score",
                                      bold: true,
                                      size: 25,
                                      color: "#efc71d",
                                    }),
                                  ],
                                }),
                              ],
                              shading: {
                                fill: "#307268",
                              },
                              width: {
                                size: 2.5 * 1440,
                                type: WidthType.DXA,
                              },
                              verticalAlign: VerticalAlign.CENTER,
                            }),
                          ]
                        : []),
                      new TableCell({
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: "Photo Evidence",
                                bold: true,
                                size: 25,
                                color: "#efc71d",
                              }),
                            ],
                          }),
                        ],
                        shading: {
                          fill: "#307268",
                        },
                        width: {
                          size: 4 * 1440,
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                    ],
                  }),
                  ...selectedObsCopy.map((observation, index) => {
                    const rowBase64Images = observation.imageUrls || [];
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${index + 1}`,
                              alignment: AlignmentType.CENTER
                            })
                          ],
                          width: {
                            size: 0.5 * 1440,
                            type: WidthType.DXA,
                          },
                        }),
                        new TableCell({
                          children: [new Paragraph(observation.area)],
                          width: {
                            size: 3 * 1440,
                            type: WidthType.DXA,
                          },
                        }),
                        new TableCell({
                          children: [new Paragraph(observation.observation)],
                          width: {
                            size: 6.25 * 1440,
                            type: WidthType.DXA,
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: observation.criticality || "N/A",
                              alignment: AlignmentType.CENTER
                            })
                          ],
                          width: {
                            size: 2.5 * 1440,
                            type: WidthType.DXA,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [new Paragraph(observation.recommendations ? observation.recommendations : "N/A")],
                          width: {
                            size: 7 * 1440,
                            type: WidthType.DXA,
                          },
                        }),
                        new TableCell({
                          children: [new Paragraph(observation.is_reference ? observation.is_reference : "N/A")],
                          width: {
                            size: 2.5 * 1440,
                            type: WidthType.DXA,
                          },
                        }),
                        ...(reportType === "HSE"
                          ? [
                              new TableCell({
                                children: [
                                  new Paragraph(
                                    observation.score ? observation.score.toString() : "N/A"
                                  ),
                                ],
                                width: {
                                  size: 2.5 * 1440,
                                  type: WidthType.DXA,
                                },
                              }),
                            ]
                          : []),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: Array.isArray(observation.imageUrls) && observation.imageUrls.length > 0
                                ? observation.imageUrls.map((image, i) => {
                                    return new ImageRun({
                                      data: image,
                                      transformation: {
                                        width: 100,
                                        height: 70,
                                      },
                                    });
                                  })
                                : [new TextRun("N/A")],
                            }),
                          ],
                          width: {
                            size: 4 * 1440,
                            type: WidthType.DXA,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ],
                    });
                  }),
                ],
              })
            ],
          },      
          

          ...(reportType === "HSE" && bestPractice ? [{
            properties: {
              page: {
                margin: {
                  top: 500,
                  left: 500,
                  right: 500,
                },
              },
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff",
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 1,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "6. Global Best Practices",
                    font: "inherit",
                    color: "#307268",
                    size: 25,
                    bold: true,
                  }),
                ],
                break: 1,
              }),
              new Paragraph({ break: 1 }),
              ...bestPracticeParagraph,
            ],
          }] : []),

          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children:
              reportType !== "HSE"
                ? [
                 new Paragraph({
                   children: [new TextRun("")],
                   break: 1             
                 }),
                 new Paragraph({
                   children: [new TextRun({
                     text:"5.Scoring Table",
                     font:"inherit",
                     color:"#307268",
                     size:25,
                     bold:true,
                   })],
                   break: 1               
                 }),
                 new Paragraph({ break: 1 }),
                 new Paragraph({
                  children: [
                    new TextRun({
                      text: `Overall Score - ${((cumulativeScore / 10) * 100).toFixed(2)}%`,
                      font: "inherit",
                      color: "#efc71d",
                      size: 25,
                      bold: true,
                    }),
                  ],
                  break: 1,
                }),
                
                    new Table({
                      width: { size: "100%", type: WidthType.PERCENTAGE },
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                    new TextRun({
                                      text: "Electrical Safety",
                                      bold: true,
                                      color: "#efc71d",
                                      size: 25,
                                    }),
                                  ],
                                  shading: {
                                    fill: "#307260",
                                  },
                                }),
                              ],
                              
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                    new TextRun({
                                      text: "Max Score",
                                      bold: true,
                                      color: "#efc71d",
                                      size: 25,
                                    }),
                                  ],
                                  shading: {
                                    fill: "#307260",
                                  },
                                }),
                              ],
                              
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                    new TextRun({
                                      text: "Obtained Score",
                                      bold: true,
                                      color: "#efc71d",
                                      size: 25,
                                    }),
                                  ],
                                  shading: {
                                    fill: "#307260",
                                  },
                                }),
                              ],
                              
                              shading: {
                                fill: "#307260",
                              },
                            }),
                          ],
                        }),
                       ...scores.map(
                          (score, index) =>
                            new TableRow({
                              children: [
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      children: [
                                        new TextRun({
                                          text: score["Electrical Safety"],
                                          bold: true,
                                        }),
                                      ],
                                    }),
                                  ],
                                  margins: {
                                    top: 100,
                                    bottom: 100,
                                    left: 100,
                                    right: 100,
                                  },
                                  
                                }),
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      children: [
                                        new TextRun({
                                          text: score["Max Score"].toString(),
                                          bold: true,
                                        }),
                                      ],
                                      alignment: AlignmentType.CENTER,
                                    }),
                                  ],
                                  margins: {
                                    top: 100,
                                    bottom: 100,
                                    left: 100,
                                    right: 100,
                                  },
                                  
                                }),
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      children: [
                                        new TextRun({
                                          text: score["Score Obtained"].toString(),
                                          bold: true,
                                        }),
                                      ],
                                      alignment: AlignmentType.CENTER,
                                    }),
                                  ],
                                  margins: {
                                    top: 100,
                                    bottom: 100,
                                    left: 100,
                                    right: 100,
                                  },
                                  
                                }),
                              ],
                            }),
                        ),
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Cumulative",
                                      bold: true,
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              margins: {
                                top:100,
                                bottom: 100,
                                left: 100,
                                right: 100,
                              },
                              
                              shading: {
                                fill: "#efc71d",
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "10",
                                      bold: true,
                                    }),
                                                ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              margins: {
                                top: 100,
                                bottom: 100,
                                left:100,
                                right: 100,
                              },
                              
                              shading: {
                                fill: "#efc71d",
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: cumulativeScore.toString(),
                                      bold: true,
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              margins: {
                                top: 100,
                                bottom: 100,
                                left: 100,
                                right: 100,
                              },
                              
                              shading: {
                                fill: "#efc71d", // Yellow color code
                              },
                            }),
                          ],
                        }),
                      ],
                    }),
                  ]
                : [
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 100, // Adjust width as needed
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: ``,
                                    size: 30,
                                    font: "inherit",
                                    color: "#ffffff", 
                                  }),
                                ],
                              }),
                            ],
                            
                            verticalAlign: "center",
                            
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new ImageRun({
                                    data: base64logo,
                                    transformation: {
                                      width: 120,
                                      height: 40,
                                    },
                                  }),
                                ],
                              }),
                            ],
                            
                            verticalAlign: "center",
                            
                          }),
                        ],
                        tableHeader: true,
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    borders: {
                      top: { style: BorderStyle.NONE },
                      bottom: { style: BorderStyle.NONE },
                      left: { style: BorderStyle.NONE },
                      right: { style: BorderStyle.NONE },
                      insideHorizontal: { style: BorderStyle.NONE },
                      insideVertical: { style: BorderStyle.NONE },
                    },
                  }),
                 new Paragraph({
                   children: [new TextRun("")],
                   break: 1             
                 }),
                 new Paragraph({
                   children: [new TextRun({
                     text:"7. Dashboard",
                     font:"inherit",
                     color:"#307268",
                     size:25,
                     bold:true
                   })],
                   break: 1               
                 }),
                    new Paragraph({ break: 1 }),
                    chartImage &&
                      new Paragraph({
                        alignment:AlignmentType.CENTER,
                        children: [
                          new ImageRun({
                            data: chartImage,
                            transformation: {
                              width: 590,
                              height: 600,
                            },
                          }),
                        ],
                      }),
                  ],
          },

          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff", // Adjust text color for visibility
                              }),
                            ],
                          }),
                        ],
                        
                        verticalAlign: "center",
                       
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                    
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
             new Paragraph({
               children: [new TextRun("")],
               break: 1             
             }),
             new Paragraph({
               children: [new TextRun({
               text:reportType=== "HSE"?"8. Way Forward Plan":"6. Way Forward Plan",
               font:"inherit",
               color:"#307268",
               size:25,
               bold:true
              })],
               break: 1               
             }),
              new Paragraph({ break: 1 }), // Line break after the heading
              ...theWaForwardParagraph,
            ],
          },

          {
            properties: {
              page: {
                margin: {
                  top: 500,
                  left:500,
                  right:500
                }
              }
            },
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100, // Adjust width as needed
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: ``,
                                size: 30,
                                font: "inherit",
                                color: "#ffffff",
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: base64logo,
                                transformation: {
                                  width: 120,
                                  height: 40,
                                },
                              }),
                            ],
                          }),
                        ],
                        verticalAlign: "center",
                      }),
                    ],
                    tableHeader: true,
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                  insideHorizontal: { style: BorderStyle.NONE },
                  insideVertical: { style: BorderStyle.NONE },
                },
              }),
             new Paragraph({
               children: [new TextRun("")],
               break: 1             
             }),
             new Paragraph({
               children: [new TextRun({
               text:reportType === "HSE"?"9. Conclusion":"7. Conclusion",
               font:"inherit",
               color:"#307260",
               size:25,
               bold:true,
              })],
               break: 1               
             }),
              new Paragraph({ break: 1 }), // Line break after the heading
              ...conclusionParagraphs,
              new Paragraph({
                children: [new TextRun("")],
                break: 5             
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 5             
              }),
              new Paragraph({
                children: [new TextRun("")],
                break: 5             
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [new TextRun({text:`"Momentum - Empowering Progress"`,
                font:"inherit",
                color:"#307260",
                size:25,
                bold:true,
               })],
                break: 1               
              }),
            ],
          },
        ],
      });

      return doc;
    } catch (error) {
      console.error("Error creating Word document:", error);
      return null;
    }
  };

  const exportToWord = async () => {
    setLoading(true);
    // await fetchBase64Images();
    const doc = createWordDocument();

    if (doc) {
      try {
        doc.creator = "Your Name";
        doc.title = "Your Document Title";
        doc.description = "Your Document Description";
        doc.keywords = "Keyword1, Keyword2";

        const blob = await Packer.toBlob(doc);
        saveAs(
          blob,
          `${ReportUID}`
        );
      } catch (error) {
        console.error("Error exporting to Word:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Document is null. Export canceled.");
    }
  };

  return (
    <div>
      <button
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          background: isSaved ? "#efc71d" : "lightgrey",
          borderStyle: "none",
          borderRadius: "5px",
          fontWeight: "bold",
          padding: "11px 18px",
          textTransform:"uppercase",
          color:"white",
          fontSize:"1rem",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)"
        }}
        disabled={!isSaved || loading}
        onClick={exportToWord}
      >
        {loading ? 'Please wait...' : 'EXPORT TO WORD'} &nbsp;
        <FileDownloadIcon fontSize="small" />
      </button>
    </div>
  );
};

export default ExportWordDoc;