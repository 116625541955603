// import React, { useEffect, useState } from "react";
// import "./Dashboard.css";
// import ElectricalScoreDashboard from "../ElectricalScoreDashboard/ElectricalScoreDashboard";
// import HSEDashboard from "../HSEDashboard/HSEDashboard"; // Import your HSEDashboard component

// const Dashboard = ({allReports, getAllReports, allHseReports, getAllHseReports}) => {
//   const [activeTab, setActiveTab] = useState("electrical");

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   useEffect(() => {
//     getAllReports();
//     getAllHseReports();
//   }, [])
  

//   return (
//     <div className="dashboard">
//       <div className="tabs">
//         <div
//           className={`tab ${activeTab === "electrical" ? "active" : ""}`}
//           onClick={() => handleTabClick("electrical")}
//         >
//           Electrical Dashboard
//         </div>
//         <div
//           className={`tab ${activeTab === "hse" ? "active" : ""}`}
//           onClick={() => handleTabClick("hse")}
//         >
//           HSE Dashboard
//         </div>
//       </div>
//       {activeTab === "electrical" && <ElectricalScoreDashboard allReports={allReports} />} 
//       {activeTab === "hse" && <HSEDashboard allHseReports={allHseReports} />}
//     </div>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "./Dashboard.css";
import ElectricalScoreDashboard from "../ElectricalScoreDashboard/ElectricalScoreDashboard";
import HSEDashboard from "../HSEDashboard/HSEDashboard";
import CmvElectricalDashboard from "../CmvElectricalDashboard/CmvElectricalDashboard";
import HseCmvDashboard from "../HseCmvDashboard/HseCmvDashboard";
const Dashboard = ({ allReports, getAllReports, allHseReports, getAllHseReports }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    getAllReports();
    getAllHseReports();
  }, []);

  return (
    <div className="dashboard">
      {/* <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" >
        <Tab label="Electrical Dashboard" />
        <Tab label="CMV ELECTRICAL DASHBOARD" />
        <Tab label="HSE Dashboard" />
        <Tab label="CMV HSE Dashboard" />
      </Tabs>
      {activeTab === 0 && <ElectricalScoreDashboard allReports={allReports} />}
      {activeTab === 1 && <CmvElectricalDashboard allReports={allReports} />}
      {activeTab === 2 && <HSEDashboard allHseReports={allHseReports} />}
      {activeTab === 3 && <HseCmvDashboard allHseReports={allHseReports} />} */}
      <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" >
        <Tab label="Electrical Dashboard" />
        <Tab label="HSE Dashboard" />
      </Tabs>
      {activeTab === 0 && <CmvElectricalDashboard allReports={allReports} />}
      {activeTab === 1 && <HseCmvDashboard allHseReports={allHseReports} />}
    </div>
  );
};

export default Dashboard;


