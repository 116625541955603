import React from "react";
import "./Inspection.css";
import Select from 'react-select'
import { textAlign } from "@mui/system";

const Inspection = () => {
  return (
    <div className="new-report">
      <div className="heading">Inspection</div>
      <div style={{width:"20%",}}>
        <Select/>
        </div>
      <div className="inspection-table-container">
        <table className="inspection-table">
          <thead>
            <tr>
              <th>Inspection</th>
              <th>Actions</th>
              <th>Doc Number</th>
              <th>Score</th>
              <th>Conducted</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
            {/* Add table rows here */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Inspection;
