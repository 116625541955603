// import React, {useState} from "react";
// import "./App.css";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Home from "./Components/Home/Home";
// import SignupForm from "./Components/SignupForm/SignupForm";
// import LoginForm from "./Components/LoginForm/LoginForm";
// import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
// import { getAccountDetails } from "./Components/Services/localStorage"

// function App() {
//   const [loginSuccess, setLoginSuccess] = useState(false);
//   const {accessToken, userId} = getAccountDetails();
//   return (
//     <div className="App">
//       <Router>
//         <Routes>
//           <Route path="/signup" element={<SignupForm />} />
//           <Route path="/" element={<LoginForm setLoginSuccess={setLoginSuccess}/>} />
//           <Route path="/home" element={<Home loginSuccess={loginSuccess} />} />
//           <Route path="/forgot-password" element={<ForgotPassword />} />
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;

import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./Components/Home/Home";
import SignupForm from "./Components/SignupForm/SignupForm";
import LoginForm from "./Components/LoginForm/LoginForm";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import { getAccountDetails } from "./Components/Services/localStorage";

function App() {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { accessToken, userId } = getAccountDetails();

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Redirect to home if accessToken is present */}
          <Route path="/" element={accessToken ? <Navigate to="/home" /> : <LoginForm setLoginSuccess={setLoginSuccess} />} />
          <Route path="/signup" element={accessToken ? <Navigate to="/home" /> : <SignupForm />} />
          <Route path="/home" element={<Home loginSuccess={loginSuccess} />} />
          <Route path="/forgot-password" element={accessToken ? <Navigate to="/home" /> : <ForgotPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

