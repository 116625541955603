// import React, { useState, useEffect } from "react";
// import { Modal, TextField, Typography } from "@mui/material";
// import "./ReportModal.css";
// import Select from "react-select";
// import CreatableSelect from "react-select/creatable";
// import axios from "../../APIs/axios";
// import PeviewReportModal from "../PreviewReportModal/PreviewReportModal";
// import { config } from "../../config";
// import CloseIcon from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";
// import { getAccountDetails } from "../Services/localStorage";
// import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
// import { toast } from "react-toastify";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { addMinutes } from "date-fns";
// import { display } from "@mui/system";

// const ReportModal = ({
//   open,
//   setOpenModal,
//   areaList,
//   allData,
//   getAllData,
//   setLoading,
//   getAllReports
// }) => {
//   const [categoryList, setCategoryList] = useState([]);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [observations, setObservations] = useState([]);
//   const [selectedObservations, setSelectedObservations] = useState([]);
//   const [recommendations, setRecommendations] = useState([]);
//   const [openReviewModal, setOpenReviewModal] = useState(false);
//   const [ReportUID, setReportUID] = useState("");
//   const [newReportUID, setNewReportUID] = useState("");
//   const [selectedDateTime, setSelectedDateTime] = useState(null);
//   const [selectedOrganization, setSelectedOrganization] = useState(null);
//   const [selectedSite, setSelectedSite] = useState(null);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [orgList, setOrgList] = useState([]);
//   const [siteOptions, setSiteOptions] = useState([]);
//   const [reportHeader, setReportHeaders] = useState();
//   const [criticalObservations, setCriticalObservations] = useState([]);
//   const { userId } = getAccountDetails();
//   const [areasToDisplay, setAreasToDisplay] = useState([]);
//   const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
//   const [showConfirmationModal, setShowConfirmationModal] = useState(false);
//   const [notes, setNotes] = useState([]);
//   const [showDatePicker, setShowDatePicker] = useState(false);
//   const [scores, setScores] = useState([
//     {
//       "Electrical Safety": "Design & Installation",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Preventive maintenance",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Competency/Training",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Lock out-Tag out",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Drawings (As built) / Documents",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//   ]);
//   const cumulativeScore = scores.reduce(
//     (acc, row) => acc + parseFloat(row["Score Obtained"] || 0),
//     0
//   );

//   useEffect(() => {
//     updateReportUID();
//   }, [selectedOrganization, selectedSite, selectedDateTime]);

//   useEffect(() => {
//     getOrgList();
//     getReportHeaders();
//   }, [open, selectedOrganization]);

//   useEffect(() => {
//     generateUniqueId();
//     getCurrentDateTime();
//     getAllNotes();
//   }, []);

//   useEffect(() => {
//     const getSitesByOrganization = async (orgId) => {
//       try {
//         const response = await axios.get(
//           `${config.PATH}/api/organizations/${orgId}/sites`
//         );
//         const siteOptions = response.data.map((site) => ({
//           label: site.site_name,
//           value: site.site_name,
//         }));
//         setSiteOptions(siteOptions);
//       } catch (error) {
//         console.log("Error:", error.response?.data?.error || error.message);
//       }
//     };
//     if (selectedOrganization) {
//       getSitesByOrganization(selectedOrganization.value);
//     }
//   }, [selectedOrganization, selectedSite]);

//   const getAllNotes = async () => {
//     try {
//       const response = await axios.get(
//         `${config.PATH}/api/get-all-notes/${userId}`
//       );
//       setNotes(response.data.sort((a, b) => a.id - b.id));
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const getReportHeaders = async () => {
//     try {
//       const response = await axios.get(`${config.PATH}/api/get-headers`);
//       setReportHeaders(response.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const getOrgList = async () => {
//     try {
//       const response = await axios.get(`${config.PATH}/api/organizations`);
//       setOrgList(response.data);
//     } catch (error) {
//       console.log("Error:", error.response?.data?.error || error.message);
//     }
//   };

//   const handleCloseWithoutSaving = () => {
//     setShowConfirmationModal(false);
//     setOpenModal(false);
//     setSelectedObservations([]);
//     setRecommendations([]);
//     setSelectedArea([]);
//     setSelectedCategory([]);
//     setSelectedOrganization(null);
//     setSelectedSite(null);
//     setAreasToDisplay([]);
//     setCategoriesToDisplay([]);
//     generateUniqueId();
//     getCurrentDateTime();
//     setStartDate(null);
//     setEndDate(null);
//   };

//   const handleClose = () => {
//     if (selectedObservations.length > 0) {
//       setShowConfirmationModal(true);
//     } else {
//       handleCloseWithoutSaving();
//     }
//   };

//   const areaOptions = () => {
//     try {
//       const options = [];
//       areaList.map((area) => options.push({ label: area, value: area }));
//       return options;
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const categoryOptions = () => {
//     try {
//       const options = [];
//       let uniqueCategories = [...new Set(categoryList)];
//       uniqueCategories.map((cat) => options.push({ label: cat, value: cat }));
//       return options;
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const organizationOptions = orgList.map((e) => ({
//     label: e.org_name,
//     value: e.id,
//   }));

//   // const handleOrganizationSelection = async (selectedOption) => {
//   //   if (selectedOption && selectedOption.__isNew__ === true) {
//   //     // Validate the organization name to allow only '-' and '_'
//   //     const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
//   //       selectedOption.label
//   //     );

//   //     if (!isValidOrganizationName) {
//   //       toast.error(
//   //         "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed."
//   //       );
//   //       return;
//   //     }

//   //     const payload = {
//   //       org_name: selectedOption.label,
//   //     };

//   //     try {
//   //       const response = await axios.post(
//   //         `${config.PATH}/api/create-organization`,
//   //         payload
//   //       );

//   //       const newOrganization = response.data;
//   //       setSelectedOrganization({
//   //         label: newOrganization.org_name,
//   //         value: newOrganization.id,
//   //       });
//   //       setSelectedSite(null);
//   //     } catch (error) {
//   //       console.log("Failed to create organization:", error);
//   //       return;
//   //     }
//   //   } else {
//   //     setSelectedOrganization(selectedOption);
//   //     setSelectedSite(null);
//   //   }
//   // };

//   const handleOrganizationSelection = async (selectedOption) => {
//     if (selectedOption && selectedOption.__isNew__ === true) {
//       // Validate the organization name to allow only '-' and '_'
//       const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
//         selectedOption.label
//       );

//       // Check if the length exceeds 40 characters

//       const exceedsCharacterLimit = selectedOption.label.length > 40;

//       if (!isValidOrganizationName || exceedsCharacterLimit) {
//         let errorMessage = "";
//         if (!isValidOrganizationName) {
//           errorMessage = ("Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.");
//         } else {
//           errorMessage = `Organization name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
//         }
//         alert(errorMessage);
//         return;
//       }

//       const payload = {
//         org_name: selectedOption.label,
//       };

//       try {
//         const response = await axios.post(
//           `${config.PATH}/api/create-organization`,
//           payload
//         );

//         const newOrganization = response.data;
//         setSelectedOrganization({
//           label: newOrganization.org_name,
//           value: newOrganization.id,
//         });
//         setSelectedSite(null);
//       } catch (error) {
//         console.log("Failed to create organization:", error);
//         return;
//       }
//     } else {
//       setSelectedOrganization(selectedOption);
//       setSelectedSite(null);
//     }
//   };

//   const handleSiteSelection = async (selectedOption) => {
//     if (selectedOption && selectedOption.__isNew__ === true) {
//       const isValidSiteName = /^[a-zA-Z0-9_-]+$/.test(selectedOption.label);
//       if (!isValidSiteName) {
//         toast.error(
//           "Invalid site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed."
//         );
//         return;
//       }

//       // Check if the length exceeds 40 characters

//       const exceedsCharacterLimit = selectedOption.label.length > 40;

//       if (!isValidSiteName || exceedsCharacterLimit) {
//         let errorMessage = "";
//         if (!isValidSiteName) {
//           errorMessage = "Invalid Site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
//         } else {
//           errorMessage = `Site name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
//         }
//         alert(errorMessage);
//         return;
//       }

//       const payload = {
//         site_name: selectedOption.label,
//         org_id: selectedOrganization.value,
//       };
//       try {
//         await axios.post(`${config.PATH}/api/add-site`, payload);
//         updateReportUID();
//       } catch (error) {
//         console.log("Failed to create site:", error);
//         return;
//       }
//     }
//     setSelectedSite(selectedOption);
//     updateReportUID();
//   };

//   const handleChangeArea = (areas) => {
//     try {
//       setAreasToDisplay(areas);
//       const selectedArea = areas.map((e) => e.value);
//       setSelectedArea(selectedArea);

//       function getCategoriesByAreas(areas) {
//         const categories = [];
//         for (const item of allData.data) {
//           if (selectedArea.includes(item.area)) {
//             categories.push(item.category);
//           }
//         }
//         setCategoryList(categories);
//       }

//       if (areas.length === 0) {
//         // If the 'areas' array is empty, set 'categories' and 'categoriesToDisplay' to empty arrays.
//         setSelectedCategory([]);
//         setCategoriesToDisplay([]);
//         setSelectedObservations([]);
//         // Update the observations based on the empty category list
//         handleChangeCategory([]);
//         setCategoryList([]);
//       } else {
//         // Remove categories that are not associated with the selected areas.
//         const updatedSelectedCategory = selectedCategory.filter((category) => {
//           const categoryAreas = allData.data
//             .filter((item) => item.category === category)
//             .map((item) => item.area);

//           const includesArea = categoryAreas.some((area) =>
//             selectedArea.includes(area)
//           );

//           return includesArea;
//         });
//         setSelectedCategory(updatedSelectedCategory);
//         getCategoriesByAreas(areas);

//         // Set 'categoriesToDisplay' to the categories associated with the selected areas.
//         const updatedCategoriesToDisplay = categoryList
//           .filter((category) => updatedSelectedCategory.includes(category))
//           .map((category) => ({ label: category, value: category }));

//         const uniqueCategories = [
//           ...new Set(updatedCategoriesToDisplay.map(JSON.stringify)),
//         ].map(JSON.parse);
//         setCategoriesToDisplay(uniqueCategories);
//         // Update the observations based on the updated selected categories
//         handleChangeCategory(uniqueCategories);
//       }
//     } catch (error) {
//       console.log("An error occurred:", error);
//     }
//   };

//   const handleChangeCategory = async (cat) => {
//     setCategoriesToDisplay(cat);
//     const selectedCat = cat.map((e) => e.value);
//     setSelectedCategory(selectedCat);

//     const combinedData = allData.data.filter(e => e.edited_fields === null || e.edited_fields.includes("O") ).concat(
//       notes
//         .filter((note) => note.note_type === "electrical")
//         .map((note) => ({ ...note, isNote: true }))
//     );

//     // Filter based on selected category and area
//     const filteredObservations = combinedData.filter(
//       (item) =>
//         selectedCat.includes(item.category) && selectedArea.includes(item.area)
//     );

//     // Preserve the selection status (is_selected) from the old observations
//     const updatedObservations = filteredObservations.map((newObs) => {
//       const existingObs = observations.find(
//         (obs) => obs.observation === newObs.observation
//       );
//       return existingObs
//         ? {
//             ...newObs,
//             is_selected: existingObs.is_selected,
//             imageUrls: existingObs.imageUrls,
//           }
//         : newObs;
//     });

//     // Update the observations state with the filtered observations.
//     setObservations(updatedObservations);
//     setSelectedObservations(
//       selectedObservations.filter((selectedObs) =>
//         updatedObservations.some(
//           (e) => e.sr_no === selectedObs.sr_no || e.id === selectedObs.id
//         )
//       )
//     );
//     // Check if any observations in selectedObservations are related to removed categories
//     const removedCategories = selectedObservations.filter(
//       (selectedObs) => !selectedCat.includes(selectedObs.category)
//     );
//     if (removedCategories.length > 0) {
//       // Remove observations corresponding to removed categories
//       const updatedSelectedObservations = selectedObservations.filter(
//         (selectedObs) =>
//           !removedCategories.some(
//             (removedCat) =>
//               removedCat.category === selectedObs.category ||
//               removedCat.category === selectedObs.category
//           )
//       );
//       setSelectedObservations(updatedSelectedObservations);
//     }
//   };

//   const handleObservationSelection = (observation, index) => {
//     const tempObs = [...observations];
//     let isAlreadySelected = observation.is_selected;
//     if (isAlreadySelected) {
//       delete tempObs[index].is_selected;
//       setSelectedObservations(
//         [...selectedObservations].filter((e) => e.refIndex !== index)
//       );
//       setCriticalObservations(
//         [...selectedObservations].filter(
//           (e) => e.criticality === "High" && e.refIndex !== index
//         )
//       );
//     } else {
//       tempObs[index] = { ...observation, is_selected: 1 };
//       setSelectedObservations([
//         ...selectedObservations,
//         { ...observation, refIndex: index },
//       ]);
//       setCriticalObservations(
//         [...selectedObservations, observation].filter(
//           (e) => e.criticality === "High"
//         )
//       );
//     }
//     setObservations(tempObs);
//   };

//   // const handleObservationSelection = (observation, index) => {
//   //   const tempObs = [...observations];
//   //   let isAlreadySelected = observation.is_selected;

//   //   // Find the index of the observation within the entire observations array
//   //   const observationIndex = observations.findIndex(
//   //     (obs) => obs.observation === observation.observation
//   //   );

//   //   if (isAlreadySelected) {
//   //     delete tempObs[observationIndex].is_selected;
//   //     setSelectedObservations(
//   //       selectedObservations.filter((e) => e.refIndex !== observationIndex)
//   //     );
//   //     setCriticalObservations(
//   //       selectedObservations.filter(
//   //         (e) => e.criticality === "High" && e.refIndex !== observationIndex
//   //       )
//   //     );
//   //   } else {
//   //     tempObs[observationIndex] = { ...observation, is_selected: 1 };
//   //     setSelectedObservations([
//   //       ...selectedObservations,
//   //       { ...observation, refIndex: observationIndex },
//   //     ]);
//   //     setCriticalObservations(
//   //       [...selectedObservations, observation].filter(
//   //         (e) => e.criticality === "High"
//   //       )
//   //     );
//   //   }
//   //   setObservations(tempObs);
//   // };

//   const handleProceed = async () => {
//     try {
//       setLoading(true);
//       // Make API call with selectedObservations as input
//       // const payload = {
//       //   observations: selectedObservations.map((e) => e.observation),
//       //   // observations: selectedObservations.map((e) => e.id),
//       // };

//       // let res = await axios.post(`${config.PATH}/api/recommendations`, payload);
//       // const receivedRecommendations = res.data.data;
//       // setRecommendations(res.data.data);
//       setOpenReviewModal(true);
//       setOpenModal(false);
//       const reportData = {
//         report_id: ReportUID,
//         user_id: userId,
//         date_time: selectedDateTime,
//         organization: selectedOrganization
//           ? selectedOrganization.label
//           : selectedOrganization,
//         org_id: selectedOrganization
//           ? selectedOrganization.value
//           : selectedOrganization,
//         site: selectedSite ? selectedSite.label : selectedSite,
//         area: selectedArea,
//         category: selectedCategory,
//         scores: scores,
//         cumulative: cumulativeScore,
//         start_date: startDate,
//         end_date: endDate,
//         type: "primary",
//       };
//       await axios.post(`${config.PATH}/api/save-update-report`, reportData);

//       // const updatedObservations = [
//       //   ...observations.filter((obs) => obs.is_selected === 1),
//       //   ...observations.filter((obs) => obs.is_selected !== 1),
//       // ];

//       // setObservations(updatedObservations);

//       const observationsData = {
//         report_id: ReportUID,
//         // observation: selectedObservations,
//         // critical_observations: criticalObservations,
//         all_observations: observations,
//       };
//       await axios.post(
//         `${config.PATH}/api/save-update-observations`,
//         observationsData
//       );
//       setLoading(false);
//     } catch (error) {
//       console.log("Error:", error.response?.data?.error || error.message);
//     }
//   };

//   const updateReportUID = () => {
//     if (selectedOrganization && selectedOrganization.label && selectedSite && selectedSite.label && selectedDateTime) {
//       const formattedDateTime = selectedDateTime.split("T")[0];
//       setReportUID(
//         `${newReportUID}-${selectedOrganization.label}_${selectedSite.label}_${formattedDateTime}`
//       );
//     } else if (!selectedSite) {
//       // If selectedSite is null, revert back to the initial ReportUID
//       setReportUID(newReportUID);
//     }
//   };

//   const generateUniqueId = () => {
//     let id = "";
//     const characters =
//       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

//     for (let i = 0; i < 10; i++) {
//       const randomIndex = Math.floor(Math.random() * characters.length);
//       id += characters.charAt(randomIndex);
//     }

//     setReportUID(id);
//     setNewReportUID(id); // Set newReportUID initially to the generated ID
//   };

//   const handleDateTime = (e) => {
//     setSelectedDateTime(e.target.value);
//   };

//   const getCurrentDateTime = () => {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");
//     const hours = String(now.getHours()).padStart(2, "0");
//     const minutes = String(now.getMinutes()).padStart(2, "0");
//     setSelectedDateTime(`${year}-${month}-${day}T${hours}:${minutes}`);
//   };

//   const customSelectStyles = {
//     control: (provided) => ({
//       ...provided,
//       borderBottom: "none", // Hide the separator
//       boxShadow: "none", // Hide the box shadow
//       cursor: "pointer", // Show the pointer cursor
//     }),
//     dropdownIndicator: (provided) => ({
//       ...provided,
//       display: "none", // Hide the down arrow
//     }),
//     menu: (provided) => ({
//       ...provided,
//       zIndex: 1001,
//       position: 'absolute',
//     }),
//   };

//   const handleStartEndDate = (date, name) => {
//     if (!date) {
//       // Handle case when date is null or undefined
//       if (name === "start-date") {
//         setStartDate(null);
//       } else if (name === "end-date") {
//         setEndDate(null);
//       } else {
//         if (startDate) setEndDate(null);
//       }
//       return;
//     }

//     // Ensure that the date part is set to midnight (00:00:00)
//     const adjustedDate = new Date(date);
//     adjustedDate.setHours(0, 0, 0, 0);

//     if (name === "start-date") {
//       if (endDate && adjustedDate > endDate) {
//         toast.warning("Start date cannot be after the end date.");
//         setStartDate(null);
//         return;
//       }
//       setStartDate(adjustedDate);
//     } else {
//       if (startDate && adjustedDate < startDate) {
//         toast.warning("End date cannot be before the start date.");
//         setEndDate(null);
//         return;
//       }
//       setEndDate(adjustedDate);
//     }
//   };

//   // Group observations by area
//   const observationsByArea = observations.reduce((acc, observation) => {
//     const area = observation.area;
//     if (!acc[area]) {
//       acc[area] = [];
//     }
//     acc[area].push(observation);
//     return acc;
//   }, {});

//   const handleToggle = (e) => {
//     const content = e.currentTarget.nextElementSibling;
//     content.style.maxHeight = content.style.maxHeight ? null : content.scrollHeight + "px";
//   };

//   if (open) {
//     return (
//       <div>
//         <Modal open={open} onClose={handleClose}>
//           <div className="modal-container">
//             <div className="modal-header">
//               <Typography variant="h5">
//                 Create Electrical Safety Report
//               </Typography>
//               {/* <IconButton
//                 size="small"
//                 onClick={handleClose}
//                 className="close-icon"
//                 style={{backgroundColor:"#efc71d"}}
//                 autoFocus={false}
//               >
//                 <CloseIcon style={{color:"#307248"}} />
//               </IconButton> */}
//               <button
//                 className="custom-close-button"
//                 onClick={handleClose}
//               >
//                 &#10005; {/* Unicode for 'X' */}
//               </button>
//             </div>
//             <div className="modal-content">
//               <div className="modal-body">
//                 <Typography variant="body1">
//                 <div className="collapsable-div">
//                 {/* <div className="collapsable-header" onClick={handleToggle}>
//                   Report Details
//                 </div>
//                 <div className="collapsable-content">
//                   <div className="select-container">
//                     <Select
//                       styles={customSelectStyles}
//                       isDisabled={true}
//                       value={[{ label: ReportUID, value: ReportUID }]}
//                     />
//                     <div
//                       style={{
//                         borderStyle: "solid",
//                         borderRadius: "5px",
//                         borderWidth: "0.05mm",
//                         borderColor: "lightgray",
//                       }}
//                     >
//                       <input
//                         type="datetime-local"
//                         id="datetime-local"
//                         value={selectedDateTime}
//                         onChange={(e) => handleDateTime(e)}
//                         style={{
//                           width: "96%",
//                           border: "none",
//                           fontSize: "15px",
//                           padding: "8px",
//                           fontFamily: "inherit",
//                         }}
//                       />
//                     </div>
//                     <CreatableSelect
//                       placeholder="Organization"
//                       options={organizationOptions}
//                       onChange={handleOrganizationSelection}
//                       value={selectedOrganization}
//                       isSearchable
//                       isClearable
//                     />
//                     <CreatableSelect
//                       placeholder="Site"
//                       options={siteOptions}
//                       onChange={handleSiteSelection}
//                       value={selectedSite}
//                       isSearchable
//                       isClearable
//                       isDisabled={!selectedOrganization}
//                     />
//                   </div>
//                   <div className="flex-container-start-end-date">
//                     <div className="to-date-from-date">
//                       <DatePicker
//                         selected={startDate}
//                         onChange={(e) => handleStartEndDate(e, "start-date")}
//                         placeholderText="Audit Start Date"
//                         dateFormat="dd-MM-yyyy"
//                         showTimeSelect={false}
//                         utcOffset={0}
//                         className="class-for-date-pickers"
//                         maxDate={endDate}
//                         todayButton={"Today"}
//                       />
//                     </div>
//                     <div className="to-date-from-date">
//                       <DatePicker
//                         selected={endDate}
//                         onChange={(e) => handleStartEndDate(e, "end-date")}
//                         className="class-for-date-pickers"
//                         placeholderText="Audit End Date"
//                         dateFormat="dd-MM-yyyy"
//                         utcOffset={0}
//                         minDate={startDate}
//                         todayButton={"Today"}
//                       />
//                     </div>
//                   </div>
//                 </div> */}
//               </div>
//               {/* <div className="collapsable-header" onClick={handleToggle}>
//                   Report Details
//                 </div> */}
//                 <div className="collapsable-content">
//                   <div className="select-container">
//                     <Select
//                       styles={customSelectStyles}
//                       isDisabled={true}
//                       value={[{ label: ReportUID, value: ReportUID }]}
//                     />
//                     <div
//                       style={{
//                         borderStyle: "solid",
//                         borderRadius: "5px",
//                         borderWidth: "0.05mm",
//                         borderColor: "lightgray",
//                       }}
//                     >
//                       <input
//                         type="datetime-local"
//                         id="datetime-local"
//                         value={selectedDateTime}
//                         onChange={(e) => handleDateTime(e)}
//                         style={{
//                           width: "96%",
//                           border: "none",
//                           fontSize: "15px",
//                           padding: "8px",
//                           fontFamily: "inherit",
//                         }}
//                       />
//                     </div>
//                     <CreatableSelect
//                       placeholder="Organization"
//                       options={organizationOptions}
//                       onChange={handleOrganizationSelection}
//                       value={selectedOrganization}
//                       isSearchable
//                       isClearable
//                     />
//                     <CreatableSelect
//                       placeholder="Site"
//                       options={siteOptions}
//                       onChange={handleSiteSelection}
//                       value={selectedSite}
//                       isSearchable
//                       isClearable
//                       isDisabled={!selectedOrganization}
//                     />
//                   </div>
//                   <div className="flex-container-start-end-date">
//                     <div className="to-date-from-date">
//                       <DatePicker
//                         selected={startDate}
//                         onChange={(e) => handleStartEndDate(e, "start-date")}
//                         placeholderText="Audit Start Date"
//                         dateFormat="dd-MM-yyyy"
//                         showTimeSelect={false}
//                         utcOffset={0}
//                         className="class-for-date-pickers"
//                         maxDate={endDate}
//                         todayButton={"Today"}
//                       />
//                     </div>
//                     <div className="to-date-from-date">
//                       <DatePicker
//                         selected={endDate}
//                         onChange={(e) => handleStartEndDate(e, "end-date")}
//                         className="class-for-date-pickers"
//                         placeholderText="Audit End Date"
//                         dateFormat="dd-MM-yyyy"
//                         utcOffset={0}
//                         minDate={startDate}
//                         todayButton={"Today"}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                   <div className="flex-container">
//                     <Select
//                       className="select"
//                       placeholder="Area"
//                       options={areaOptions()}
//                       onChange={(e) => handleChangeArea(e)}
//                       isMulti={true}
//                       value={areasToDisplay}
//                     />
//                     <Select
//                       className="select"
//                       placeholder="Category"
//                       options={categoryOptions()}
//                       onChange={(e) => handleChangeCategory(e)}
//                       isMulti={true}
//                       value={categoriesToDisplay}
//                     />
//                   </div>

//                   {/* <div className="flex-container-start-end-date">
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(e) => handleStartEndDate(e, "start-date")}
//                       className="to-date-from-date"
//                       placeholderText="Enter Start Date"
//                       dateFormat="dd-MM-yyyy"
//                       showTimeSelect={false}
//                       utcOffset={0}
//                     />
//                     <DatePicker
//                       selected={endDate}
//                       onChange={(e) => handleStartEndDate(e, "end-date")}
//                       className="to-date-from-date"
//                       placeholderText="Enter End Date"
//                       dateFormat="dd-MM-yyyy"
//                       utcOffset={0}
//                     />
//                   </div> */}

//                 </Typography>
//                 <div className="observations-container">
//                   {observations.length > 0 && selectedCategory.length > 0 ? (
//                     <div className="observations-list">
//                       {observations.map((observation, index) => (
//                         <div key={index} className="observation-item-checkbox">
//                           <input
//                             type="checkbox"
//                             checked={observation.is_selected === 1}
//                             onChange={() =>
//                               handleObservationSelection(observation, index)
//                             }
//                           />
//                           {/* <span>
//                             {observation.observation} (
//                             <span style={{ fontWeight: "bold" }}>
//                               {observation.area}, {observation.category}
//                             </span>
//                             )
//                           </span> */}
//                           <span>
//                             {observation.observation} (
//                             <span style={{ fontWeight: "bold" }}>
//                               {observation.area}, {observation.category}
//                             </span>
//                             ){" "}
//                             {/* {observation.edited_fields && (
//                               <sup>
//                                 {JSON.parse(observation.edited_fields).map(
//                                   (field, index) => (
//                                     <span key={index}>
//                                       {index > 0 && ", "}{" "}
//                                       {field}
//                                     </span>
//                                   )
//                                 )}
//                               </sup>
//                             )} */}
//                           </span>

//                           {observation.isNote && (
//                             <span className="note-label">(Note)</span>
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   ) : (
//                     <div className="no-observations">
//                       No observations available.
//                     </div>
//                   )}
//                 </div>
//                 {/* <div className="observations-container">
//                   {Object.entries(observationsByArea).length > 0 ? (
//                     Object.entries(observationsByArea).map(
//                       ([area, areaObservations]) => (
//                         <div key={area}>
//                           <h3><u><i>{area}</i></u></h3>
//                           <div className="observations-list">
//                             {areaObservations.map((observation, index) => (
//                               <div
//                                 key={index}
//                                 className="observation-item-checkbox"
//                               >
//                                 <input
//                                   type="checkbox"
//                                   checked={observation.is_selected === 1}
//                                   onChange={() =>
//                                     handleObservationSelection(
//                                       observation,
//                                       index
//                                     )
//                                   }
//                                 />
//                                 <span>
//                                   {observation.observation}
//                                   (
//                                   <span style={{ fontWeight: "bold" }}>
//                                     {area}
//                                   </span>
//                                   )
//                                 </span>
//                                 {observation.isNote && (
//                                   <span className="note-label">(Note)</span>
//                                 )}
//                               </div>
//                             ))}
//                           </div>
//                         </div>
//                       )
//                     )
//                   ) : (
//                     <div className="no-observations">
//                       No observations available.
//                     </div>
//                   )}
//                 </div> */}
//                 <div className="get-recommendations-container">
//                   {/* <p
//                     className="legend"
//                     style={{ fontSize: "small", fontStyle: "italic" }}
//                   >
//                     Legend: A &#8594; Area Edited, CA &#8594; Category Edited, CH &#8594; Check Points Edited, O &#8594;
//                     Observation Edited, CR &#8594; Criticality Edited, R &#8594; Recommendations Edited, I &#8594;
//                     IS Reference Edited.
//                   </p> */}
//                   <button
//                     // variant="contained"
//                     onClick={handleProceed}
//                     disabled={
//                       selectedObservations.length === 0 ||
//                       !selectedOrganization ||
//                       !selectedSite ||
//                       !startDate ||
//                       !endDate
//                     }
//                     style={{background: selectedObservations.length === 0 ||
//                       !selectedOrganization ||
//                       !selectedSite ||
//                       !startDate ||
//                       !endDate?"lightgrey":"#efc71d"}}
//                       className="button-styles"
//                   >
//                     Proceed
//                   </button>
//                 </div>
//               </div>
//               {/* <div className="modal-footer">
//                 <hr />
//                 <div style={{ textAlign: "center" }}>
//                   <Button variant="contained" onClick={handleClose}>
//                     Close
//                   </Button>
//                 </div>
//               </div> */}
//             </div>
//           </div>
//         </Modal>
//         {showConfirmationModal && (
//           <ConfirmationModal
//             setShowConfirmationModal={setShowConfirmationModal}
//             handleCloseWithoutSaving={handleCloseWithoutSaving}
//           />
//         )}
//       </div>
//     );
//   } else if (openReviewModal) {
//     return (
//       <PeviewReportModal
//         open={openReviewModal}
//         setOpenModal={setOpenReviewModal}
//         setOpenCreateReportModal={setOpenModal}
//         reportHeader={reportHeader}
//         selectedObservations={selectedObservations}
//         recommendations={recommendations}
//         selectedArea={selectedArea}
//         selectedCategory={selectedCategory}
//         setSelectedObservations={setSelectedObservations}
//         setRecommendations={setRecommendations}
//         setSelectedArea={setSelectedArea}
//         setSelectedCategory={setSelectedCategory}
//         setSelectedOrganization={setSelectedOrganization}
//         setSelectedSite={setSelectedSite}
//         selectedOrganization={selectedOrganization}
//         selectedSite={selectedSite}
//         ReportUID={ReportUID}
//         selectedDateTime={selectedDateTime}
//         observations={observations}
//         setAreasToDisplay={setAreasToDisplay}
//         setCategoriesToDisplay={setCategoriesToDisplay}
//         setObservations={setObservations}
//         getAllData={getAllData}
//         startDate={startDate}
//         endDate={endDate}
//         setStartDate={setStartDate}
//         setEndDate={setEndDate}
//         generateUniqueId={generateUniqueId}
//         getCurrentDateTime={getCurrentDateTime}
//         // getCategoriesByAreas={getCategoriesByAreas}
//         setLoading={setLoading}
//         areaOptions={areaOptions}
//         allData={allData}
//         getAllReports={getAllReports}
//       />
//     );
//   }
// };

// export default ReportModal;

// import React, { useState, useEffect } from "react";
// import { Modal, TextField, Typography } from "@mui/material";
// import "./ReportModal.css";
// import Select from "react-select";
// import CreatableSelect from "react-select/creatable";
// import axios from "../../APIs/axios";
// import PeviewReportModal from "../PreviewReportModal/PreviewReportModal";
// import { config } from "../../config";
// import CloseIcon from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";
// import { getAccountDetails } from "../Services/localStorage";
// import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
// import { toast } from "react-toastify";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { addMinutes } from "date-fns";
// import { display } from "@mui/system";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// const ReportModal = ({
//   open,
//   setOpenModal,
//   areaList,
//   allData,
//   getAllData,
//   setLoading,
//   getAllReports,
// }) => {
//   const [categoryList, setCategoryList] = useState([]);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [observations, setObservations] = useState([]);
//   const [selectedObservations, setSelectedObservations] = useState([]);
//   const [recommendations, setRecommendations] = useState([]);
//   const [openReviewModal, setOpenReviewModal] = useState(false);
//   const [ReportUID, setReportUID] = useState("");
//   const [newReportUID, setNewReportUID] = useState("");
//   const [selectedDateTime, setSelectedDateTime] = useState(null);
//   const [selectedOrganization, setSelectedOrganization] = useState(null);
//   const [selectedSite, setSelectedSite] = useState(null);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [orgList, setOrgList] = useState([]);
//   const [siteOptions, setSiteOptions] = useState([]);
//   const [reportHeader, setReportHeaders] = useState();
//   const [criticalObservations, setCriticalObservations] = useState([]);
//   const { userId } = getAccountDetails();
//   const [areasToDisplay, setAreasToDisplay] = useState([]);
//   const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
//   const [showConfirmationModal, setShowConfirmationModal] = useState(false);
//   const [notes, setNotes] = useState([]);
//   const [showDatePicker, setShowDatePicker] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [scores, setScores] = useState([
//     {
//       "Electrical Safety": "Design & Installation",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Preventive maintenance",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Competency/Training",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Lock out-Tag out",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//     {
//       "Electrical Safety": "Drawings (As built) / Documents",
//       "Max Score": 2,
//       "Score Obtained": 0,
//     },
//   ]);
//   const cumulativeScore = scores.reduce(
//     (acc, row) => acc + parseFloat(row["Score Obtained"] || 0),
//     0
//   );

//   useEffect(() => {
//     updateReportUID();
//   }, [selectedOrganization, selectedSite, selectedDateTime]);

//   useEffect(() => {
//     getOrgList();
//     getReportHeaders();
//   }, [open, selectedOrganization]);

//   useEffect(() => {
//     generateUniqueId();
//     getCurrentDateTime();
//     getAllNotes();
//   }, []);

//   useEffect(() => {
//     const getSitesByOrganization = async (orgId) => {
//       try {
//         const response = await axios.get(
//           `${config.PATH}/api/organizations/${orgId}/sites`
//         );
//         const siteOptions = response.data.map((site) => ({
//           label: site.site_name,
//           value: site.site_name,
//         }));
//         setSiteOptions(siteOptions);
//       } catch (error) {
//         console.log("Error:", error.response?.data?.error || error.message);
//       }
//     };
//     if (selectedOrganization) {
//       getSitesByOrganization(selectedOrganization.value);
//     }
//   }, [selectedOrganization, selectedSite]);

//   const getAllNotes = async () => {
//     try {
//       const response = await axios.get(
//         `${config.PATH}/api/get-all-notes/${userId}`
//       );
//       setNotes(response.data.sort((a, b) => a.id - b.id));
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const getReportHeaders = async () => {
//     try {
//       const response = await axios.get(`${config.PATH}/api/get-headers`);
//       setReportHeaders(response.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const getOrgList = async () => {
//     try {
//       const response = await axios.get(`${config.PATH}/api/organizations`);
//       setOrgList(response.data);
//     } catch (error) {
//       console.log("Error:", error.response?.data?.error || error.message);
//     }
//   };

//   const handleCloseWithoutSaving = () => {
//     setShowConfirmationModal(false);
//     setOpenModal(false);
//     setSelectedObservations([]);
//     setRecommendations([]);
//     setSelectedArea([]);
//     setSelectedCategory([]);
//     setSelectedOrganization(null);
//     setSelectedSite(null);
//     setAreasToDisplay([]);
//     setCategoriesToDisplay([]);
//     generateUniqueId();
//     getCurrentDateTime();
//     setStartDate(null);
//     setEndDate(null);
//   };

//   const handleClose = () => {
//     if (selectedObservations.length > 0) {
//       setShowConfirmationModal(true);
//     } else {
//       handleCloseWithoutSaving();
//     }
//   };

//   const areaOptions = () => {
//     try {
//       const options = [];
//       areaList.map((area) => options.push({ label: area, value: area }));
//       return options;
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const categoryOptions = () => {
//     try {
//       const options = [];
//       let uniqueCategories = [...new Set(categoryList)];
//       uniqueCategories.map((cat) => options.push({ label: cat, value: cat }));
//       return options;
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const organizationOptions = orgList.map((e) => ({
//     label: e.org_name,
//     value: e.id,
//   }));

//   // const handleOrganizationSelection = async (selectedOption) => {
//   //   if (selectedOption && selectedOption.__isNew__ === true) {
//   //     // Validate the organization name to allow only '-' and '_'
//   //     const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
//   //       selectedOption.label
//   //     );

//   //     if (!isValidOrganizationName) {
//   //       toast.error(
//   //         "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed."
//   //       );
//   //       return;
//   //     }

//   //     const payload = {
//   //       org_name: selectedOption.label,
//   //     };

//   //     try {
//   //       const response = await axios.post(
//   //         `${config.PATH}/api/create-organization`,
//   //         payload
//   //       );

//   //       const newOrganization = response.data;
//   //       setSelectedOrganization({
//   //         label: newOrganization.org_name,
//   //         value: newOrganization.id,
//   //       });
//   //       setSelectedSite(null);
//   //     } catch (error) {
//   //       console.log("Failed to create organization:", error);
//   //       return;
//   //     }
//   //   } else {
//   //     setSelectedOrganization(selectedOption);
//   //     setSelectedSite(null);
//   //   }
//   // };

//   const handleOrganizationSelection = async (selectedOption) => {
//     if (selectedOption && selectedOption.__isNew__ === true) {
//       // Validate the organization name to allow only '-' and '_'
//       const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
//         selectedOption.label
//       );

//       // Check if the length exceeds 40 characters

//       const exceedsCharacterLimit = selectedOption.label.length > 40;

//       if (!isValidOrganizationName || exceedsCharacterLimit) {
//         let errorMessage = "";
//         if (!isValidOrganizationName) {
//           errorMessage =
//             "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
//         } else {
//           errorMessage = `Organization name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
//         }
//         alert(errorMessage);
//         return;
//       }

//       const payload = {
//         org_name: selectedOption.label,
//       };

//       try {
//         const response = await axios.post(
//           `${config.PATH}/api/create-organization`,
//           payload
//         );

//         const newOrganization = response.data;
//         setSelectedOrganization({
//           label: newOrganization.org_name,
//           value: newOrganization.id,
//         });
//         setSelectedSite(null);
//       } catch (error) {
//         console.log("Failed to create organization:", error);
//         return;
//       }
//     } else {
//       setSelectedOrganization(selectedOption);
//       setSelectedSite(null);
//     }
//   };

//   const handleSiteSelection = async (selectedOption) => {
//     if (selectedOption && selectedOption.__isNew__ === true) {
//       const isValidSiteName = /^[a-zA-Z0-9_-]+$/.test(selectedOption.label);
//       if (!isValidSiteName) {
//         toast.error(
//           "Invalid site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed."
//         );
//         return;
//       }

//       // Check if the length exceeds 40 characters

//       const exceedsCharacterLimit = selectedOption.label.length > 40;

//       if (!isValidSiteName || exceedsCharacterLimit) {
//         let errorMessage = "";
//         if (!isValidSiteName) {
//           errorMessage =
//             "Invalid Site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
//         } else {
//           errorMessage = `Site name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
//         }
//         alert(errorMessage);
//         return;
//       }

//       const payload = {
//         site_name: selectedOption.label,
//         org_id: selectedOrganization.value,
//       };
//       try {
//         await axios.post(`${config.PATH}/api/add-site`, payload);
//         updateReportUID();
//       } catch (error) {
//         console.log("Failed to create site:", error);
//         return;
//       }
//     }
//     setSelectedSite(selectedOption);
//     updateReportUID();
//   };

//   const handleChangeArea = (areas) => {
//     try {
//       setAreasToDisplay(areas);
//       const selectedArea = areas.map((e) => e.value);
//       setSelectedArea(selectedArea);

//       function getCategoriesByAreas(areas) {
//         const categories = [];
//         for (const item of allData.data) {
//           if (selectedArea.includes(item.area)) {
//             categories.push(item.category);
//           }
//         }
//         setCategoryList(categories);
//       }

//       if (areas.length === 0) {
//         // If the 'areas' array is empty, set 'categories' and 'categoriesToDisplay' to empty arrays.
//         setSelectedCategory([]);
//         setCategoriesToDisplay([]);
//         setSelectedObservations([]);
//         // Update the observations based on the empty category list
//         handleChangeCategory([]);
//         setCategoryList([]);
//       } else {
//         // Remove categories that are not associated with the selected areas.
//         const updatedSelectedCategory = selectedCategory.filter((category) => {
//           const categoryAreas = allData.data
//             .filter((item) => item.category === category)
//             .map((item) => item.area);

//           const includesArea = categoryAreas.some((area) =>
//             selectedArea.includes(area)
//           );

//           return includesArea;
//         });
//         setSelectedCategory(updatedSelectedCategory);
//         getCategoriesByAreas(areas);

//         // Set 'categoriesToDisplay' to the categories associated with the selected areas.
//         const updatedCategoriesToDisplay = categoryList
//           .filter((category) => updatedSelectedCategory.includes(category))
//           .map((category) => ({ label: category, value: category }));

//         const uniqueCategories = [
//           ...new Set(updatedCategoriesToDisplay.map(JSON.stringify)),
//         ].map(JSON.parse);
//         setCategoriesToDisplay(uniqueCategories);
//         // Update the observations based on the updated selected categories
//         handleChangeCategory(uniqueCategories);
//       }
//     } catch (error) {
//       console.log("An error occurred:", error);
//     }
//   };

//   const handleChangeCategory = async (cat) => {
//     setCategoriesToDisplay(cat);
//     const selectedCat = cat.map((e) => e.value);
//     setSelectedCategory(selectedCat);

//     const combinedData = allData.data
//       .filter((e) => e.edited_fields === null || e.edited_fields.includes("O"))
//       .concat(
//         notes
//           .filter((note) => note.note_type === "electrical")
//           .map((note) => ({ ...note, isNote: true }))
//       );

//     // Filter based on selected category and area
//     const filteredObservations = combinedData.filter(
//       (item) =>
//         selectedCat.includes(item.category) && selectedArea.includes(item.area)
//     );

//     // Preserve the selection status (is_selected) from the old observations
//     const updatedObservations = filteredObservations.map((newObs) => {
//       const existingObs = observations.find(
//         (obs) => obs.observation === newObs.observation
//       );
//       return existingObs
//         ? {
//             ...newObs,
//             is_selected: existingObs.is_selected,
//             imageUrls: existingObs.imageUrls,
//           }
//         : newObs;
//     });

//     // Update the observations state with the filtered observations.
//     setObservations(updatedObservations);
//     setSelectedObservations(
//       selectedObservations.filter((selectedObs) =>
//         updatedObservations.some(
//           (e) => e.sr_no === selectedObs.sr_no || e.id === selectedObs.id
//         )
//       )
//     );
//     // Check if any observations in selectedObservations are related to removed categories
//     const removedCategories = selectedObservations.filter(
//       (selectedObs) => !selectedCat.includes(selectedObs.category)
//     );
//     if (removedCategories.length > 0) {
//       // Remove observations corresponding to removed categories
//       const updatedSelectedObservations = selectedObservations.filter(
//         (selectedObs) =>
//           !removedCategories.some(
//             (removedCat) =>
//               removedCat.category === selectedObs.category ||
//               removedCat.category === selectedObs.category
//           )
//       );
//       setSelectedObservations(updatedSelectedObservations);
//     }
//   };

//   const handleObservationSelection = (observation, index) => {
//     const tempObs = [...observations];
//     let isAlreadySelected = observation.is_selected;
//     if (isAlreadySelected) {
//       delete tempObs[index].is_selected;
//       setSelectedObservations(
//         [...selectedObservations].filter((e) => e.refIndex !== index)
//       );
//       setCriticalObservations(
//         [...selectedObservations].filter(
//           (e) => e.criticality === "High" && e.refIndex !== index
//         )
//       );
//     } else {
//       tempObs[index] = { ...observation, is_selected: 1 };
//       setSelectedObservations([
//         ...selectedObservations,
//         { ...observation, refIndex: index },
//       ]);
//       setCriticalObservations(
//         [...selectedObservations, observation].filter(
//           (e) => e.criticality === "High"
//         )
//       );
//     }
//     setObservations(tempObs);
//   };

//   // const handleObservationSelection = (observation, index) => {
//   //   const tempObs = [...observations];
//   //   let isAlreadySelected = observation.is_selected;

//   //   // Find the index of the observation within the entire observations array
//   //   const observationIndex = observations.findIndex(
//   //     (obs) => obs.observation === observation.observation
//   //   );

//   //   if (isAlreadySelected) {
//   //     delete tempObs[observationIndex].is_selected;
//   //     setSelectedObservations(
//   //       selectedObservations.filter((e) => e.refIndex !== observationIndex)
//   //     );
//   //     setCriticalObservations(
//   //       selectedObservations.filter(
//   //         (e) => e.criticality === "High" && e.refIndex !== observationIndex
//   //       )
//   //     );
//   //   } else {
//   //     tempObs[observationIndex] = { ...observation, is_selected: 1 };
//   //     setSelectedObservations([
//   //       ...selectedObservations,
//   //       { ...observation, refIndex: observationIndex },
//   //     ]);
//   //     setCriticalObservations(
//   //       [...selectedObservations, observation].filter(
//   //         (e) => e.criticality === "High"
//   //       )
//   //     );
//   //   }
//   //   setObservations(tempObs);
//   // };

//   const handleProceed = async () => {
//     try {
//       setLoading(true);
//       // Make API call with selectedObservations as input
//       // const payload = {
//       //   observations: selectedObservations.map((e) => e.observation),
//       //   // observations: selectedObservations.map((e) => e.id),
//       // };

//       // let res = await axios.post(`${config.PATH}/api/recommendations`, payload);
//       // const receivedRecommendations = res.data.data;
//       // setRecommendations(res.data.data);
//       setOpenReviewModal(true);
//       setOpenModal(false);
//       const reportData = {
//         report_id: ReportUID,
//         user_id: userId,
//         date_time: selectedDateTime,
//         organization: selectedOrganization
//           ? selectedOrganization.label
//           : selectedOrganization,
//         org_id: selectedOrganization
//           ? selectedOrganization.value
//           : selectedOrganization,
//         site: selectedSite ? selectedSite.label : selectedSite,
//         area: selectedArea,
//         category: selectedCategory,
//         scores: scores,
//         cumulative: cumulativeScore,
//         start_date: startDate,
//         end_date: endDate,
//         type: "primary",
//       };
//       await axios.post(`${config.PATH}/api/save-update-report`, reportData);

//       // const updatedObservations = [
//       //   ...observations.filter((obs) => obs.is_selected === 1),
//       //   ...observations.filter((obs) => obs.is_selected !== 1),
//       // ];

//       // setObservations(updatedObservations);

//       const observationsData = {
//         report_id: ReportUID,
//         // observation: selectedObservations,
//         // critical_observations: criticalObservations,
//         all_observations: observations,
//       };
//       await axios.post(
//         `${config.PATH}/api/save-update-observations`,
//         observationsData
//       );
//       setLoading(false);
//     } catch (error) {
//       console.log("Error:", error.response?.data?.error || error.message);
//     }
//   };

//   const updateReportUID = () => {
//     if (
//       selectedOrganization &&
//       selectedOrganization.label &&
//       selectedSite &&
//       selectedSite.label &&
//       selectedDateTime
//     ) {
//       const formattedDateTime = selectedDateTime.split("T")[0];
//       setReportUID(
//         `${newReportUID}-${selectedOrganization.label}_${selectedSite.label}_${formattedDateTime}`
//       );
//     } else if (!selectedSite) {
//       // If selectedSite is null, revert back to the initial ReportUID
//       setReportUID(newReportUID);
//     }
//   };

//   const generateUniqueId = () => {
//     let id = "";
//     const characters =
//       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

//     for (let i = 0; i < 10; i++) {
//       const randomIndex = Math.floor(Math.random() * characters.length);
//       id += characters.charAt(randomIndex);
//     }

//     setReportUID(id);
//     setNewReportUID(id); // Set newReportUID initially to the generated ID
//   };

//   const handleDateTime = (e) => {
//     setSelectedDateTime(e.target.value);
//   };

//   const getCurrentDateTime = () => {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");
//     const hours = String(now.getHours()).padStart(2, "0");
//     const minutes = String(now.getMinutes()).padStart(2, "0");
//     setSelectedDateTime(`${year}-${month}-${day}T${hours}:${minutes}`);
//   };

//   const customSelectStyles = {
//     control: (provided) => ({
//       ...provided,
//       borderBottom: "none", // Hide the separator
//       boxShadow: "none", // Hide the box shadow
//       cursor: "pointer", // Show the pointer cursor
//     }),
//     dropdownIndicator: (provided) => ({
//       ...provided,
//       display: "none", // Hide the down arrow
//     }),
//     menu: (provided) => ({
//       ...provided,
//       zIndex: 1001,
//       position: "absolute",
//     }),
//   };

//   const handleStartEndDate = (date, name) => {
//     if (!date) {
//       // Handle case when date is null or undefined
//       if (name === "start-date") {
//         setStartDate(null);
//       } else if (name === "end-date") {
//         setEndDate(null);
//       } else {
//         if (startDate) setEndDate(null);
//       }
//       return;
//     }

//     // Ensure that the date part is set to midnight (00:00:00)
//     const adjustedDate = new Date(date);
//     adjustedDate.setHours(0, 0, 0, 0);

//     if (name === "start-date") {
//       if (endDate && adjustedDate > endDate) {
//         toast.warning("Start date cannot be after the end date.");
//         setStartDate(null);
//         return;
//       }
//       setStartDate(adjustedDate);
//     } else {
//       if (startDate && adjustedDate < startDate) {
//         toast.warning("End date cannot be before the start date.");
//         setEndDate(null);
//         return;
//       }
//       setEndDate(adjustedDate);
//     }
//   };

//   // Group observations by area
//   const observationsByArea = observations.reduce((acc, observation) => {
//     const area = observation.area;
//     if (!acc[area]) {
//       acc[area] = [];
//     }
//     acc[area].push(observation);
//     return acc;
//   }, {});

// const handleToggle = (e) => {
//   const content = e.currentTarget.nextElementSibling;
//   content.style.maxHeight = isExpanded ? null : content.scrollHeight + "px";
//   setIsExpanded(!isExpanded);
// };

//   if (open) {
//     return (
//       <div>
//         <Modal open={open} onClose={handleClose}>
//           <div className="modal-container">
//             <div className="modal-header">
//               <Typography variant="h5">
//                 Create Electrical Safety Report
//               </Typography>
//               {/* <IconButton
//                 size="small"
//                 onClick={handleClose}
//                 className="close-icon"
//                 style={{backgroundColor:"#efc71d"}}
//                 autoFocus={false}
//               >
//                 <CloseIcon style={{color:"#307248"}} />
//               </IconButton> */}
//               <button className="custom-close-button" onClick={handleClose}>
//                 &#10005; {/* Unicode for 'X' */}
//               </button>
//             </div>
//             <div className="modal-content">
//               <div className="modal-body">
//                 <Typography variant="body1">
//                   <div className="collapsable-div">
//                     <div className="collapsable-header" onClick={handleToggle}>
//                       {isExpanded ? "Hide Details" : "Show Details"}
//                       {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                     </div>

//                     <div className="collapsable-content">
//                       <div className="select-container">
//                         <Select
//                           styles={customSelectStyles}
//                           isDisabled={true}
//                           value={[{ label: ReportUID, value: ReportUID }]}
//                         />
//                         <div
//                           style={{
//                             borderStyle: "solid",
//                             borderRadius: "5px",
//                             borderWidth: "0.05mm",
//                             borderColor: "lightgray",
//                           }}
//                         >
//                           <input
//                             type="datetime-local"
//                             id="datetime-local"
//                             value={selectedDateTime}
//                             onChange={(e) => handleDateTime(e)}
//                             style={{
//                               width: "96%",
//                               border: "none",
//                               fontSize: "15px",
//                               padding: "8px",
//                               fontFamily: "inherit",
//                             }}
//                           />
//                         </div>
//                         <CreatableSelect
//                           placeholder="Organization"
//                           options={organizationOptions}
//                           onChange={handleOrganizationSelection}
//                           value={selectedOrganization}
//                           isSearchable
//                           isClearable
//                         />
//                         <CreatableSelect
//                           placeholder="Site"
//                           options={siteOptions}
//                           onChange={handleSiteSelection}
//                           value={selectedSite}
//                           isSearchable
//                           isClearable
//                           isDisabled={!selectedOrganization}
//                         />
//                       </div>
//                       <div className="flex-container-start-end-date">
//                         <div className="to-date-from-date">
//                           <DatePicker
//                             selected={startDate}
//                             onChange={(e) =>
//                               handleStartEndDate(e, "start-date")
//                             }
//                             placeholderText="Audit Start Date"
//                             dateFormat="dd-MM-yyyy"
//                             showTimeSelect={false}
//                             utcOffset={0}
//                             className="class-for-date-pickers"
//                             maxDate={endDate}
//                             todayButton={"Today"}
//                           />
//                         </div>
//                         <div className="to-date-from-date">
//                           <DatePicker
//                             selected={endDate}
//                             onChange={(e) => handleStartEndDate(e, "end-date")}
//                             className="class-for-date-pickers"
//                             placeholderText="Audit End Date"
//                             dateFormat="dd-MM-yyyy"
//                             utcOffset={0}
//                             minDate={startDate}
//                             todayButton={"Today"}
//                           />
//                         </div>
//                       </div>
//                       <div className="flex-container">
//                         <Select
//                           className="select"
//                           placeholder="Area"
//                           options={areaOptions()}
//                           onChange={(e) => handleChangeArea(e)}
//                           isMulti={true}
//                           value={areasToDisplay}
//                         />
//                         <Select
//                           className="select"
//                           placeholder="Category"
//                           options={categoryOptions()}
//                           onChange={(e) => handleChangeCategory(e)}
//                           isMulti={true}
//                           value={categoriesToDisplay}
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   {/* <div className="flex-container-start-end-date">
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(e) => handleStartEndDate(e, "start-date")}
//                       className="to-date-from-date"
//                       placeholderText="Enter Start Date"
//                       dateFormat="dd-MM-yyyy"
//                       showTimeSelect={false}
//                       utcOffset={0}
//                     />
//                     <DatePicker
//                       selected={endDate}
//                       onChange={(e) => handleStartEndDate(e, "end-date")}
//                       className="to-date-from-date"
//                       placeholderText="Enter End Date"
//                       dateFormat="dd-MM-yyyy"
//                       utcOffset={0}
//                     />
//                   </div> */}
//                 </Typography>
//                 <div style={{height:"100vh"}} className="observations-container">
//                   {observations.length > 0 && selectedCategory.length > 0 ? (
//                     <div className="observations-list">
//                       {observations.map((observation, index) => (
//                         <div key={index} className="observation-item-checkbox">
//                           <input
//                             type="checkbox"
//                             checked={observation.is_selected === 1}
//                             onChange={() =>
//                               handleObservationSelection(observation, index)
//                             }
//                           />
//                           {/* <span>
//                             {observation.observation} (
//                             <span style={{ fontWeight: "bold" }}>
//                               {observation.area}, {observation.category}
//                             </span>
//                             )
//                           </span> */}
//                           <span>
//                             {observation.observation} (
//                             <span style={{ fontWeight: "bold" }}>
//                               {observation.area}, {observation.category}
//                             </span>
//                             ){" "}
//                             {/* {observation.edited_fields && (
//                               <sup>
//                                 {JSON.parse(observation.edited_fields).map(
//                                   (field, index) => (
//                                     <span key={index}>
//                                       {index > 0 && ", "}{" "}
//                                       {field}
//                                     </span>
//                                   )
//                                 )}
//                               </sup>
//                             )} */}
//                           </span>

//                           {observation.isNote && (
//                             <span className="note-label">(Note)</span>
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   ) : (
//                     <div className="no-observations">
//                       No observations available.
//                     </div>
//                   )}
//                 </div>
//                 {/* <div className="observations-container">
//                   {Object.entries(observationsByArea).length > 0 ? (
//                     Object.entries(observationsByArea).map(
//                       ([area, areaObservations]) => (
//                         <div key={area}>
//                           <h3><u><i>{area}</i></u></h3>
//                           <div className="observations-list">
//                             {areaObservations.map((observation, index) => (
//                               <div
//                                 key={index}
//                                 className="observation-item-checkbox"
//                               >
//                                 <input
//                                   type="checkbox"
//                                   checked={observation.is_selected === 1}
//                                   onChange={() =>
//                                     handleObservationSelection(
//                                       observation,
//                                       index
//                                     )
//                                   }
//                                 />
//                                 <span>
//                                   {observation.observation}
//                                   (
//                                   <span style={{ fontWeight: "bold" }}>
//                                     {area}
//                                   </span>
//                                   )
//                                 </span>
//                                 {observation.isNote && (
//                                   <span className="note-label">(Note)</span>
//                                 )}
//                               </div>
//                             ))}
//                           </div>
//                         </div>
//                       )
//                     )
//                   ) : (
//                     <div className="no-observations">
//                       No observations available.
//                     </div>
//                   )}
//                 </div> */}
//                 <div className="get-recommendations-container">
//                   {/* <p
//                     className="legend"
//                     style={{ fontSize: "small", fontStyle: "italic" }}
//                   >
//                     Legend: A &#8594; Area Edited, CA &#8594; Category Edited, CH &#8594; Check Points Edited, O &#8594;
//                     Observation Edited, CR &#8594; Criticality Edited, R &#8594; Recommendations Edited, I &#8594;
//                     IS Reference Edited.
//                   </p> */}
//                   <button
//                     // variant="contained"
//                     onClick={handleProceed}
//                     disabled={
//                       selectedObservations.length === 0 ||
//                       !selectedOrganization ||
//                       !selectedSite ||
//                       !startDate ||
//                       !endDate
//                     }
//                     style={{
//                       background:
//                         selectedObservations.length === 0 ||
//                         !selectedOrganization ||
//                         !selectedSite ||
//                         !startDate ||
//                         !endDate
//                           ? "lightgrey"
//                           : "#efc71d",
//                     }}
//                     className="button-styles"
//                   >
//                     Proceed
//                   </button>
//                 </div>
//               </div>
//               {/* <div className="modal-footer">
//                 <hr />
//                 <div style={{ textAlign: "center" }}>
//                   <Button variant="contained" onClick={handleClose}>
//                     Close
//                   </Button>
//                 </div>
//               </div> */}
//             </div>
//           </div>
//         </Modal>
//         {showConfirmationModal && (
//           <ConfirmationModal
//             setShowConfirmationModal={setShowConfirmationModal}
//             handleCloseWithoutSaving={handleCloseWithoutSaving}
//           />
//         )}
//       </div>
//     );
//   } else if (openReviewModal) {
//     return (
//       <PeviewReportModal
//         open={openReviewModal}
//         setOpenModal={setOpenReviewModal}
//         setOpenCreateReportModal={setOpenModal}
//         reportHeader={reportHeader}
//         selectedObservations={selectedObservations}
//         recommendations={recommendations}
//         selectedArea={selectedArea}
//         selectedCategory={selectedCategory}
//         setSelectedObservations={setSelectedObservations}
//         setRecommendations={setRecommendations}
//         setSelectedArea={setSelectedArea}
//         setSelectedCategory={setSelectedCategory}
//         setSelectedOrganization={setSelectedOrganization}
//         setSelectedSite={setSelectedSite}
//         selectedOrganization={selectedOrganization}
//         selectedSite={selectedSite}
//         ReportUID={ReportUID}
//         selectedDateTime={selectedDateTime}
//         observations={observations}
//         setAreasToDisplay={setAreasToDisplay}
//         setCategoriesToDisplay={setCategoriesToDisplay}
//         setObservations={setObservations}
//         getAllData={getAllData}
//         startDate={startDate}
//         endDate={endDate}
//         setStartDate={setStartDate}
//         setEndDate={setEndDate}
//         generateUniqueId={generateUniqueId}
//         getCurrentDateTime={getCurrentDateTime}
//         // getCategoriesByAreas={getCategoriesByAreas}
//         setLoading={setLoading}
//         areaOptions={areaOptions}
//         allData={allData}
//         getAllReports={getAllReports}
//       />
//     );
//   }
// };

// export default ReportModal;

import React, { useState, useEffect } from "react";
import { Modal, TextField, Typography } from "@mui/material";
import "./ReportModal.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import axios from "../../APIs/axios";
import PeviewReportModal from "../PreviewReportModal/PreviewReportModal";
import { config } from "../../config";
import { getAccountDetails } from "../Services/localStorage";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ObservationsDrawer from "../ObservationsDrawer/ObservationsDrawer";

const ReportModal = ({
  open,
  setOpenModal,
  areaList,
  allData,
  getAllData,
  setLoading,
  getAllReports,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [observations, setObservations] = useState([]);
  const [selectedObservations, setSelectedObservations] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [ReportUID, setReportUID] = useState("");
  const [newReportUID, setNewReportUID] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orgList, setOrgList] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [reportHeader, setReportHeaders] = useState();
  const [criticalObservations, setCriticalObservations] = useState([]);
  const { userId } = getAccountDetails();
  const [areasToDisplay, setAreasToDisplay] = useState([]);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [scores, setScores] = useState([
    {
      "Electrical Safety": "Design & Installation",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Preventive maintenance",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Competency/Training",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Lock out-Tag out",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Drawings (As built) / Documents",
      "Max Score": 2,
      "Score Obtained": 0,
    },
  ]);
  const cumulativeScore = scores.reduce(
    (acc, row) => acc + parseFloat(row["Score Obtained"] || 0),
    0
  );

  useEffect(() => {
    updateReportUID();
  }, [selectedOrganization, selectedSite, selectedDateTime]);

  useEffect(() => {
    getOrgList();
    getReportHeaders();
  }, [open, selectedOrganization]);

  useEffect(() => {
    generateUniqueId();
    getCurrentDateTime();
    getAllNotes();
  }, []);

  useEffect(() => {
    const getSitesByOrganization = async (orgId) => {
      try {
        const response = await axios.get(
          `${config.PATH}/api/organizations/${orgId}/sites`
        );
        const siteOptions = response.data.map((site) => ({
          label: site.site_name,
          value: site.site_name,
        }));
        setSiteOptions(siteOptions);
      } catch (error) {
        console.log("Error:", error.response?.data?.error || error.message);
      }
    };
    if (selectedOrganization) {
      getSitesByOrganization(selectedOrganization.value);
    }
  }, [selectedOrganization, selectedSite]);

  const getAllNotes = async () => {
    try {
      const response = await axios.get(
        `${config.PATH}/api/get-all-notes/${userId}`
      );
      setNotes(response.data.sort((a, b) => a.id - b.id));
    } catch (err) {
      console.log(err);
    }
  };

  const getReportHeaders = async () => {
    try {
      const response = await axios.get(`${config.PATH}/api/get-headers`);
      console.log("response", response.data);
      setReportHeaders(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getOrgList = async () => {
    try {
      const response = await axios.get(`${config.PATH}/api/organizations`);
      setOrgList(response.data);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleCloseWithoutSaving = () => {
    setShowConfirmationModal(false);
    setOpenModal(false);
    setSelectedObservations([]);
    setRecommendations([]);
    setSelectedArea([]);
    setSelectedCategory([]);
    setSelectedOrganization(null);
    setSelectedSite(null);
    setAreasToDisplay([]);
    setCategoriesToDisplay([]);
    generateUniqueId();
    getCurrentDateTime();
    setStartDate(null);
    setEndDate(null);
  };

  const handleClose = () => {
    if (selectedObservations.length > 0) {
      setShowConfirmationModal(true);
    } else {
      handleCloseWithoutSaving();
    }
  };

  const areaOptions = () => {
    try {
      const options = [];
      areaList.map((area) => options.push({ label: area, value: area }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const categoryOptions = () => {
    try {
      const options = [];
      let uniqueCategories = [...new Set(categoryList)];
      uniqueCategories.map((cat) => options.push({ label: cat, value: cat }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const organizationOptions = orgList.map((e) => ({
    label: e.org_name,
    value: e.id,
  }));

  // const handleOrganizationSelection = async (selectedOption) => {
  //   if (selectedOption && selectedOption.__isNew__ === true) {
  //     // Validate the organization name to allow only '-' and '_'
  //     const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
  //       selectedOption.label
  //     );

  //     if (!isValidOrganizationName) {
  //       toast.error(
  //         "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed."
  //       );
  //       return;
  //     }

  //     const payload = {
  //       org_name: selectedOption.label,
  //     };

  //     try {
  //       const response = await axios.post(
  //         `${config.PATH}/api/create-organization`,
  //         payload
  //       );

  //       const newOrganization = response.data;
  //       setSelectedOrganization({
  //         label: newOrganization.org_name,
  //         value: newOrganization.id,
  //       });
  //       setSelectedSite(null);
  //     } catch (error) {
  //       console.log("Failed to create organization:", error);
  //       return;
  //     }
  //   } else {
  //     setSelectedOrganization(selectedOption);
  //     setSelectedSite(null);
  //   }
  // };

  const handleOrganizationSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      // Validate the organization name to allow only '-' and '_'
      const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
        selectedOption.label
      );

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidOrganizationName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidOrganizationName) {
          errorMessage =
            "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Organization name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        org_name: selectedOption.label,
      };

      try {
        const response = await axios.post(
          `${config.PATH}/api/create-organization`,
          payload
        );

        const newOrganization = response.data;
        setSelectedOrganization({
          label: newOrganization.org_name,
          value: newOrganization.id,
        });
        setSelectedSite(null);
      } catch (error) {
        console.log("Failed to create organization:", error);
        return;
      }
    } else {
      setSelectedOrganization(selectedOption);
      setSelectedSite(null);
    }
  };

  const handleSiteSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidSiteName = /^[a-zA-Z0-9_-]+$/.test(selectedOption.label);
      if (!isValidSiteName) {
        toast.error(
          "Invalid site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed."
        );
        return;
      }

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidSiteName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidSiteName) {
          errorMessage =
            "Invalid Site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Site name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        site_name: selectedOption.label,
        org_id: selectedOrganization.value,
      };
      try {
        await axios.post(`${config.PATH}/api/add-site`, payload);
        updateReportUID();
      } catch (error) {
        console.log("Failed to create site:", error);
        return;
      }
    }
    setSelectedSite(selectedOption);
    updateReportUID();
  };

  const handleChangeArea = (areas) => {
    try {
      setAreasToDisplay(areas);
      const selectedArea = areas.map((e) => e.value);
      setSelectedArea(selectedArea);

      function getCategoriesByAreas(areas) {
        const categories = [];
        for (const item of allData.data) {
          if (selectedArea.includes(item.area)) {
            categories.push(item.category);
          }
        }
        setCategoryList(categories);
      }

      if (areas.length === 0) {
        // If the 'areas' array is empty, set 'categories' and 'categoriesToDisplay' to empty arrays.
        setSelectedCategory([]);
        setCategoriesToDisplay([]);
        setSelectedObservations([]);
        // Update the observations based on the empty category list
        handleChangeCategory([]);
        setCategoryList([]);
      } else {
        // Remove categories that are not associated with the selected areas.
        const updatedSelectedCategory = selectedCategory.filter((category) => {
          const categoryAreas = allData.data
            .filter((item) => item.category === category)
            .map((item) => item.area);

          const includesArea = categoryAreas.some((area) =>
            selectedArea.includes(area)
          );

          return includesArea;
        });
        setSelectedCategory(updatedSelectedCategory);
        getCategoriesByAreas(areas);

        // Set 'categoriesToDisplay' to the categories associated with the selected areas.
        const updatedCategoriesToDisplay = categoryList
          .filter((category) => updatedSelectedCategory.includes(category))
          .map((category) => ({ label: category, value: category }));

        const uniqueCategories = [
          ...new Set(updatedCategoriesToDisplay.map(JSON.stringify)),
        ].map(JSON.parse);
        setCategoriesToDisplay(uniqueCategories);
        // Update the observations based on the updated selected categories
        handleChangeCategory(uniqueCategories);
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const handleChangeCategory = async (cat) => {
    setCategoriesToDisplay(cat);
    const selectedCat = cat.map((e) => e.value);
    setSelectedCategory(selectedCat);

    const combinedData = allData.data
      .filter((e) => e.edited_fields === null || e.edited_fields.includes("O"))
      .concat(
        notes
          .filter((note) => note.note_type === "electrical")
          .map((note) => ({ ...note, isNote: true }))
      );

    // Filter based on selected category and area
    const filteredObservations = combinedData.filter(
      (item) =>
        selectedCat.includes(item.category) && selectedArea.includes(item.area)
    );

    // Preserve the selection status (is_selected) from the old observations
    const updatedObservations = filteredObservations.map((newObs) => {
      const existingObs = observations.find(
        (obs) => obs.observation === newObs.observation
      );
      return existingObs
        ? {
            ...newObs,
            is_selected: existingObs.is_selected,
            imageUrls: existingObs.imageUrls,
          }
        : newObs;
    });

    // Update the observations state with the filtered observations.
    setObservations(updatedObservations);
    setSelectedObservations(
      selectedObservations.filter((selectedObs) =>
        updatedObservations.some(
          (e) => e.sr_no === selectedObs.sr_no || e.id === selectedObs.id
        )
      )
    );
    // Check if any observations in selectedObservations are related to removed categories
    const removedCategories = selectedObservations.filter(
      (selectedObs) => !selectedCat.includes(selectedObs.category)
    );
    if (removedCategories.length > 0) {
      // Remove observations corresponding to removed categories
      const updatedSelectedObservations = selectedObservations.filter(
        (selectedObs) =>
          !removedCategories.some(
            (removedCat) =>
              removedCat.category === selectedObs.category ||
              removedCat.category === selectedObs.category
          )
      );
      setSelectedObservations(updatedSelectedObservations);
    }
  };

  const handleObservationSelection = (observation, index) => {
    const tempObs = [...observations];
    let isAlreadySelected = observation.is_selected;
    if (isAlreadySelected) {
      delete tempObs[index].is_selected;
      setSelectedObservations(
        [...selectedObservations].filter((e) => e.refIndex !== index)
      );
      setCriticalObservations(
        [...selectedObservations].filter(
          (e) => e.criticality === "High" && e.refIndex !== index
        )
      );
    } else {
      tempObs[index] = { ...observation, is_selected: 1 };
      setSelectedObservations([
        ...selectedObservations,
        { ...observation, refIndex: index },
      ]);
      setCriticalObservations(
        [...selectedObservations, observation].filter(
          (e) => e.criticality === "High"
        )
      );
    }
    setObservations(tempObs);
  };

  // const handleObservationSelection = (observation, index) => {
  //   const tempObs = [...observations];
  //   let isAlreadySelected = observation.is_selected;

  //   // Find the index of the observation within the entire observations array
  //   const observationIndex = observations.findIndex(
  //     (obs) => obs.observation === observation.observation
  //   );

  //   if (isAlreadySelected) {
  //     delete tempObs[observationIndex].is_selected;
  //     setSelectedObservations(
  //       selectedObservations.filter((e) => e.refIndex !== observationIndex)
  //     );
  //     setCriticalObservations(
  //       selectedObservations.filter(
  //         (e) => e.criticality === "High" && e.refIndex !== observationIndex
  //       )
  //     );
  //   } else {
  //     tempObs[observationIndex] = { ...observation, is_selected: 1 };
  //     setSelectedObservations([
  //       ...selectedObservations,
  //       { ...observation, refIndex: observationIndex },
  //     ]);
  //     setCriticalObservations(
  //       [...selectedObservations, observation].filter(
  //         (e) => e.criticality === "High"
  //       )
  //     );
  //   }
  //   setObservations(tempObs);
  // };

  const handleProceed = async () => {
    try {
      setLoading(true);
      // Make API call with selectedObservations as input
      // const payload = {
      //   observations: selectedObservations.map((e) => e.observation),
      //   // observations: selectedObservations.map((e) => e.id),
      // };

      // let res = await axios.post(`${config.PATH}/api/recommendations`, payload);
      // const receivedRecommendations = res.data.data;
      // setRecommendations(res.data.data);
      setOpenReviewModal(true);
      setOpenModal(false);
      const reportData = {
        report_id: ReportUID,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        area: selectedArea,
        category: selectedCategory,
        scores: scores,
        cumulative: cumulativeScore,
        start_date: startDate,
        end_date: endDate,
        type: "primary",
      };
      await axios.post(`${config.PATH}/api/save-update-report`, reportData);

      // const updatedObservations = [
      //   ...observations.filter((obs) => obs.is_selected === 1),
      //   ...observations.filter((obs) => obs.is_selected !== 1),
      // ];

      // setObservations(updatedObservations);

      const observationsData = {
        report_id: ReportUID,
        // observation: selectedObservations,
        // critical_observations: criticalObservations,
        all_observations: observations,
      };
      await axios.post(
        `${config.PATH}/api/save-update-observations`,
        observationsData
      );
      setLoading(false);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const updateReportUID = () => {
    if (
      selectedOrganization &&
      selectedOrganization.label &&
      selectedSite &&
      selectedSite.label &&
      selectedDateTime
    ) {
      const formattedDateTime = selectedDateTime.split("T")[0];
      setReportUID(
        `${newReportUID}-${selectedOrganization.label}_${selectedSite.label}_${formattedDateTime}`
      );
    } else if (!selectedSite) {
      // If selectedSite is null, revert back to the initial ReportUID
      setReportUID(newReportUID);
    }
  };

  const generateUniqueId = () => {
    let id = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }

    setReportUID(id);
    setNewReportUID(id); // Set newReportUID initially to the generated ID
  };

  const handleDateTime = (e) => {
    setSelectedDateTime(e.target.value);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    setSelectedDateTime(`${year}-${month}-${day}T${hours}:${minutes}`);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderBottom: "none", // Hide the separator
      boxShadow: "none", // Hide the box shadow
      cursor: "pointer", // Show the pointer cursor
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none", // Hide the down arrow
    }),
  };

  const handleStartEndDate = (date, name) => {
    if (!date) {
      // Handle case when date is null or undefined
      if (name === "start-date") {
        setStartDate(null);
      } else if (name === "end-date") {
        setEndDate(null);
      } else {
        if (startDate) setEndDate(null);
      }
      return;
    }

    // Ensure that the date part is set to midnight (00:00:00)
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);

    if (name === "start-date") {
      if (endDate && adjustedDate > endDate) {
        toast.warning("Start date cannot be after the end date.");
        setStartDate(null);
        return;
      }
      setStartDate(adjustedDate);
    } else {
      if (startDate && adjustedDate < startDate) {
        toast.warning("End date cannot be before the start date.");
        setEndDate(null);
        return;
      }
      setEndDate(adjustedDate);
    }
  };

  // Group observations by area
  // const observationsByArea = observations.reduce((acc, observation) => {
  //   const area = observation.area;
  //   if (!acc[area]) {
  //     acc[area] = [];
  //   }
  //   acc[area].push(observation);
  //   return acc;
  // }, {});

  // const groupedObservations = observations.reduce((groups, observation) => {
  //   const { area } = observation;
  //   if (!groups[area]) {
  //     groups[area] = [];
  //   }
  //   groups[area].push(observation);
  //   return groups;
  // }, {});

  // Filter observations based on search term
  const filteredObservations = observations.filter(
    (observation) =>
      observation.observation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      observation.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
      observation.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Group filtered observations by area
  const groupedObservations = filteredObservations.reduce(
    (groups, observation) => {
      const { area } = observation;
      if (!groups[area]) {
        groups[area] = [];
      }
      groups[area].push(observation);
      return groups;
    },
    {}
  );

  const allDataForGlobalSearch = allData.data;
  const filteredAllData = allDataForGlobalSearch.filter(
    (observation) =>
      observation.observation
        .toLowerCase()
        .includes(globalSearchTerm.toLowerCase()) ||
      observation.area.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
      observation.category
        .toLowerCase()
        .includes(globalSearchTerm.toLowerCase())
  );
  // Group filtered observations by area
  const groupedData = filteredAllData.reduce((groups, observation) => {
    const { area } = observation;
    if (!groups[area]) {
      groups[area] = [];
    }
    groups[area].push(observation);
    return groups;
  }, {});

  if (open) {
    return (
      <div>
        <Modal open={open} onClose={handleClose}>
          <div className="modal-container">
            <div className="modal-header">
              <Typography variant="h5">
                Create Electrical Safety Report
              </Typography>
              {/* <IconButton
                size="small"
                onClick={handleClose}
                className="close-icon"
                style={{backgroundColor:"#efc71d"}}
                autoFocus={false}
              >
                <CloseIcon style={{color:"#307248"}} />
              </IconButton> */}
              <button className="custom-close-button" onClick={handleClose}>
                &#10005; {/* Unicode for 'X' */}
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-body">
                Report ID: {`${ReportUID}`}
                <Typography variant="body1">
                  <div className="select-container">
                    {/* <Select
                      styles={customSelectStyles}
                      isDisabled={true}
                      value={[{ label: ReportUID, value: ReportUID }]}
                    /> */}
                    {/* <div
                      style={{
                        borderStyle: "solid",
                        borderRadius: "5px",
                        borderWidth: "0.05mm",
                        borderColor: "lightgray",
                      }}
                    >
                      <input
                        type="datetime-local"
                        id="datetime-local"
                        value={selectedDateTime}
                        onChange={(e) => handleDateTime(e)}
                        style={{
                          width: "96%",
                          border: "none",
                          fontSize: "15px",
                          padding: "8px",
                          fontFamily: "inherit",
                        }}
                      />
                    </div> */}
                    <CreatableSelect
                      placeholder="Organization"
                      options={organizationOptions}
                      onChange={handleOrganizationSelection}
                      value={selectedOrganization}
                      isSearchable
                      isClearable
                    />
                    <CreatableSelect
                      placeholder="Site"
                      options={siteOptions}
                      onChange={handleSiteSelection}
                      value={selectedSite}
                      isSearchable
                      isClearable
                      isDisabled={!selectedOrganization}
                    />
                  </div>
                  {/* <div className="flex-container-start-end-date">
                    <DatePicker
                      selected={startDate}
                      onChange={(e) => handleStartEndDate(e, "start-date")}
                      className="to-date-from-date"
                      placeholderText="Enter Start Date"
                      dateFormat="dd-MM-yyyy"
                      showTimeSelect={false}
                      utcOffset={0}
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(e) => handleStartEndDate(e, "end-date")}
                      className="to-date-from-date"
                      placeholderText="Enter End Date"
                      dateFormat="dd-MM-yyyy"
                      utcOffset={0}
                    />
                  </div> */}
                  <div className="flex-container-start-end-date">
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={startDate}
                        onChange={(e) => handleStartEndDate(e, "start-date")}
                        placeholderText="Audit Start Date"
                        dateFormat="dd-MM-yyyy"
                        showTimeSelect={false}
                        utcOffset={0}
                        className="class-for-date-pickers"
                        maxDate={endDate}
                        todayButton={"Today"}
                      />
                    </div>
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={endDate}
                        onChange={(e) => handleStartEndDate(e, "end-date")}
                        className="class-for-date-pickers"
                        placeholderText="Audit End Date"
                        dateFormat="dd-MM-yyyy"
                        utcOffset={0}
                        minDate={startDate}
                        todayButton={"Today"}
                      />
                    </div>
                  </div>
                  <div className="flex-container">
                    <Select
                      className="select"
                      placeholder="Area"
                      options={areaOptions()}
                      onChange={(e) => handleChangeArea(e)}
                      isMulti={true}
                      value={areasToDisplay}
                    />
                    <Select
                      className="select"
                      placeholder="Category"
                      options={categoryOptions()}
                      onChange={(e) => handleChangeCategory(e)}
                      isMulti={true}
                      value={categoriesToDisplay}
                    />
                  </div>
                </Typography>
                <div className="observation-and-global-search">
                  <div className="observations-container">
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                      <input
                        type="text"
                        placeholder="Search observations"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                      />
                      <div>
                      <button onClick={handleOpenDrawer} className="search-bar-button">
                      <span className="search-icon">&#128269;</span> {/* Unicode for search icon */}
                      <span className="search-text">All Observations</span>
                      </button>

                        <ObservationsDrawer
                          isOpen={isDrawerOpen}
                          onClose={handleCloseDrawer}
                          groupedData={groupedData}
                          globalSearchTerm={globalSearchTerm}
                          setGlobalSearchTerm={setGlobalSearchTerm}
                        />
                        {/* Other components and content here */}
                      </div>
                    </div>
                    {Object.keys(groupedObservations).length > 0 &&
                    selectedCategory.length > 0 ? (
                      <div className="observations-list">
                        {Object.entries(groupedObservations).map(
                          ([area, observationsInArea]) => (
                            <div key={area} className="area-group">
                              <h4>
                                <u>
                                  <em>{area}</em>
                                </u>
                              </h4>
                              {observationsInArea.map((observation, index) => (
                                <div
                                  key={index}
                                  className="observation-item-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    checked={observation.is_selected === 1}
                                    onChange={() =>
                                      handleObservationSelection(
                                        observation,
                                        observations.indexOf(observation)
                                      )
                                    }
                                  />
                                  <span>
                                    {observation.observation} (
                                    <span style={{ fontWeight: "bold" }}>
                                      {/* {observation.area}, {observation.category} */}
                                      {observation.category}
                                    </span>
                                    ){" "}
                                    {/* {observation.edited_fields && (
                      <sup>
                        {JSON.parse(observation.edited_fields).map((field, index) => (
                          <span key={index}>
                            {index > 0 && ", "} {field}
                          </span>
                        ))}
                      </sup>
                    )} */}
                                  </span>
                                  {observation.isNote && (
                                    <span className="note-label">(Note)</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="no-observations">
                        No observations available.
                      </div>
                    )}
                  </div>
                  {/* <div style={{maxHeight:"45vh"}} className="observations-container">
                  <input
                    type="text"
                    placeholder="Search all observations"
                    value={globalSearchTerm}
                    onChange={(e) => setGlobalSearchTerm(e.target.value)}
                    className="search-input"
                  />
                  {Object.keys(groupedData).length > 0 ? (
                    <div className="observations-list">
                      {Object.entries(groupedData).map(
                        ([area, observationsInArea]) => (
                          <div key={area} className="area-group">
                            <h4>
                              <u>
                                <em>{area}</em>
                              </u>
                            </h4>
                            {observationsInArea.map((observation, index) => (
                              <div
                                key={index}
                                className="observation-item-checkbox"
                              >
                               {`${index+1}.`}&nbsp;
                                <span>
                                  {observation.observation} (
                                  <span style={{ fontWeight: "bold" }}>
                                    {observation.category}
                                  </span>
                                  )
                                </span>
                                {observation.isNote && (
                                  <span className="note-label">(Note)</span>
                                )}
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="no-observations">
                      No observations available.
                    </div>
                  )}
                </div> */}
                </div>
                {/* <div className="observations-container">
                  {Object.entries(observationsByArea).length > 0 ? (
                    Object.entries(observationsByArea).map(
                      ([area, areaObservations]) => (
                        <div key={area}>
                          <h3><u><i>{area}</i></u></h3>
                          <div className="observations-list">
                            {areaObservations.map((observation, index) => (
                              <div
                                key={index}
                                className="observation-item-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  checked={observation.is_selected === 1}
                                  onChange={() =>
                                    handleObservationSelection(
                                      observation,
                                      index
                                    )
                                  }
                                />
                                <span>
                                  {observation.observation} 
                                  (
                                  <span style={{ fontWeight: "bold" }}>
                                    {area}
                                  </span>
                                  )
                                </span>
                                {observation.isNote && (
                                  <span className="note-label">(Note)</span>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className="no-observations">
                      No observations available.
                    </div>
                  )}
                </div> */}
                <div className="get-recommendations-container">
                  {/* <p
                    className="legend"
                    style={{ fontSize: "small", fontStyle: "italic" }}
                  >
                    Legend: A &#8594; Area Edited, CA &#8594; Category Edited, CH &#8594; Check Points Edited, O &#8594;
                    Observation Edited, CR &#8594; Criticality Edited, R &#8594; Recommendations Edited, I &#8594;
                    IS Reference Edited.
                  </p> */}
                  <button
                    // variant="contained"
                    onClick={handleProceed}
                    disabled={
                      selectedObservations.length === 0 ||
                      !selectedOrganization ||
                      !selectedSite ||
                      !startDate ||
                      !endDate
                    }
                    style={{
                      background:
                        selectedObservations.length === 0 ||
                        !selectedOrganization ||
                        !selectedSite ||
                        !startDate ||
                        !endDate
                          ? "lightgrey"
                          : "#efc71d",
                    }}
                    className="button-styles"
                  >
                    Proceed
                  </button>
                </div>
              </div>
              {/* <div className="modal-footer">
                <hr />
                <div style={{ textAlign: "center" }}>
                  <Button variant="contained" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </Modal>
        {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )}
      </div>
    );
  } else if (openReviewModal) {
    return (
      <PeviewReportModal
        open={openReviewModal}
        setOpenModal={setOpenReviewModal}
        setOpenCreateReportModal={setOpenModal}
        reportHeader={reportHeader}
        selectedObservations={selectedObservations}
        recommendations={recommendations}
        selectedArea={selectedArea}
        selectedCategory={selectedCategory}
        setSelectedObservations={setSelectedObservations}
        setRecommendations={setRecommendations}
        setSelectedArea={setSelectedArea}
        setSelectedCategory={setSelectedCategory}
        setSelectedOrganization={setSelectedOrganization}
        setSelectedSite={setSelectedSite}
        selectedOrganization={selectedOrganization}
        selectedSite={selectedSite}
        ReportUID={ReportUID}
        selectedDateTime={selectedDateTime}
        observations={observations}
        setAreasToDisplay={setAreasToDisplay}
        setCategoriesToDisplay={setCategoriesToDisplay}
        setObservations={setObservations}
        getAllData={getAllData}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        generateUniqueId={generateUniqueId}
        getCurrentDateTime={getCurrentDateTime}
        // getCategoriesByAreas={getCategoriesByAreas}
        setLoading={setLoading}
        areaOptions={areaOptions}
        allData={allData}
        getAllReports={getAllReports}
      />
    );
  }
};

export default ReportModal;
