import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  TextField,
  Typography,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./SavedReportModal.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { config } from "../../config";
import axios from "../../APIs/axios";
import { toast, ToastContainer } from "react-toastify";
import ExportSavedReportPDF from "../ExportSavedReportPDF/ExportSavedReportPDF";
import { getAccountDetails } from "../Services/localStorage";
import "./SavedReportModal.css";
import ExportExcel from "../ExportExcel/ExportExcel";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import ExportWordDoc from "../ExportWordDoc/ExportWordDoc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageViewerModal from "../ImageViewerModal/ImageViewerModal";
import Loader from "../Loader/Loader";
import VariantsModal from "../VariantsModal/VariantsModal";
import CheckIcon from "@mui/icons-material/Check";
import ObservationsDrawer from "../ObservationsDrawer/ObservationsDrawer";
import DeleteIcon from "@mui/icons-material/Delete";

const removeDuplicates = (observations) => {
  const uniqueObservations = Array.from(
    new Map(observations.map((item) => [item.observation, item])).values()
  );
  return uniqueObservations;
};

const SavedReportModal = ({
  selectedReportData,
  setOpenSavedReport,
  allData,
  reportHeader,
  getAllData,
  module,
  setOpenReportList,
  getAllReports,
  exp,
}) => {
  const [screenNumber, setScreenNumber] = useState(exp === true ? 10 : 1);
  const [selectedDateTime, setSelectedDateTime] = useState(
    selectedReportData.date_time
  );
  const [orgList, setOrgList] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({
    label: selectedReportData.organization,
    value: selectedReportData.org_id,
  });
  const [selectedSite, setSelectedSite] = useState({
    label: selectedReportData.site,
    value: selectedReportData.site,
  });
  const [startDate, setStartDate] = useState(selectedReportData.start_date);
  const [endDate, setEndDate] = useState(selectedReportData.end_date);
  const [siteOptions, setSiteOptions] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedArea, setSelectedArea] = useState(
    selectedReportData.Areas.map((e) => e.area)
  );
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    selectedReportData.Categories.map((e) => e.category)
  );
  const [AllObservations, setAllObservations] = useState(
    removeDuplicates(selectedReportData.AllObservations)
  );
  const [selectedObservations, setSelectedObservations] = useState(
    selectedReportData.AllObservations.filter((e) => e.is_selected === 1)
  );

  const [recommendations, setRecommendations] = useState(
    selectedReportData.Recommendations
  );
  const [criticalObservations, setCriticalObservations] = useState([]);
  const [otherDetails, setOtherDetails] = useState(
    selectedReportData.other_details
  );
  // const [backgroundBrief, setBackgroundBrief] = useState(
  //   selectedReportData.background_brief
  //     ? selectedReportData.background_brief
  //     : reportHeader.background_brief
  // );
  const [backgroundBrief, setBackgroundBrief] = useState("");
  const [contents, setContents] = useState(
    selectedReportData.contents
      ? selectedReportData.contents
      : reportHeader.contents
  );
  const [bestPractice, setbestPractice] = useState(
    selectedReportData.best_practice
      ? selectedReportData.best_practice
      : reportHeader.best_practice
  );
  const [theWayForward, setTheWayForward] = useState(
    selectedReportData.the_way_forward
      ? selectedReportData.the_way_forward
      : reportHeader.the_way_forward
  );
  const [exeSummary, setExeSummary] = useState("");
  const [conclusion, setConclusion] = useState(
    selectedReportData.conclusion
      ? selectedReportData.conclusion
      : reportHeader.conclusion
  );
  // const [imageUrlsByRow, setImageUrlsByRow] = useState(
  //   JSON.parse(selectedReportData.image_urls)
  //     ? JSON.parse(selectedReportData.image_urls)
  //     : {}
  // );
  const [scores, setScores] = useState(JSON.parse(selectedReportData.scores));
  const cumulativeScore =
    scores.reduce(
      (acc, row) => acc + parseFloat(row["Score Obtained"] || 0),
      0
    ) > 0
      ? scores
          .reduce((acc, row) => acc + parseFloat(row["Score Obtained"] || 0), 0)
          .toFixed(2)
      : 0;
  const { userId, name } = getAccountDetails();
  const [editedObservations, setEditedObservations] = useState([]);
  const [isReportEdited, setIsReportEdited] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [currentEditedRow, setCurrentEditedRow] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [disableSaveNext, setDisableSaveNext] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const regexForBackground = /\bon.*?by\b/;
  const regexForExeSummary = /\bon.*?and\b/;
  const regexForAreas = /\bentire[\s\S]*?and\b/;
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openVairantModal, setOpenVariantModal] = useState(false);
  const [observationVariants, setObservationVariants] = useState([]);
  const [editedFields, setEditedFields] = useState([]);
  const [area, setArea] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [facilityInfo, setFacilityInfo] = useState({
    "Name of Facility": "", 
    "Address & Location": "",
    "Geographical Co-ordinates Seismic Zone": "",
    "Brief Property Description:": "",
    "Type of Construction": "",
    "Number of Floors":"",
    "Average Worker Foot Fall:": "",
    "No Objection Certificate": "",
  });
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [newRow, setNewRow] = useState({
    area: "",
    category: "",
    check_points: "",
    observation: "",
    criticality: "",
    recommendations: "",
    is_reference: "",
    imageUrls: [],
  });
  const [isRowSaved, setIsRowSaved] = useState(true);

  useEffect(() => {
    updateBackgroundBrief();
    updateExecSummary();
    getFacilityInfo();
  }, [selectedOrganization, selectedSite, startDate, endDate, selectedArea]);

  const updateBackgroundBrief = () => {
    const updatedData = selectedReportData.background_brief
      .replace(
        selectedReportData.organization,
        `${
          selectedOrganization
            ? selectedOrganization.label
            : selectedReportData.organization
        }`
      )
      .replace(
        selectedReportData.site,
        `${selectedSite ? selectedSite.label : selectedReportData.site}`
      )
      .replace(
        regexForBackground,
        `on ${new Date(startDate).getDate()}-${
          new Date(startDate).getMonth() + 1
        }-${new Date(startDate).getFullYear()} and ${new Date(
          endDate
        ).getDate()}-${new Date(endDate).getMonth() + 1}-${new Date(
          endDate
        ).getFullYear()} by`
      );
    setBackgroundBrief(updatedData);
  };

  const updateExecSummary = () => {
    const updatedData = selectedReportData.exe_summary
      .replace(
        `${selectedReportData.organization}(${selectedReportData.site})`,
        `${
          selectedOrganization
            ? selectedOrganization.label
            : selectedReportData.organization
        }(${selectedSite ? selectedSite.label : selectedReportData.site})`
      )
      .replace(
        regexForExeSummary,
        `on ${new Date(startDate).getDate()}-${
          new Date(startDate).getMonth() + 1
        }-${new Date(startDate).getFullYear()} till ${new Date(
          endDate
        ).getDate()}-${new Date(endDate).getMonth() + 1}-${new Date(
          endDate
        ).getFullYear()} and`
      )
      .replace(
        regexForAreas,
        `entire ${selectedArea.map((e) => e).join(", ")} and`
      );
    setExeSummary(updatedData);
  };

  // const handleCellEdit = (e, index, field, originalContent, observationObj) => {
  //   // Confirmation dialog logic for the observation field
  //       if (field === "observation" && !confirmationShown) {
  //         const confirmEdit = window.confirm(
  //           "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
  //         );
  //         if (!confirmEdit) {
  //           setIsEditing(false);
  //           return; // Exit the function if the user cancels
  //         } else {
  //           setConfirmationShown(true); // Set confirmation shown to true after user confirms
  //         }
  //       }
  //   setIsEditing(true);

  //   // Function to update edited fields array
  //   const updateEditedFields = (newField) => {
  //     if (!editedFields.includes(newField)) {
  //       setEditedFields((prevFields) => [...prevFields, newField]);
  //     }
  //   };

  //   if (field === "area") {
  //     let area = [];
  //     area.push(e.value);
  //     setArea(area);
  //     updateEditedFields("A");
  //   }

  //   let currentContent;
  //   if (field === "area" || field === "category" || field === "criticality") {
  //     currentContent = e.value;
  //   } else {
  //     currentContent = e.target.textContent;
  //   }

  //   // Check if the current content exceeds the character limit
  //   if (
  //     (field === "area" || field === "category") &&
  //     currentContent.length > 50
  //   ) {
  //     toast.warning("Only 50 characters are allowed in this field.");
  //     setIsEditing(false);
  //     return; // Do nothing and exit the function
  //   }

  //   if (currentContent.length > 7000) {
  //     toast.warning("Maximum character limit reached.");
  //     e.target.textContent = currentContent.substring(0, 7000);
  //     const range = document.createRange();
  //     const sel = window.getSelection();
  //     range.setStart(e.target.childNodes[0], 7000);
  //     range.collapse(true);
  //     sel.removeAllRanges();
  //     sel.addRange(range);
  //     e.preventDefault();
  //     return;
  //   }
  //   setIsReportEdited(true);
  //   if (currentEditedRow !== -1 && currentEditedRow !== index) {
  //     toast.warning(
  //       "Please save changes in the currently edited row before editing another row."
  //     );
  //     return;
  //   }

  //   setCurrentEditedRow(index);
  //   setIsEditing(true);

  //   let value;

  //   if (field === "area" || field === "category" || field === "criticality") {
  //     value = e.value;
  //   // } else if (field === "criticality") {
  //   //   value = e.target.value;
  //   } else {
  //     value = e.target.textContent;
  //   }

  //   // const value = field === "criticality" ? e.target.value : e.target.textContent;

  //   const updatedObservations = [...editedObservations];
  //   let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
  //   updatedObservations[i] = {
  //     ...observationObj,
  //     ...updatedObservations[i],
  //     [field]: value,
  //     selectedRefIndex: index,
  //   };

  //   setEditedObservations(updatedObservations);

  //   // Check if the content has been reverted to the original
  //   const isReverted =
  //     field === "observation"
  //       ? e.target.textContent === originalContent
  //       : field === "recommendations"
  //       ? e.target.textContent === originalContent
  //       : field === "area"
  //       ? e.value === originalContent
  //       : field === "criticality"
  //       ? e.value === originalContent
  //       : field === "is_reference"
  //       ? e.target.textContent === originalContent
  //       : field === "category"
  //       ? e.value === originalContent
  //       : false;

  //   // If content is reverted, disable editing and enable other rows
  //   if (isReverted) {
  //     setCurrentEditedRow(-1);
  //     setIsEditing(false);
  //     setEditedObservations([]);
  //     // setEditedRecommendations([]);
  //     // setEditedAreas([]);
  //     // setEditedCriticalities([]);
  //     // setEditedIsReference([]);
  //     // setEditedCategories([]);
  //   }

  //   // Update edited fields array based on the field edited
  //   switch (field) {
  //     case "category":
  //       updateEditedFields("CA");
  //       break;
  //     case "check_points":
  //       updateEditedFields("CH");
  //       break;
  //     case "observation":
  //       updateEditedFields("O");
  //       break;
  //     case "criticality":
  //       updateEditedFields("CR");
  //       break;
  //     case "recommendations":
  //       updateEditedFields("R");
  //       break;
  //     case "is_reference":
  //       updateEditedFields("I");
  //       break;
  //     // Add cases for other fields as needed
  //     default:
  //       break;
  //   }
  // };

  const handleCellEdit = (e, index, field, originalContent, observationObj) => {
    // Function to handle the confirmation dialog
    const handleConfirmation = () => {
      if (!confirmationShown) {
        const confirmEdit = window.confirm(
          "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
        );
        if (!confirmEdit) {
          // Revert the text content if the user cancels
          e.target.textContent = originalContent;
          return false;
        } else {
          setConfirmationShown(true); // User confirmed, set the flag
          return true;
        }
      }
      return true;
    };

    // Show the confirmation dialog if the field is "observation"
    if (field === "observation") {
      if (!handleConfirmation()) {
        setIsEditing(false);
        return; // Exit if the user cancels the confirmation
      }
    }

    setIsEditing(true);

    // Function to update edited fields array
    const updateEditedFields = (newField) => {
      if (!editedFields.includes(newField)) {
        setEditedFields((prevFields) => [...prevFields, newField]);
      }
    };

    if (field === "area") {
      let area = [];
      area.push(e.value);
      setArea(area);
      updateEditedFields("A");
    }

    let currentContent;
    if (field === "area" || field === "category" || field === "criticality") {
      currentContent = e.value;
    } else {
      currentContent = e.target.textContent;
    }

    // Check if the current content exceeds the character limit
    if (
      (field === "area" || field === "category") &&
      currentContent.length > 50
    ) {
      toast.warning("Only 50 characters are allowed in this field.");
      setIsEditing(false);
      return; // Do nothing and exit the function
    }

    if (currentContent.length > 7000) {
      toast.warning("Maximum character limit reached.");
      e.target.textContent = currentContent.substring(0, 7000);
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(e.target.childNodes[0], 7000);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      e.preventDefault();
      return;
    }

    setIsReportEdited(true);
    if (currentEditedRow !== -1 && currentEditedRow !== index) {
      toast.warning(
        "Please save changes in the currently edited row before editing another row."
      );
      return;
    }

    setCurrentEditedRow(index);

    let value;
    if (field === "area" || field === "category" || field === "criticality") {
      value = e.value;
    } else {
      value = e.target.textContent;
    }

    const updatedObservations = [...editedObservations];
    let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
    updatedObservations[i] = {
      ...observationObj,
      ...updatedObservations[i],
      [field]: value,
      selectedRefIndex: index,
    };

    setEditedObservations(updatedObservations);

    // Check if the content has been reverted to the original
    const isReverted =
      field === "observation"
        ? e.target.textContent === originalContent
        : field === "recommendations"
        ? e.target.textContent === originalContent
        : field === "area"
        ? e.value === originalContent
        : field === "criticality"
        ? e.value === originalContent
        : field === "is_reference"
        ? e.target.textContent === originalContent
        : field === "category"
        ? e.value === originalContent
        : false;

    // If content is reverted, disable editing and enable other rows
    if (isReverted) {
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setEditedObservations([]);
      setConfirmationShown(false); // Reset confirmation shown when content is reverted
    }

    // Update edited fields array based on the field edited
    switch (field) {
      case "category":
        updateEditedFields("CA");
        break;
      case "check_points":
        updateEditedFields("CH");
        break;
      case "observation":
        updateEditedFields("O");
        break;
      case "criticality":
        updateEditedFields("CR");
        break;
      case "recommendations":
        updateEditedFields("R");
        break;
      case "is_reference":
        updateEditedFields("I");
        break;
      // Add cases for other fields as needed
      default:
        break;
    }
  };

  const populateCategoryList = async (selectedAreas) => {
    try {
      // Filter the observations based on the selected areas
      const filteredObservations = allData.data.filter((item) =>
        selectedAreas.includes(item.area)
      );

      // Extract unique categories from the filtered observations
      const categories = [
        ...new Set(filteredObservations.map((item) => item.category)),
      ];

      setCategoryList(categories);
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const getCriticalObservations = async () => {
    try {
      const res = await axios.get(
        module === "cmv"
          ? `${config.PATH}/api/get-cmv-critical-observations/${selectedReportData.report_id}`
          : `${config.PATH}/api/get-critical-observations/${selectedReportData.report_id}`
      );
      setCriticalObservations(res.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    populateCategoryList(selectedArea); // Populate the category list based on selected areas
  }, [selectedArea]);

  useEffect(() => {
    getOrgList();
    populateAreaList();
  }, [allData, selectedCategory]);

  useEffect(() => {
    getSitesByOrganization(
      selectedOrganization ? selectedOrganization.value : null
    );
  }, [selectedOrganization, selectedSite]);

  useEffect(() => {
    getCriticalObservations();
    const updatedCriticalObservations = criticalObservations.filter((obs) => {
      return selectedObservations.some(
        (selectedObs) =>
          selectedObs.observation === obs.observation &&
          selectedObs.report_id === obs.report_id
      );
    });
    setCriticalObservations(updatedCriticalObservations);
  }, []);

  const refreshAllObservations = async () => {
    try {
      // Fetch the updated data from the API or wherever
      const report = await axios.get(
        `${config.PATH}/api/report/${selectedReportData.report_id}`
      );
      setAllObservations(report.data.AllObservations);
    } catch (error) {
      console.error("Error refreshing AllObservations:", error);
    }
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderBottom: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const handleDateTime = (e) => {
    setSelectedDateTime(e.target.value);
  };

  const handleOrganizationSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
        selectedOption.label
      );

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidOrganizationName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidOrganizationName) {
          errorMessage =
            "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Organization name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        org_name: selectedOption.label,
      };
      try {
        const response = await axios.post(
          `${config.PATH}/api/create-organization`,
          payload
        );
        const newOrganization = response.data; // Assuming the API returns the newly created organization object
        setSelectedOrganization({
          label: newOrganization.org_name,
          value: newOrganization.id,
        });
        setSelectedSite(null);
      } catch (error) {
        console.log("Failed to create organization:", error);
        return;
      }
    } else {
      setSelectedOrganization(selectedOption);
      setSelectedSite(null);
    }
  };

  const getSitesByOrganization = async (orgId) => {
    try {
      const response = await axios.get(
        `${config.PATH}/api/organizations/${orgId}/sites`
      );
      const siteOptions = response.data.map((site) => ({
        label: site.site_name,
        value: site.site_name,
      }));
      setSiteOptions(siteOptions);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const getOrgList = async () => {
    try {
      const response = await axios.get(`${config.PATH}/api/organizations`);
      setOrgList(response.data);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const handleSiteSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidSiteName = /^[a-zA-Z0-9_-]+$/.test(selectedOption.label);

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidSiteName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidSiteName) {
          errorMessage =
            "Invalid Site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Site name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        site_name: selectedOption.label,
        org_id: selectedOrganization ? selectedOrganization.value : null,
      };
      try {
        await axios.post(`${config.PATH}/api/add-site`, payload);
      } catch (error) {
        console.log("Failed to create site:", error);
        return;
      }
    }
    setSelectedSite(selectedOption);
  };

  const populateAreaList = async () => {
    try {
      const area = allData.data.map((e) => e.area);
      let uniqueAreaList = [...new Set(area)];
      setAreaList(uniqueAreaList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeArea = (areas) => {
    try {
      // setAreasToDisplay(areas);
      const selectedArea = areas.map((e) => e.value);
      setSelectedArea(selectedArea);

      function getCategoriesByAreas(areas) {
        const categories = [];
        for (const item of allData.data) {
          if (selectedArea.includes(item.area)) {
            categories.push(item.category);
          }
        }
        setCategoryList(categories);
      }

      if (areas.length === 0) {
        // If the 'areas' array is empty, set 'categories' and 'categoriesToDisplay' to empty arrays.
        setSelectedCategory([]);
        // setCategoriesToDisplay([]);
        setSelectedObservations([]);
        // Update the observations based on the empty category list
        handleChangeCategory([]);
        setCategoryList([]);
      } else {
        // Remove categories that are not associated with the selected areas.
        const updatedSelectedCategory = selectedCategory.filter((category) => {
          const categoryAreas = allData.data
            .filter((item) => item.category === category)
            .map((item) => item.area);

          const includesArea = categoryAreas.some((area) =>
            selectedArea.includes(area)
          );

          return includesArea;
        });
        setSelectedCategory(updatedSelectedCategory);
        getCategoriesByAreas(areas);

        // Set 'categoriesToDisplay' to the categories associated with the selected areas.
        const updatedCategoriesToDisplay = categoryList
          .filter((category) => updatedSelectedCategory.includes(category))
          .map((category) => ({ label: category, value: category }));

        const uniqueCategories = [
          ...new Set(updatedCategoriesToDisplay.map(JSON.stringify)),
        ].map(JSON.parse);
        // setCategoriesToDisplay(uniqueCategories);
        // Update the observations based on the updated selected categories
        handleChangeCategory(uniqueCategories);
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  console.log(AllObservations);

  const handleChangeCategory = async (cat) => {
    const selectedCat = cat.map((e) => e.value);
    setSelectedCategory(selectedCat);

    try {
      const filteredObservations = allData.data
        .concat(selectedReportData.AllObservations)
        .filter(
          (observation) =>
            selectedCat.includes(observation.category) &&
            selectedArea.includes(observation.area)
        )
        .filter((observation, index, self) => {
          const observationValue = observation.observation;

          // Check if the observationValue is unique in the array
          return (
            index ===
            self.findIndex((obs) => obs.observation === observationValue)
          );
        })
        .map((observation) => {
          const selectedObs = selectedObservations.find(
            (selectedObs) => selectedObs.observation === observation.observation
          );
          if (selectedObs) {
            return {
              ...observation,
              is_selected: 1,
              imageUrls: selectedObs.imageUrls,
            };
          }
          return observation;
        });

      setAllObservations(filteredObservations);
      setSelectedObservations(
        filteredObservations.filter((e) => e.is_selected === 1)
      );
      // Check if any observations in selectedObservations are related to removed categories
      const removedCategories = selectedObservations.filter(
        (selectedObs) => !selectedCat.includes(selectedObs.category)
      );
      if (removedCategories.length > 0) {
        // Uncheck these observations
        const updatedSelectedObservations = selectedObservations.filter(
          (selectedObs) =>
            !removedCategories.some(
              (removedCat) => removedCat.sr_no === selectedObs.sr_no
            )
        );
        setSelectedObservations(updatedSelectedObservations);
      }
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  // // This is to add refIndex in selected observation when component loads
  // useEffect(() => {
  //   // Update refIndex for initially selected observations
  //   setSelectedObservations((prevSelectedObservations) =>
  //     prevSelectedObservations.map((obs) => {
  //       const indexInAllObservations = AllObservations.findIndex(
  //         (allObs) => allObs.id === obs.id
  //       );
  //       return {
  //         ...obs,
  //         refIndex: indexInAllObservations,
  //       };
  //     })
  //   );
  // }, [AllObservations]);

  useEffect(() => {
    // Update refIndex for initially selected observations
    setSelectedObservations((prevSelectedObservations) =>
      prevSelectedObservations.map((obs) => {
        let indexInAllObservations;
        if (obs.id) {
          indexInAllObservations = AllObservations.findIndex(
            (allObs) => allObs.id === obs.id
          );
        } else if (obs.sr_no) {
          indexInAllObservations = AllObservations.findIndex(
            (allObs) => allObs.sr_no === obs.sr_no
          );
        }
        return {
          ...obs,
          refIndex:
            indexInAllObservations !== -1 ? indexInAllObservations : null,
        };
      })
    );
  }, [AllObservations]);

  const handleObservationSelection = (observation, index) => {
    const tempObs = [...AllObservations];

    let isAlreadySelected = observation.is_selected;

    if (isAlreadySelected) {
      delete tempObs[index].is_selected;
      setSelectedObservations(
        [...selectedObservations].filter(
          (e) =>
            `${e.sr_no || e.id}` !== `${observation.sr_no || observation.id}` &&
            e.observation !== observation.observation
        )
      );
      setCriticalObservations(
        [...selectedObservations].filter(
          (e) =>
            e.criticality === "High" &&
            `${e.sr_no || e.id}` !== `${observation.sr_no || observation.id}` &&
            e.observation !== observation.observation
        )
      );
    } else {
      tempObs[index] = { ...observation, is_selected: 1 };
      setSelectedObservations([
        ...selectedObservations,
        { ...observation, refIndex: index },
      ]);
      setCriticalObservations(
        [...selectedObservations, observation].filter(
          (e) => e.criticality === "High"
        )
      );
    }
    setAllObservations(tempObs);
  };

  const handleProceed = async () => {
    try {
      setLoading(true);
      // const payload = {
      //   observations: selectedObservations.map((e) => e.observation),
      //   report_id: selectedReportData.report_id,
      // };
      const reportData = {
        report_id: selectedReportData.report_id,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        area: selectedArea,
        category: selectedCategory,
        background_brief: backgroundBrief,
        contents: contents,
        exe_summary: exeSummary,
        conclusion: conclusion,
        best_practice: bestPractice,
        the_way_forward: theWayForward,
        is_edited: isReportEdited,
        start_date: startDate,
        end_date: endDate,
        is_saved: true,
      };
      // Set the flag based on the module
      if (module === "cmv") {
        reportData.type = "cmv";
      } else {
        reportData.type = "primary";
      }
      handleNext();

      const cmvEndPOint = `${config.PATH}/api/save-update-cmv-report`;
      const reportEndPoint = `${config.PATH}/api/save-update-report`;

      if (module === "cmv") {
        await axios.post(cmvEndPOint, reportData);
      } else {
        await axios.post(reportEndPoint, reportData);
      }

      const observationsData = {
        report_id: selectedReportData.report_id,
        all_observations: AllObservations,
      };

      const observationEndPoint = `${config.PATH}/api/save-update-observations`;
      const cmvObservationEndPoint = `${config.PATH}/api/save-update-cmv-observations`;

      if (module === "cmv") {
        await axios.post(cmvObservationEndPoint, observationsData);
      } else {
        await axios.post(observationEndPoint, observationsData);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const keyNotToCheck = [
    "remarks",
    "score",
    "refIndex",
    "selectedRefIndex",
    "equipment",
    "sr_no",
    "status",
    "is_selected",
    "imageUrls",
    "status",
    "sector_type",
    "table_type"
  ];

  console.log("AllObservations", AllObservations);
  console.log("selected", selectedObservations);

  const handleSave = async (complete) => {
    try {
      setLoading(true);
  
      if (screenNumber === 7 && isRowSaved === false) {
        toast.warning("Please save the new row by clicking on the tick action button.");
        setLoading(false);
        return;
      }
  
      if (isAnyScoreEmpty()) {
        toast.warning("Please fill the score table before saving the report.");
        setLoading(false);
        return;
      }
  
      let updatedSelectedObservations = [...selectedObservations];
      let updatedAllObservations = [...AllObservations];
  
      // Trimming fields in observations and report data
      const trimObjectFields = (obj) => {
        const trimmedObj = { ...obj };
        Object.keys(trimmedObj).forEach((key) => {
          if (typeof trimmedObj[key] === 'string') {
            trimmedObj[key] = trimmedObj[key].trim();
          }
        });
        return trimmedObj;
      };
  
      if (editedObservations[0]) {
        // Check for empty fields after trimming
        if (
          Object.entries(trimObjectFields(editedObservations[0])).filter(
            (e) => !(keyNotToCheck.includes(e[0]) || e[1])
          ).length > 0
        ) {
          toast.error("Table fields can't be empty.");
          setLoading(false);
          return;
        }
  
        editedObservations.forEach((obj) => {
          const trimmedObj = trimObjectFields(obj);
          updatedSelectedObservations[obj.selectedRefIndex] = trimmedObj;
          updatedAllObservations[obj.refIndex] = { ...trimmedObj, is_selected: 1 };
        });
  
        if (isReportEdited) {
          const payload = editedObservations
            .map(({ sr_no, score, edited_fields, ...rest }) => ({
              ...trimObjectFields(rest), // Trimmed rest of the fields
              edited_fields: editedFields, // Include edited_fields in the payload
            }))
            .filter((item) => Object.keys(item).length > 0); // Filter out items with no properties
  
          if (payload.length > 0) {
            await axios.post(`${config.PATH}/api/insert-new-row`, payload);
          }
        }
  
        setSelectedObservations(updatedSelectedObservations);
        setAllObservations(updatedAllObservations);
      }
  
      // Trim fields in reportData
      const reportData = trimObjectFields({
        report_id: selectedReportData.report_id,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization ? selectedOrganization.label : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        org_id: selectedOrganization ? selectedOrganization.value : selectedOrganization,
        area: selectedArea,
        category: selectedCategory,
        background_brief: backgroundBrief,
        contents: contents,
        exe_summary: exeSummary,
        conclusion: conclusion,
        best_practice: bestPractice,
        the_way_forward: theWayForward,
        scores: scores,
        cumulative: cumulativeScore,
        is_edited: isReportEdited,
        other_details: otherDetails,
        is_complete: complete === true ? true : false,
        start_date: startDate,
        end_date: endDate,
        is_saved: true,
      });
  
      const cmvEndPOint = `${config.PATH}/api/save-update-cmv-report`;
      const reportEndPoint = `${config.PATH}/api/save-update-report`;
  
      if (module === "cmv") {
        await axios.post(cmvEndPOint, reportData);
      } else if (complete === true && module !== "cmv") {
        await axios.post(cmvEndPOint, reportData);
        await axios.post(reportEndPoint, reportData);
      } else {
        await axios.post(reportEndPoint, reportData);
      }
  
      // Trim fields in observationsData
      const observationsData = trimObjectFields({
        report_id: selectedReportData.report_id,
        all_observations: updatedAllObservations,
        organization: selectedOrganization ? selectedOrganization.label : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
      });
  
      const observationEndPoint = `${config.PATH}/api/save-update-observations`;
      const cmvObservationEndPoint = `${config.PATH}/api/save-update-cmv-observations`;
  
      if (module === "cmv") {
        await axios.post(cmvObservationEndPoint, observationsData);
      } else if (complete === true && module !== "cmv") {
        await axios.post(cmvObservationEndPoint, observationsData);
        await axios.post(observationEndPoint, observationsData);
      } else {
        await axios.post(observationEndPoint, observationsData);
      }
  
      setIsSaved(true);
      setEditedObservations([]);
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setDisableSaveNext(false);
      getAllData();
      updateOrgReportStatus();
      updateSiteReportStatus();
      setEditedFields([]);
      setConfirmationShown(false);
      toast.success(
        `${complete === true ? "Report Completed and Saved" : "Report Saved"}`
      );
      saveCriticalObservations(complete);
      saveFacilityInfo();
      setLoading(false);
    } catch (error) {
      console.log("Error saving report:", error);
      setLoading(false);
    }
  };
  

  // const handleSave = async (complete) => {
  //   try {
  //     setLoading(true);

  //     if (screenNumber === 7 && isRowSaved === false) {
  //       toast.warning(
  //         "Please save the new row by clicking on tick action button."
  //       );
  //       setLoading(false);
  //       return;
  //     }

  //     if (isAnyScoreEmpty()) {
  //       toast.warning("Please fill the score table before saving the report.");
  //       setLoading(false);
  //       return;
  //     }

  //     let updatedSelectedObservations = [...selectedObservations];
  //     let updatedAllObservations = [...AllObservations];

  //     if (editedObservations[0]) {
  //       if (
  //         Object.entries(editedObservations[0]).filter(
  //           (e) => !(keyNotToCheck.includes(e[0]) || e[1])
  //         ).length > 0
  //       ) {
  //         toast.error("Table fields can't be empty.");
  //         setLoading(false);
  //         return;
  //       }

  //       // if (
  //       //   selectedObservations.filter(
  //       //     (obj) => obj.observation === editedObservations[0].observation
  //       //   ).length > 0
  //       // ) {
  //       //   toast.error(
  //       //     "Duplicate observations detected. Please modify observations to ensure uniqueness."
  //       //   );
  //       //   setLoading(false);
  //       //   return;
  //       // }

  //       editedObservations.forEach((obj) => {
  //         updatedSelectedObservations[obj.selectedRefIndex] = obj;
  //         updatedAllObservations[obj.refIndex] = { ...obj, is_selected: 1 };
  //       });

  //       // if (isReportEdited) {
  //       //   const payload = editedObservations
  //       //     // .filter(({ note_type }) => !note_type)
  //       //     .map(({ sr_no, score, note_type, ...rest }) => rest);

  //       //   if (payload.length > 0) {
  //       //     await axios.post(`${config.PATH}/api/insert-new-row`, payload);
  //       //   }
  //       // } else {
  //       //   //pass
  //       // }

  //       if (isReportEdited) {
  //         const payload = editedObservations
  //           .map(({ sr_no, score, edited_fields, ...rest }) => ({
  //             ...rest,
  //             edited_fields: editedFields, // Include edited_fields in the payload
  //           }))
  //           .filter((item) => Object.keys(item).length > 0); // Filter out items with no properties

  //         if (payload.length > 0) {
  //           await axios.post(`${config.PATH}/api/insert-new-row`, payload);
  //         }
  //       } else {
  //         //pass
  //       }

  //       setSelectedObservations(updatedSelectedObservations);
  //       setAllObservations(updatedAllObservations);
  //     }

  //     const reportData = {
  //       report_id: selectedReportData.report_id,
  //       user_id: userId,
  //       date_time: selectedDateTime,
  //       organization: selectedOrganization
  //         ? selectedOrganization.label
  //         : selectedOrganization,
  //       site: selectedSite ? selectedSite.label : selectedSite,
  //       org_id: selectedOrganization
  //         ? selectedOrganization.value
  //         : selectedOrganization,
  //       area: selectedArea,
  //       category: selectedCategory,
  //       background_brief: backgroundBrief,
  //       contents: contents,
  //       exe_summary: exeSummary,
  //       conclusion: conclusion,
  //       best_practice: bestPractice,
  //       the_way_forward: theWayForward,
  //       scores: scores,
  //       cumulative: cumulativeScore,
  //       is_edited: isReportEdited,
  //       other_details: otherDetails,
  //       is_complete: complete === true ? true : false,
  //       start_date: startDate,
  //       end_date: endDate,
  //       is_saved: true,
  //     };

  //     if (module === "cmv") {
  //       reportData.type = "cmv";
  //     } else {
  //       reportData.type = "primary";
  //     }

  //     const cmvEndPOint = `${config.PATH}/api/save-update-cmv-report`;
  //     const reportEndPoint = `${config.PATH}/api/save-update-report`;

  //     if (module === "cmv") {
  //       await axios.post(cmvEndPOint, reportData);
  //     } else if (complete === true && module !== "cmv") {
  //       await axios.post(cmvEndPOint, reportData);
  //       await axios.post(reportEndPoint, reportData);
  //     } else {
  //       await axios.post(reportEndPoint, reportData);
  //     }

  //     const observationsData = {
  //       report_id: selectedReportData.report_id,
  //       all_observations: updatedAllObservations
  //         ? updatedAllObservations
  //         : AllObservations,
  //       organization: selectedOrganization.label,
  //       site: selectedSite.label,
  //     };

  //     const observationEndPoint = `${config.PATH}/api/save-update-observations`;
  //     const cmvObservationEndPoint = `${config.PATH}/api/save-update-cmv-observations`;

  //     if (module === "cmv") {
  //       await axios.post(cmvObservationEndPoint, observationsData);
  //     } else if (complete === true && module !== "cmv") {
  //       await axios.post(cmvObservationEndPoint, observationsData);
  //       await axios.post(observationEndPoint, observationsData);
  //     } else {
  //       await axios.post(observationEndPoint, observationsData);
  //     }

  //     setIsSaved(true);
  //     setEditedObservations([]);
  //     setCurrentEditedRow(-1);
  //     setIsEditing(false);
  //     setDisableSaveNext(false);
  //     getAllData();
  //     updateOrgReportStatus();
  //     updateSiteReportStatus();
  //     setEditedFields([]);
  //     setConfirmationShown(false);
  //     toast.success(
  //       `${complete === true ? "Report Completed and Saved" : "Report Saved"}`
  //     );
  //     saveCriticalObservations(complete);
  //     saveFacilityInfo();
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error saving report:", error);
  //   }
  // };

  const saveCriticalObservations = async (complete) => {
    try {
      // Create a new array with objects excluding the 'id' field
      const observationsToSave = criticalObservations.map(
        ({ id, ...observation }) => observation
      );

      // if (observationsToSave.length === 0) {
      //   console.log("No observations to save.");
      //   return;
      // }

      const payload = {
        criticalObservations: observationsToSave,
        report_id: selectedReportData.report_id,
      };

      // await axios.post(
      //   module === "cmv"
      //     ? `${config.PATH}/api/save-critical-cmv-observations`
      //     : `${config.PATH}/api/save-critical-observations`,
      //   payload
      // )

      const criticalObsEndPoint = `${config.PATH}/api/save-critical-observations`;
      const cmvCriticalObsEndPoint = `${config.PATH}/api/save-critical-cmv-observations`;

      // if (complete === true && module !== "cmv") {
      //   console.log("only true")
      //   await axios.post(cmvCriticalObsEndPoint, payload);
      //   await axios.post(criticalObsEndPoint, payload);
      // } else if (complete === true && module === "cmv") {
      //   console.log("both")
      //   await axios.post(cmvCriticalObsEndPoint, payload);
      // } else {
      //   console.log("nothing")
      //   await axios.post(criticalObsEndPoint, payload);
      // }

      if (module === "cmv") {
        console.log("1");
        await axios.post(cmvCriticalObsEndPoint, payload);
      } else if (complete === true && module !== "cmv") {
        console.log("2");
        await axios.post(cmvCriticalObsEndPoint, payload);
        await axios.post(criticalObsEndPoint, payload);
      } else {
        console.log("3");
        await axios.post(criticalObsEndPoint, payload);
      }

      console.log("Critical observations saved successfully.");
    } catch (error) {
      console.log("Error saving critical observations:", error.message);
    }
  };

  const handleChange = (e, name) => {
    setIsSaved(false);
    if (name === "background") {
      setBackgroundBrief(e.target.value);
    } else if (name === "contents") {
      setContents(e.target.value);
    } else if (name === "exe") {
      setExeSummary(e.target.value);
    } else if (name === "conclusion") {
      setConclusion(e.target.value);
    } else if (name === "other details") {
      setOtherDetails(e.target.value);
    } else if (name === "best_practice") {
      setbestPractice(e.target.value);
    } else if (name === "the_way_forward") {
      setTheWayForward(e.target.value);
    }
  };

  const handleNext = () => {
    if (screenNumber === 7 && isRowSaved === false) {
      toast.warning(
        "Please save the new row by clicking on tick action button."
      );
      setLoading(false);
      return;
    }

    if (isEditing) {
      toast.warning("Please save changes before proceeding.");
      return;
    }
    setScreenNumber(screenNumber + 1);
    // setOpenReportList(false);
  };

  const handlePrev = () => {
    if (screenNumber === 7 && isRowSaved === false) {
      toast.warning(
        "Please save the new row by clicking on tick action button."
      );
      setLoading(false);
      return;
    }

    if (isEditing) {
      toast.warning("Please save changes before going back.");
      return;
    }
    setScreenNumber(screenNumber - 1);
  };

  const removeItem = (index) => {
    const updatedObservations = [...criticalObservations];
    updatedObservations.splice(index, 1);
    setCriticalObservations(updatedObservations);
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const MAX_WIDTH = 800; // Maximum width for the compressed image
        const MAX_HEIGHT = 800; // Maximum height for the compressed image

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.7); // 0.7 is the image quality (0.0 - 1.0)
      };

      img.src = URL.createObjectURL(file);
    });
  };

  // const handleImageUpload = async (index, files) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...AllObservations];

  //   if (!selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls = [];
  //   }

  //   try {
  //     setLoading(true);
  //     for (const file of Array.from(files)) {
  //       if (!file.type.startsWith("image/")) {
  //         throw new Error("Only image files are allowed.");
  //       }

  //       const compressedImage = await compressImage(file);

  //       const formData = new FormData();
  //       formData.append("image", compressedImage);

  //       const response = await axios.post(
  //         `${config.PATH}/api/upload/image `,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const imageUrl = response.data.imageUrl;
  //       selectedObsCopy[index].imageUrls.push(imageUrl);
  //       // imageUrlsByRow[index].push(imageUrl);
  //     }

  //     selectedObsCopy.forEach((e) => {
  //       obsCopy[e.refIndex] = {
  //         ...obsCopy[e.refIndex],
  //         imageUrls: e.imageUrls,
  //       };
  //     });

  //     // setImageUrlsByRow({ ...imageUrlsByRow });
  //     setSelectedObservations(selectedObsCopy);
  //     setAllObservations(obsCopy);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error uploading image:", error);
  //     toast.error(error.message);
  //   }
  // };

  const handleImageUpload = async (index, files) => {
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...AllObservations];
    const editedObsCopy = [...editedObservations];

    if (!selectedObsCopy[index].imageUrls) {
      selectedObsCopy[index].imageUrls = [];
    }

    try {
      setLoading(true);

      for (const file of Array.from(files)) {
        if (!file.type.startsWith("image/")) {
          throw new Error("Only image files are allowed.");
        }

        const compressedImage = await compressImage(file);

        const formData = new FormData();
        formData.append("image", compressedImage);

        const response = await axios.post(
          `${config.PATH}/api/upload/image `,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageUrl = response.data.imageUrl;
        selectedObsCopy[index].imageUrls.push(imageUrl);

        // Update editedObservations if it has values
        if (editedObsCopy.length > 0) {
          const editedIndex = editedObsCopy.findIndex(
            (observation) =>
              observation.refIndex === selectedObsCopy[index].refIndex
          );
          if (editedIndex !== -1) {
            editedObsCopy[editedIndex].imageUrls =
              selectedObsCopy[index].imageUrls;
          }
        }
      }

      selectedObsCopy.forEach((e) => {
        obsCopy[e.refIndex] = {
          ...obsCopy[e.refIndex],
          imageUrls: e.imageUrls,
        };
      });

      setSelectedObservations(selectedObsCopy);
      setAllObservations(obsCopy);

      // Update editedObservations only if it has values
      if (editedObsCopy.length > 0) {
        setEditedObservations(editedObsCopy);
      }

      setLoading(false);
    } catch (error) {
      console.log("Error uploading image:", error);
      toast.error(error.message);
    }
  };

  const handleRemoveImage = (index, imageIndex) => {
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...AllObservations];

    if (selectedObsCopy[index].imageUrls) {
      selectedObsCopy[index].imageUrls.splice(imageIndex, 1);

      // If there are no more images in the array, remove the property
      if (selectedObsCopy[index].imageUrls.length === 0) {
        delete selectedObsCopy[index].imageUrls;
      }

      // Update the corresponding observation in obsCopy
      obsCopy[selectedObsCopy[index].refIndex] = {
        ...obsCopy[selectedObsCopy[index].refIndex],
        imageUrls: selectedObsCopy[index].imageUrls,
      };

      // Update state
      setSelectedObservations(selectedObsCopy);
      setAllObservations(obsCopy);
    }
  };

  const handleClose = () => {
    if (isEditing && screenNumber === 7) {
      toast.warning("Please save changes before closing the report.");
      return;
    } else if (!isSaved && screenNumber !== 1 && !exp) {
      toast.warning("Please save the report before closing.");
      return;
    } else {
      closeReport();
    }
  };

  const closeReport = () => {
    if (isAnyScoreEmpty()) {
      toast.warning("Please fill the score table before closing the report.");
      return;
    }
    setOpenSavedReport(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleDuplicateRow = (index) => {
    if (isEditing) {
      toast.warn(
        "Please finish editing the current row before adding a new row."
      );
      return;
    }

    const selectedOriginalRow = selectedObservations[index];

    // Check if imageUrls is defined, and create a deep copy if it is, or an empty array otherwise
    const duplicatedRowForSelected = {
      ...selectedOriginalRow,
      refIndex: selectedOriginalRow.refIndex + 1,
      imageUrls: selectedOriginalRow.imageUrls
        ? [...selectedOriginalRow.imageUrls]
        : [],
    };

    const updatedAllObservations = [...AllObservations];
    const updatedSelectedObservations = [...selectedObservations];

    updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
      ...duplicatedRowForSelected,
      is_selected: 1,
    });
    updatedSelectedObservations.splice(index + 1, 0, duplicatedRowForSelected);

    setAllObservations(updatedAllObservations);
    setSelectedObservations(updatedSelectedObservations);

    toast.success("New row added");
  };

  const handleDeleteRow = (index) => {
    if (isEditing && currentEditedRow !== index) {
      toast.warn(
        "Please finish editing the current row before deleting the row."
      );
      return;
    }
    const updatedSelectedObservations = [...selectedObservations];
    const updatedAllObservations = [...AllObservations];

    // Check if there is only one row left
    if (updatedSelectedObservations.length === 1) {
      toast.warn("Cannot delete the last row.");
      return;
    }

    const refIndex = selectedObservations[index].refIndex;

    updatedSelectedObservations.splice(index, 1);
    updatedAllObservations.splice(refIndex, 1);
    updatedSelectedObservations.forEach(
      (e) => (e.refIndex = e.refIndex > refIndex ? e.refIndex - 1 : e.refIndex)
    );
    // updatedAllObservations.forEach((e,index) => e.index = e.index>refIndex?e.index-1:e.index)
    setSelectedObservations(updatedSelectedObservations);
    setAllObservations(updatedAllObservations);
    setIsEditing(false);
    setEditedObservations([]);
    setCurrentEditedRow(-1);
    toast.error("Row deleted");
  };

  const handleScoreChange = (index, value) => {
    setIsSaved(false);
    // Ensure the value is a number and within the range [0, 2]
    const parsedValue = parseFloat(value);
    const scoreValue = isNaN(parsedValue)
      ? ""
      : Math.min(2.0, Math.max(0, parsedValue));

    const updatedScores = [...scores];
    updatedScores[index]["Score Obtained"] = scoreValue;
    setScores(updatedScores);
  };

  const isAnyScoreEmpty = () => {
    return scores.some((score) => score["Score Obtained"] === "");
  };

  const handleObservationEdit = (index, e) => {
    setDisableSaveNext(true);
    const updatedObservations = [...criticalObservations];
    updatedObservations[index].observation = e.target.value;
    setCriticalObservations(updatedObservations);
  };

  const handleStartEndDate = (date, name) => {
    if (!date) {
      // Handle case when date is null or undefined
      if (name === "start-date") {
        setStartDate(null);
      } else if (name === "end-date") {
        setEndDate(null);
      } else {
        if (startDate) setEndDate(null);
      }
      return;
    }

    // Ensure that the date part is set to midnight (00:00:00)
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);

    if (name === "start-date") {
      if (endDate && adjustedDate > endDate) {
        toast.warning("Start date cannot be after the end date.");
        setStartDate(null);
        return;
      }
      setStartDate(adjustedDate);
    } else {
      if (startDate && adjustedDate < startDate) {
        toast.warning("End date cannot be before the start date.");
        setEndDate(null);
        return;
      }
      setEndDate(adjustedDate);
    }
  };

  const updateOrgReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      axios.post(`${config.PATH}/api/update-org-report-status/${org_id}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateSiteReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      const site_name = selectedSite.value;
      axios.post(
        `${config.PATH}/api/update-site-report-status/${org_id}/${site_name}`
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleComplete = async () => {
    if (!isSaved) {
      toast.warning("Please save the report before marking as complete.");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to mark this report as complete? Once done action cannot be reversed and you cannot edit the report.`
    );

    if (!userConfirmed) {
      return;
    }
    setIsComplete(true);
    handleSave(true);
    closeReport();
    setOpenReportList(false);
    getAllReports();
  };

  // const uniqueCategories = new Set();
  // const categoryOptions = [];

  // allData.data.forEach((e) => {
  //   if (!uniqueCategories.has(e.category)) {
  //     categoryOptions.push({ value: e.category, label: e.category });
  //     uniqueCategories.add(e.category);
  //   }
  // });

  useEffect(() => {
    const uniqueCategories = new Set();
    const filteredOptions = [];

    allData.data.forEach((e) => {
      if (area.includes(e.area) && !uniqueCategories.has(e.category)) {
        filteredOptions.push({ value: e.category, label: e.category });
        uniqueCategories.add(e.category);
      }
    });

    setCategoryOptions(filteredOptions);
  }, [getAllData, area]);

  const customSelectStylesCreatable = {
    control: (provided) => ({
      ...provided,
      // borderBottom: "none", // Hide the separator
      boxShadow: "none", // Hide the box shadow
      cursor: "pointer", // Show the pointer cursor
      width: "150px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      borderLeft: 0, // Hide the vertical separator beside the dropdown indicator
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index
      position: "absolute",
    }),
  };

  const getObservationVariants = async (observation, index) => {
    try {
      if (isEditing && currentEditedRow !== index) {
        toast.warn("Please finish editing the current row.");
        return;
      }
      setOpenVariantModal(true);
      const payload = {
        observation,
        report: "saved",
      };
      const response = await axios.post(
        `${config.PATH}/api/search-by-observation`,
        payload
      );

      // Ensure the response data is an array
      if (Array.isArray(response.data)) {
        // Add variant: true to each item in the response data
        const updatedVariants = response.data.map((item) => ({
          ...item,
          variant: true,
        }));
        setObservationVariants(updatedVariants);
      } else {
        console.log("Unexpected response data format:", response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const handleConfirmSelection = (selectedVariants, removedItems) => {

  //   let map = {}
  //   // Update both states with the selected variants
  //   setSelectedObservations([...selectedObservations, ...selectedVariants,].filter((e) => {
  //     if(!(map[e.sr_no] || removedItems.includes(e.sr_no))) {
  //       map[e.sr_no] = true
  //       return true
  //     } else {
  //       return false
  //     }
  //   }));
  //   map = {}
  //   setAllObservations([...AllObservations, ...selectedVariants,].filter((e) => {
  //     if(!(map[e.sr_no] || removedItems.includes(e.sr_no))) {
  //       map[e.sr_no] = true
  //       return true
  //     } else {
  //       return false
  //     }
  //   }));
  // };

  const handleConfirmSelection = (selectedVariants, removedItems) => {
    let map = {};

    const getId = (item) => item.sr_no || item.id;

    // Update both states with the selected variants
    setSelectedObservations(
      [...selectedObservations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );

    map = {};
    setAllObservations(
      [...AllObservations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );
  };

  const closeVariantModal = () => {
    setOpenVariantModal(false);
  };

  const criticalityOptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const filteredObservations = AllObservations.filter(
    (observation) =>
      observation.observation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      observation.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
      observation.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Group filtered observations by area
  const groupedObservations = filteredObservations.reduce(
    (groups, observation) => {
      const { area } = observation;
      if (!groups[area]) {
        groups[area] = [];
      }
      groups[area].push(observation);
      return groups;
    },
    {}
  );

  const allDataForGlobalSearch = allData.data;
  const filteredAllData = allDataForGlobalSearch.filter(
    (observation) =>
      observation.observation
        .toLowerCase()
        .includes(globalSearchTerm.toLowerCase()) ||
      observation.area.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
      observation.category
        .toLowerCase()
        .includes(globalSearchTerm.toLowerCase())
  );
  // Group filtered observations by area
  const groupedData = filteredAllData.reduce((groups, observation) => {
    const { area } = observation;
    if (!groups[area]) {
      groups[area] = [];
    }
    groups[area].push(observation);
    return groups;
  }, {});

  useEffect(() => {
    handleEmptyFields();
  }, [newRow]);

  const handleEmptyFields = () => {
    const isEmpty = Object.entries(newRow).every(([key, value]) => {
      if (key === "is_selected") return true; // ignore the is_selected field
      if (Array.isArray(value)) {
        return value.length === 0;
      } else {
        return value === "";
      }
    });
    if (isEmpty) {
      setIsRowSaved(true); // Set isNewRowSaved to true if all fields are empty
    }
  };

  const handleChangeNewRow = (e, field) => {
    const value = e.target.value;
    if (
      (field === "area" || field === "category" || field === "criticality") &&
      value.length > 100
    ) {
      alert(`${field} cannot exceed 100 characters.`);
      return; // Do not update the state if validation fails
    }
    setNewRow((prev) => ({ ...prev, [field]: value, is_selected: 1 }));
    setIsRowSaved(false); // Set isNewRowSaved to false if any change is made
  };

  // const handleChangeNewRow = (e, field) => {
  //   const value = e.target.value;
  // if ((field === 'area' || field === 'category' || field === 'criticality') && value.length > 100) {
  //   alert(`${field} cannot exceed 100 characters.`);
  //   return; // Do not update the state if validation fails
  // }
  //   setNewRow((prev) => ({ ...prev, [field]: value, is_selected: 1 }));
  // };

  // const handleChangeNewRow = (e, field) => {
  //   const value = e.target.value;
  //   setNewRow((prev) => ({ ...prev, [field]: value, is_selected: 1 }));
  // };

  // const handleAddRow = () => {
  //      // Check if area or observation fields are empty
  //      if (!newRow.area.trim() || !newRow.observation.trim()) {
  //       alert("Area and Observation fields cannot be empty.");
  //       return;
  //     }

  //   // Check if all fields are empty
  //   const areAllFieldsEmpty = Object.values(newRow).every(
  //     (value) => value === "" || (Array.isArray(value) && value.length === 0)
  //   );

  //   if (areAllFieldsEmpty) {
  //     alert("Cannot add empty row.");
  //     return;
  //   }
  //   selectedObservations.push({ ...newRow, imageUrls: [] });
  //   AllObservations.push({ ...newRow, imageUrls: [] });
  //   setNewRow({
  //     area: "",
  //     category: "",
  //     check_points: "",
  //     observation: "",
  //     criticality: "",
  //     recommendations: "",
  //     is_reference: "",
  //     imageUrls: [],
  //   });
  // };

  const handleAddRow = () => {
    // Check if area or observation fields are empty
    if (!newRow.area.trim() || !newRow.observation.trim() || !newRow.recommendations.trim() || !newRow.is_reference.trim()) {
      alert("Area, Observation, Recommendations & IS Reference fields cannot be empty.");
      return;
    }

    // Replace empty fields with "N/A"
    const updatedRow = {
      ...newRow,
      area: newRow.area.trim() || "N/A",
      category: newRow.category.trim() || "N/A",
      check_points: newRow.check_points.trim() || "N/A",
      observation: newRow.observation.trim() || "N/A",
      criticality: newRow.criticality.trim() || "N/A",
      recommendations: newRow.recommendations.trim() || "N/A",
      is_reference: newRow.is_reference.trim() || "N/A",
      imageUrls: newRow.imageUrls.length > 0 ? newRow.imageUrls : [],
    };

    // Check if all fields are empty (excluding imageUrls)
    const areAllFieldsEmpty = Object.values(updatedRow).every(
      (value) => value === "" || (Array.isArray(value) && value.length === 0)
    );

    if (areAllFieldsEmpty) {
      alert("Cannot add empty row.");
      return;
    }

    setEditedObservations((prevObservations) => [...prevObservations, updatedRow]);
    setIsReportEdited(true);

    selectedObservations.push(updatedRow);
    AllObservations.push(updatedRow);
    setNewRow({
      area: "",
      category: "",
      check_points: "",
      observation: "",
      criticality: "",
      recommendations: "",
      is_reference: "",
      imageUrls: [],
    });
  };

  const getFacilityInfo = async () => {
    const response = await axios.get(
      `${config.PATH}/api/get-electrical-facility-info/${selectedReportData.report_id}`
    );
    const data = response.data;
    // Update each field individually
    setFacilityInfo(data);
  };

  const saveFacilityInfo = async () => {
    try {
      const payload = {
        report_id: selectedReportData.report_id,
        facility_info: facilityInfo,
      };
      const res = await axios.post(
        `${config.PATH}/api/save-electrical-facility-info`,
        payload
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChangeFacilityInfo = (event) => {
    const { name, value } = event.target;
    setFacilityInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleNewKeyChange = (event) => {
    setNewKey(event.target.value);
  };

  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
  };

  // const handleAddNewField = () => {
  //   if (newKey && newValue) {
  //     setFacilityInfo((prevInfo) => ({
  //       ...prevInfo,
  //       [newKey]: newValue,
  //     }));
  //     setNewKey("");
  //     setNewValue("");
  //   } else {
  //     alert("Please fill both the fields before adding.");
  //   }
  // };

  const handleAddNewField = () => {
    if (newKey && newValue) {
      const keyExists = facilityInfo.hasOwnProperty(newKey);
      const valueExists = facilityInfo[newKey] === newValue;
  
      if (keyExists) {
        alert("This field already exists.");
        return;
      }
  
      setFacilityInfo((prevInfo) => ({
        ...prevInfo,
        [newKey]: newValue,
      }));
      setNewKey("");
      setNewValue("");
    } else {
      alert("Please fill both the fields before adding.");
    }
  };

  const handleRemoveField = (key) => {
    if (Object.keys(facilityInfo).length === 1) {
      alert("Cannot remove the last field.");
      return;
    }
  
    const updatedFacilityInfo = { ...facilityInfo };
    delete updatedFacilityInfo[key];
    setFacilityInfo(updatedFacilityInfo);
  };

  if (screenNumber === 1) {
    return (
      <div>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="modal-container">
            <div className="modal-header">
              <Typography variant="h5">
                {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
              </Typography>
              <button className="custom-close-button" onClick={handleClose}>
                &#10005; {/* Unicode for 'X' */}
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-body">
              Report ID: {`${selectedReportData.report_id}`}
                <Typography variant="body1">
                  <div className="select-container">
                    {/* <Select
                      styles={customSelectStyles}
                      isDisabled={true}
                      value={[
                        {
                          label: selectedReportData.report_id,
                          value: selectedReportData.report_id,
                        },
                      ]}
                    /> */}
                    {/* <div
                      style={{
                        borderStyle: "solid",
                        borderRadius: "5px",
                        borderWidth: "0.05mm",
                        borderColor: "lightgray",
                      }}
                    >
                      <input
                        type="datetime-local"
                        id="datetime-local"
                        value={selectedDateTime}
                        onChange={(e) => handleDateTime(e)}
                        style={{
                          width: "96%",
                          border: "none",
                          fontSize: "15px",
                          padding: "8px",
                          fontFamily: "inherit",
                        }}
                        disabled={true}
                      />
                    </div> */}
                    <CreatableSelect
                      placeholder="Organization"
                      options={orgList.map((e) => ({
                        label: e.org_name,
                        value: e.id,
                      }))}
                      onChange={handleOrganizationSelection}
                      value={selectedOrganization}
                      isSearchable
                      isClearable
                      isDisabled
                    />
                    <CreatableSelect
                      placeholder="Site"
                      options={siteOptions}
                      onChange={handleSiteSelection}
                      value={selectedSite}
                      isSearchable
                      isClearable
                      // isDisabled={!selectedOrganization}
                      isDisabled={true}
                    />
                  </div>

                  <div className="flex-container-start-end-date">
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={new Date(startDate)}
                        onChange={(e) => handleStartEndDate(e, "start-date")}
                        className="class-for-date-pickers"
                        placeholderText="Audit Start Date"
                        dateFormat="dd-MM-yyyy"
                        utcOffset={0}
                        maxDate={new Date(endDate)}
                        todayButton={"Today"}
                      />
                    </div>
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={new Date(endDate)}
                        onChange={(e) => handleStartEndDate(e, "end-date")}
                        className="class-for-date-pickers"
                        placeholderText="Audit End Date"
                        dateFormat="dd-MM-yyyy"
                        utcOffset={0}
                        minDate={new Date(startDate)}
                        todayButton={"Today"}
                      />
                    </div>
                  </div>
                  <div className="flex-container">
                    <Select
                      className="select"
                      placeholder="Area"
                      options={areaList.map((area) => ({
                        label: area,
                        value: area,
                      }))}
                      onChange={(e) => handleChangeArea(e)}
                      isMulti={true}
                      defaultValue={selectedArea.map((area) => ({
                        label: area,
                        value: area,
                      }))}
                    />
                    <Select
                      className="select"
                      placeholder="Category"
                      options={[...new Set(categoryList)].map((cat) => ({
                        label: cat,
                        value: cat,
                      }))}
                      onChange={(e) => handleChangeCategory(e)}
                      isMulti={true}
                      value={selectedCategory.map((cat) => ({
                        label: cat,
                        value: cat,
                      }))}
                    />
                  </div>
                </Typography>
                {/* <div className="observations-container">
                  {AllObservations.length > 0 && selectedCategory.length > 0 ? (
                    <div className="observations-list">
                      {AllObservations.map((observation, index) => (
                        <div key={index} className="observation-item">
                          <input
                            type="checkbox"
                            checked={observation.is_selected === 1}
                            onChange={() =>
                              handleObservationSelection(observation, index)
                            }
                          />
                          <span>
                            {observation.observation} (
                            <span style={{ fontWeight: "bold" }}>
                              {observation.area}, {observation.category}
                            </span>
                            )
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="no-observations">
                      No observations available.
                    </div>
                  )}
                </div> */}
                <div className="observation-and-global-search">
                  <div className="observations-container">
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <input
                      type="text"
                      placeholder="Search Observations"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    <div>
                      <button onClick={handleOpenDrawer} className="search-bar-button">
                      <span className="search-icon">&#128269;</span> {/* Unicode for search icon */}
                      <span className="search-text">All Observations</span>
                      </button>

                        <ObservationsDrawer
                          isOpen={isDrawerOpen}
                          onClose={handleCloseDrawer}
                          groupedData={groupedData}
                          globalSearchTerm={globalSearchTerm}
                          setGlobalSearchTerm={setGlobalSearchTerm}
                        />
                      </div>
                    </div>
                    {Object.keys(groupedObservations).length > 0 &&
                    selectedCategory.length > 0 ? (
                      <div className="observations-list">
                        {Object.entries(groupedObservations).map(
                          ([area, observationsInArea]) => (
                            <div key={area} className="area-group">
                              <h4>
                                <u>
                                  <em>{area}</em>
                                </u>
                              </h4>
                              {observationsInArea.map((observation, index) => (
                                <div
                                  key={index}
                                  className="observation-item-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    checked={observation.is_selected === 1}
                                    onChange={() =>
                                      handleObservationSelection(
                                        observation,
                                        AllObservations.indexOf(observation)
                                      )
                                    }
                                  />
                                  <span>
                                    {observation.observation} (
                                    <span style={{ fontWeight: "bold" }}>
                                      {/* {observation.area}, {observation.category} */}
                                      {observation.category}
                                    </span>
                                    ){" "}
                                    {/* {observation.edited_fields && (
                      <sup>
                        {JSON.parse(observation.edited_fields).map((field, index) => (
                          <span key={index}>
                            {index > 0 && ", "} {field}
                          </span>
                        ))}
                      </sup>
                    )} */}
                                  </span>
                                  {observation.isNote && (
                                    <span className="note-label">(Note)</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="no-observations">
                        No observations available.
                      </div>
                    )}
                  </div>
                  {/* <div
                    style={{ maxHeight: "45vh" }}
                    className="observations-container"
                  >
                    <input
                      type="text"
                      placeholder="Search all observations"
                      value={globalSearchTerm}
                      onChange={(e) => setGlobalSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    {Object.keys(groupedData).length > 0 ? (
                      <div className="observations-list">
                        {Object.entries(groupedData).map(
                          ([area, observationsInArea]) => (
                            <div key={area} className="area-group">
                              <h4>
                                <u>
                                  <em>{area}</em>
                                </u>
                              </h4>
                              {observationsInArea.map((observation, index) => (
                                <div
                                  key={index}
                                  className="observation-item-checkbox"
                                >
                                  {`${index + 1}.`}&nbsp;
                                  <span>
                                    {observation.observation} (
                                    <span style={{ fontWeight: "bold" }}>
                                      {observation.category}
                                    </span>
                                    )
                                  </span>
                                  {observation.isNote && (
                                    <span className="note-label">(Note)</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="no-observations">
                        No observations available.
                      </div>
                    )}
                  </div> */}
                </div>
                <div className="get-recommendations-container">
                  <button
                    className="button-styles"
                    onClick={handleProceed}
                    disabled={
                      selectedObservations.length === 0 ||
                      !selectedSite ||
                      !selectedOrganization ||
                      !startDate ||
                      !endDate
                    }
                    style={{
                      background:
                        selectedObservations.length === 0 ||
                        !selectedOrganization ||
                        !selectedSite ||
                        !startDate ||
                        !endDate
                          ? "lightgrey"
                          : "#efc71d",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else if (screenNumber === 2) {
    return (
      <div>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      BACKGROUND &#8208; PROJECT BRIEF
                    </div>
                    <br />
                    <TextareaAutosize
                      value={backgroundBrief}
                      onChange={(e) => handleChange(e, "background")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </div>
    );
  } else if (screenNumber === 3) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      UNDERSTANDING OF THE REVIEW REPORT &#8208; CONTENTS.
                    </div>
                    <br />
                    <TextareaAutosize
                      value={contents}
                      onChange={(e) => handleChange(e, "contents")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 4) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">EXECUTIVE SUMMARY</div>
                    <br />
                    <TextareaAutosize
                      value={exeSummary}
                      onChange={(e) => handleChange(e, "exe")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }else if (screenNumber === 5) {
    return (
      <Modal open={true} onClose={handleClose}>
      <div className="review-modal-container">
        <div className="review-modal-content">
          <div className="review-modal-header">
            <Typography variant="h5">Preview Report</Typography>
            <button className="custom-close-button" onClick={handleClose}>
              &#10005; {/* Unicode for 'X' */}
            </button>
          </div>
          <div className="review-modal-body" style={{ overflowY: "auto" }}>
            <Typography variant="body1">
              <div className="sub-headings">ACADEMIC INFORMATION</div>
              <br />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>FACILITY INFORMATION</TableCell>
                      <TableCell>COMMENTS & NOTES</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {facilityInfo &&
                      Object.entries(facilityInfo).map(([key, value], index) => (
                        <TableRow key={index}>
                          <TableCell>{key}:</TableCell>
                          <TableCell style={{display:"flex"}}>
                            <textarea
                              value={value}
                              onChange={(e) =>
                                handleChangeFacilityInfo({
                                  target: { name: key, value: e.target.value },
                                })
                              }
                              placeholder="Enter your text here"
                              style={{ width: "100%", minHeight: "80px" }}
                            />
                            <IconButton
              onClick={() => handleRemoveField(key)}
              style={{ color: "red", marginLeft: "10px" }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
    
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="New Field Name"
                          value={newKey}
                          onChange={handleNewKeyChange}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <textarea
                            value={newValue}
                            onChange={(e) =>
                              handleNewValueChange({ target: { value: e.target.value } })
                            }
                            placeholder="Enter your text here"
                            style={{ width: "100%", minHeight: "80px" }}
                          />
                          <button
                            onClick={handleAddNewField}
                            className="button-styles"
                            style={{
                              background: "#efc71d",
                              marginLeft: "10px",
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </div>
          <hr />
          <div className="review-modal-footer">
            <button
              className="button-styles"
              onClick={handleSave}
              style={{ background: "#efc71d" }}
            >
              Save
            </button>
            <button
              className="button-styles"
              onClick={handlePrev}
              style={{ background: "#efc71d" }}
            >
              &#171; Prev
            </button>
            <button
              className="button-styles"
              color="primary"
              onClick={handleNext}
              style={{ background: "#efc71d" }}
            >
              Next &#187;
            </button>
          </div>
        </div>
      </div>
    </Modal>
    );
  } else if (screenNumber === 6) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CRITICAL OBSERVATIONS</div>
                    <br />
                    <div className="critical-observations-div">
                      {criticalObservations.length === 0 ? (
                        <div className="no-observations">
                          <em>No critical observations</em>
                        </div>
                      ) : (
                        criticalObservations.map((observation, index) => (
                          <div key={index} className="observation-item">
                            <textarea
                              onChange={(e) => handleObservationEdit(index, e)}
                              style={{ width: "100%", fontFamily: "inherit" }}
                              value={observation.observation}
                            />
                            &nbsp;
                            <CancelIcon
                              onClick={() => removeItem(index)}
                              className="cancel-icon"
                            >
                              &#10005;
                            </CancelIcon>
                          </div>
                        ))
                      )}
                    </div>
                    <br />
                    <textarea
                      onChange={(e) => handleChange(e, "other details")}
                      value={otherDetails}
                      placeholder="Other Details..."
                      style={{
                        width: "99%",
                        height: "10vh",
                        fontFamily: "inherit",
                      }}
                    />
                  </div>
                </Typography>
              </div>

              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  disabled={disableSaveNext}
                  className="button-styles"
                  onClick={handlePrev}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  disabled={disableSaveNext}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 7) {
    return (
      <>
        {loading ? <Loader /> : null}
        <VariantsModal
          data={observationVariants}
          open={openVairantModal}
          handleClose={closeVariantModal}
          handleConfirmSelection={handleConfirmSelection}
        />
        <ImageViewerModal
          imageUrl={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="sub-headings" style={{ fontWeight: 500 }}>
                CRITICAL OBSERVATIONS, RECOMMENDATIONS & REASONING - ELECTRICAL
                SAFETY
              </div>
              <div className="review-modal-body">
                <div className="table-container">
                  <TableContainer component={Paper} className="table-scroll">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell>Areas</TableCell>
                          <TableCell>Categories</TableCell>
                          <TableCell>Check Point</TableCell>
                          <TableCell>Observation</TableCell>
                          <TableCell>Criticality</TableCell>
                          <TableCell>Recommendation</TableCell>
                          <TableCell>IS Reference</TableCell>
                          {/* <TableCell>Score</TableCell> */}
                          <TableCell>Photo Evidences</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedObservations.map((observation, index) => (
                          <TableRow
                            key={index}
                            className={
                              (isEditing && currentEditedRow === index) ||
                              !isEditing
                                ? "even-row"
                                : "odd-row"
                            }
                          >
                            <TableCell>{index + 1}</TableCell>
                            {/* <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div className="cell-content">
                                <span
                                  contentEditable={
                                    (isEditing && currentEditedRow === index) ||
                                    !isEditing
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "area",
                                      observation.area,
                                      observation
                                    )
                                  }
                                >
                                  {observation.area}
                                </span>
                              </div>
                              <EditIcon
                                onClick={(e) =>
                                  isEditing
                                    ? handleCellEdit(
                                        e,
                                        index,
                                        "area",
                                        observation.area,
                                        observation
                                      )
                                    : null
                                }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell> */}
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    isEditing && currentEditedRow !== index
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <CreatableSelect
                                  styles={customSelectStylesCreatable}
                                  placeholder="Area"
                                  options={areaList.map((area) => ({
                                    label: area,
                                    value: area,
                                  }))}
                                  defaultValue={{
                                    label: observation.area,
                                    value: observation.area,
                                  }}
                                  isSearchable
                                  onChange={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "area",
                                      observation.area,
                                      observation
                                    )
                                  }
                                  isDisabled={
                                    isEditing && currentEditedRow !== index
                                  }
                                />
                              </div>
                              <EditOutlinedIcon
                                // onClick={(e) =>
                                //   handleCellEdit(
                                //     e,
                                //     index,
                                //     "area",
                                //     observation.area,
                                //     observation
                                //   )
                                // }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    isEditing && currentEditedRow !== index
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <CreatableSelect
                                  styles={customSelectStylesCreatable}
                                  placeholder="Category..."
                                  options={categoryOptions}
                                  defaultValue={{
                                    label: observation.category,
                                    value: observation.category,
                                  }}
                                  isSearchable
                                  onChange={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "category",
                                      observation.category,
                                      observation
                                    )
                                  }
                                  isDisabled={
                                    isEditing && currentEditedRow !== index
                                  }
                                />
                              </div>
                              <EditOutlinedIcon
                                // onClick={(e) =>
                                //   handleCellEdit(
                                //     e,
                                //     index,
                                //     "category",
                                //     observation.category,
                                //     observation
                                //   )
                                // }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            {/* <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div className="cell-content">
                                <span
                                  contentEditable={
                                    (isEditing && currentEditedRow === index) ||
                                    !isEditing
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "category",
                                      observation.category,
                                      observation
                                    )
                                  }
                                >
                                  {observation.category}
                                </span>
                              </div>
                              <EditIcon
                                onClick={(e) =>
                                  isEditing
                                    ? handleCellEdit(
                                        e,
                                        index,
                                        "category",
                                        observation.category,
                                        observation
                                      )
                                    : null
                                }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell> */}
                            {/* <TableCell>{observation.check_points}</TableCell> */}
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    isEditing && currentEditedRow !== index
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    (isEditing && currentEditedRow === index) ||
                                    !isEditing
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "check_points",
                                      observation.check_points,
                                      observation
                                    )
                                  }
                                >
                                  {observation.check_points}
                                </span>
                              </div>
                              <EditOutlinedIcon
                                onClick={(e) =>
                                  isEditing
                                    ? handleCellEdit(
                                        e,
                                        index,
                                        "check_points",
                                        observation.check_points
                                          ? observation.check_points
                                          : "Check Point",
                                        observation
                                      )
                                    : null
                                }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    isEditing && currentEditedRow !== index
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    (isEditing && currentEditedRow === index) ||
                                    !isEditing
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "observation",
                                      observation.observation,
                                      observation
                                    )
                                  }
                                >
                                  {observation.observation}
                                </span>
                              </div>

                              <EditOutlinedIcon
                                onClick={(e) =>
                                  isEditing
                                    ? handleCellEdit(
                                        e,
                                        index,
                                        "observation",
                                        observation.observation,
                                        observation
                                      )
                                    : null
                                }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <Select
                                styles={customSelectStylesCreatable}
                                placeholder="Criticality"
                                options={criticalityOptions}
                                defaultValue={{
                                  label: observation.criticality,
                                  value: observation.criticality,
                                }}
                                isSearchable
                                onChange={(e) =>
                                  handleCellEdit(
                                    e,
                                    index,
                                    "criticality",
                                    observation.criticality,
                                    observation
                                  )
                                }
                                isDisabled={
                                  isEditing && currentEditedRow !== index
                                }
                              />
                              {/* <div className="cell-content">
                              <select style={{width:"150px", height:"38px", borderRadius:"3px", border: "0.4mm solid lightgrey"}}
                                  value={
                                    currentEditedRow === index
                                      ? editedObservations[0]?.criticality
                                      : observation.criticality
                                  }
                                  onChange={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "criticality",
                                      observation.criticality,
                                      observation
                                    )
                                  }
                                  disabled={
                                    isEditing && currentEditedRow !== index
                                  }
                                >
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div> */}
                              <EditOutlinedIcon
                                // onClick={(e) =>
                                //   handleCellEdit(
                                //     e,
                                //     index,
                                //     "criticality",
                                //     observation.criticality,
                                //     observation
                                //   )
                                // }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            <TableCell className="editable-cell">
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    isEditing && currentEditedRow !== index
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    (isEditing && currentEditedRow === index) ||
                                    !isEditing
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "recommendations",
                                      observation.recommendations,
                                      observation
                                    )
                                  }
                                >
                                  {observation.recommendations}
                                </span>
                              </div>

                              <EditOutlinedIcon
                                onClick={(e) =>
                                  isEditing
                                    ? handleCellEdit(
                                        e,
                                        index,
                                        "recommendations",
                                        observation.recommendations,
                                        observation
                                      )
                                    : null
                                }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    isEditing && currentEditedRow !== index
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    (isEditing && currentEditedRow === index) ||
                                    !isEditing
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "is_reference",
                                      observation.is_reference,
                                      observation
                                    )
                                  }
                                >
                                  {observation.is_reference}
                                </span>
                              </div>
                              <EditOutlinedIcon
                                onClick={(e) =>
                                  isEditing
                                    ? handleCellEdit(
                                        e,
                                        index,
                                        "is_reference",
                                        observation.is_reference,
                                        observation
                                      )
                                    : null
                                }
                                className="edit-icon"
                                fontSize="small"
                              />
                            </TableCell>
                            {/* <TableCell>
                              <input
                                value={observation.score}
                                onChange={(e) => handleScoreChange(e, index)} // Implement this function
                                placeholder="Enter score"
                              />
                            </TableCell> */}
                            <TableCell>
                              <div className="image-container">
                                {observation.imageUrls?.length > 0 ? (
                                  <div className="image-item">
                                    {observation.imageUrls.map(
                                      (imageUrl, imgIndex) => (
                                        <div
                                          style={{ display: "flex" }}
                                          key={imgIndex}
                                        >
                                          <img
                                            src={imageUrl}
                                            alt={`Image ${imgIndex + 1}`}
                                            className="photo-image-saved"
                                            onClick={() =>
                                              setSelectedImage(imageUrl)
                                            } // Set selected image on click
                                            style={{ cursor: "pointer" }}
                                          />
                                          <CancelIcon
                                            onClick={() =>
                                              handleRemoveImage(index, imgIndex)
                                            }
                                            className="cancel-icon"
                                            style={{
                                              pointerEvents:
                                                isEditing &&
                                                currentEditedRow !== index
                                                  ? "none"
                                                  : "auto",
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="upload-container">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleImageUpload(index, e.target.files)
                                  }
                                  multiple
                                  style={{ color: "transparent" }}
                                  disabled={
                                    isEditing && currentEditedRow !== index
                                  }
                                />
                                {observation.imageUrls?.length === 0 && (
                                  <div className="no-file-chosen">
                                    No file chosen
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ display: "flex" }}>
                                <InfoIcon
                                  onClick={() =>
                                    getObservationVariants(
                                      observation.observation,
                                      index
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                />

                                <PlaylistAddCircleIcon
                                  onClick={() => handleDuplicateRow(index)}
                                  style={{ cursor: "pointer" }}
                                />
                                <DeleteForeverIcon
                                  onClick={() => handleDeleteRow(index)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell>
                            {selectedObservations.length + 1}
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.area}
                              onChange={(e) => handleChangeNewRow(e, "area")}
                              placeholder="Area"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.category}
                              onChange={(e) =>
                                handleChangeNewRow(e, "category")
                              }
                              placeholder="Category"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.check_points}
                              onChange={(e) =>
                                handleChangeNewRow(e, "check_points")
                              }
                              placeholder="Check Point"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.observation}
                              onChange={(e) =>
                                handleChangeNewRow(e, "observation")
                              }
                              placeholder="Observation"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.criticality}
                              onChange={(e) =>
                                handleChangeNewRow(e, "criticality")
                              }
                              placeholder="Criticality"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.recommendations}
                              onChange={(e) =>
                                handleChangeNewRow(e, "recommendations")
                              }
                              placeholder="Recommendations"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.is_reference}
                              onChange={(e) =>
                                handleChangeNewRow(e, "is_reference")
                              }
                              placeholder="IS Reference"
                            />
                          </TableCell>
                          <TableCell>
                            <div className="image-container">
                              <div className="upload-container">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleImageUpload(
                                      selectedObservations.length,
                                      e.target.files
                                    )
                                  }
                                  multiple
                                  style={{ color: "transparent" }}
                                  disabled
                                />
                                {newRow.imageUrls?.length === 0 && (
                                  <div className="no-file-chosen">
                                    No file chosen
                                  </div>
                                )}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CheckIcon
                                onClick={handleAddRow}
                                style={{ cursor: "pointer", color: "green" }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  }
  // else if (screenNumber === 7) {
  //   return (
  //     <>
  //       {loading ? <Loader /> : null}
  //       <Modal open={true} onClose={handleClose}>
  //         <div className="review-modal-container">
  //           <div className="review-modal-content">
  //             <div className="review-modal-header">
  //               <Typography variant="h5">{module === "cmv"?" Electrical CMV Report":"Saved Report"}</Typography>
  //               <button
  //               className="custom-close-button"
  //               onClick={handleClose}
  //             >
  //               &#10005; {/* Unicode for 'X' */}
  //             </button>
  //             </div>
  //             <div className="review-modal-body" style={{overflowY:"auto"}}>
  //               <Typography variant="body1">
  //                 <div>
  //                   <div className="sub-headings">GLOBAL BEST PRACTICES</div>
  //                   <br />
  //                   <TextareaAutosize
  //                     value={bestPractice}
  //                     onChange={(e) => handleChange(e, "best_practice")}
  //                     placeholder="Enter your text here"
  //                     className="text-area"
  //                     style={{ background: "whitesmoke" }}
  //                   />
  //                 </div>
  //               </Typography>
  //             </div>
  //             <hr />
  //             <div className="review-modal-footer">
  //               <button className="button-styles" onClick={handleSave} style={{background:"#efc71d"}}>
  //                 Save
  //               </button>
  //               <button className="button-styles" onClick={handlePrev} style={{background:"#efc71d"}}>
  //                 &#171; Prev
  //               </button>
  //               <button
  //                 className="button-styles"
  //                 color="primary"
  //                 onClick={handleNext}
  //                 style={{background:"#efc71d"}}
  //               >
  //                 Next &#187;
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </Modal>
  //     </>
  //   );
  // }
  else if (screenNumber === 8) {
    return (
      <>
        {loading ? <Loader /> : null}

        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      Electrical Safety Scoring Table
                    </div>
                    <br />
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow style={{ background: "grey" }}>
                            <TableCell>Electrical Safety</TableCell>
                            <TableCell>Max Score</TableCell>
                            <TableCell>Score Obtained</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scores.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row["Electrical Safety"]}</TableCell>
                              <TableCell>{row["Max Score"]}</TableCell>
                              <TableCell>
                                <input
                                  type="number"
                                  placeholder="Enter score"
                                  value={row["Score Obtained"]}
                                  onChange={(e) =>
                                    handleScoreChange(index, e.target.value)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow style={{ background: "#efc71d" }}>
                            <TableCell>Cumulative</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {scores.reduce(
                                (acc, row) =>
                                  acc + parseFloat(row["Max Score"] || 0),
                                0
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {cumulativeScore}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 9) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " Electrical CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">THE WAY FORWARD</div>
                    <br />
                    <TextareaAutosize
                      value={theWayForward}
                      onChange={(e) => handleChange(e, "the_way_forward")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 10) {
    // return (
    //   <>
    //     {loading ? <Loader /> : null}
    //     <Modal open={true} onClose={handleClose}>
    //       <div className="review-modal-container">
    //         <div className="review-modal-content">
    //           <div className="review-modal-header">
    //             <Typography variant="h5">{module === "cmv"?" Electrical CMV Report":"Saved Report"}</Typography>
    //             <button
    //             className="custom-close-button"
    //             onClick={handleClose}
    //           >
    //             &#10005; {/* Unicode for 'X' */}
    //           </button>
    //           </div>
    //           <div className="review-modal-body" style={{overflowY:"auto"}}>
    //             <Typography variant="body1">
    //               <div>
    //                 <div className="sub-headings">CONCLUSION</div>
    //                 <br />
    //                 <TextareaAutosize
    //                   value={conclusion}
    //                   onChange={(e) => handleChange(e, "conclusion")}
    //                   placeholder="Enter your text here"
    //                   className="text-area"
    //                   style={{ background: "whitesmoke" }}
    //                 />
    //               </div>
    //             </Typography>
    //           </div>
    //           <hr />
    //           <div className="review-modal-footer">
    //             <button className="button-styles" onClick={handleSave} style={{background:"#efc71d"}}>
    //               Save
    //             </button>
    //             <button className="button-styles" onClick={handlePrev} style={{background:"#efc71d"}}>
    //               &#171; Prev
    //             </button>
    //             {module !== "cmv" ? (
    //               <button
    //                 className="button-styles"
    //                 onClick={handleComplete}
    //                 disabled={isComplete}
    //                 style={{background:isComplete?"lightgrey":"#efc71d"}}
    //               >
    //                 Complete
    //               </button>
    //             ) : null}

    //             <ExportSavedReportPDF
    //               selectedOrganization={selectedOrganization}
    //               selectedSite={selectedSite}
    //               backgroundBrief={backgroundBrief}
    //               contents={contents}
    //               exeSummary={exeSummary}
    //               criticalObservations={criticalObservations}
    //               selectedObservations={selectedObservations}
    //               selectedArea={selectedArea}
    //               selectedCategory={selectedCategory}
    //               recommendations={recommendations}
    //               conclusion={conclusion}
    //               selectedDateTime={selectedDateTime.split("T")[0]}
    //               // imageUrlsByRow={imageUrlsByRow}
    //               isSaved={isSaved}
    //               scores={scores}
    //               cumulativeScore={cumulativeScore}
    //               otherDetails={otherDetails}
    //               ReportUID={selectedReportData.report_id}
    //               startDate={new Date(startDate)}
    //               endDate={new Date(endDate)}
    //               bestPractice={bestPractice}
    //               theWayForward={theWayForward}
    //               name={name}
    //             />
    //             <ExportExcel
    //               selectedOrganization={selectedOrganization}
    //               selectedSite={selectedSite}
    //               backgroundBrief={backgroundBrief}
    //               contents={contents}
    //               exeSummary={exeSummary}
    //               criticalObservations={criticalObservations}
    //               selectedObservations={selectedObservations}
    //               selectedArea={selectedArea}
    //               selectedCategory={selectedCategory}
    //               recommendations={recommendations}
    //               conclusion={conclusion}
    //               selectedDateTime={selectedDateTime.split("T")[0]}
    //               // imageUrlsByRow={imageUrlsByRow}
    //               isSaved={isSaved}
    //               scores={scores}
    //               cumulativeScore={cumulativeScore}
    //               otherDetails={otherDetails}
    //               ReportUID={selectedReportData.report_id}
    //               bestPractice={bestPractice}
    //               theWayForward={theWayForward}
    //             />
    //             <ExportWordDoc
    //               selectedOrganization={selectedOrganization}
    //               selectedSite={selectedSite}
    //               backgroundBrief={backgroundBrief}
    //               contents={contents}
    //               exeSummary={exeSummary}
    //               criticalObservations={criticalObservations}
    //               selectedObservations={selectedObservations}
    //               selectedArea={selectedArea}
    //               selectedCategory={selectedCategory}
    //               recommendations={recommendations}
    //               conclusion={conclusion}
    //               selectedDateTime={selectedDateTime.split("T")[0]}
    //               // imageUrlsByRow={imageUrlsByRow}
    //               isSaved={isSaved}
    //               scores={scores}
    //               cumulativeScore={cumulativeScore}
    //               otherDetails={otherDetails}
    //               ReportUID={selectedReportData.report_id}
    //               bestPractice={bestPractice}
    //               theWayForward={theWayForward}
    //               startDate={new Date(startDate)}
    //               endDate={new Date(endDate)}
    //               name={name}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </Modal>
    //   </>
    // );
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div
              className={exp ? "export-modal-content" : "review-modal-content"}
            >
              <div className="review-modal-header">
                <Typography variant="h5">
                  {exp
                    ? "Export Report"
                    : module === "cmv"
                    ? "Electrical CMV Report"
                    : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              {!exp && (
                <div
                  className="review-modal-body"
                  style={{ overflowY: "auto" }}
                >
                  <Typography variant="body1">
                    <div>
                      <div className="sub-headings">CONCLUSION</div>
                      <br />
                      <TextareaAutosize
                        value={conclusion}
                        onChange={(e) => handleChange(e, "conclusion")}
                        placeholder="Enter your text here"
                        className="text-area"
                        style={{ background: "whitesmoke" }}
                      />
                    </div>
                  </Typography>
                </div>
              )}
              <hr />
              <div className="review-modal-footer">
                {!exp && (
                  <>
                    <button
                      className="button-styles"
                      onClick={handleSave}
                      style={{ background: "#efc71d" }}
                    >
                      Save
                    </button>
                    <button
                      className="button-styles"
                      onClick={handlePrev}
                      style={{ background: "#efc71d" }}
                    >
                      &#171; Prev
                    </button>
                    {module !== "cmv" && (
                      <button
                        className="button-styles"
                        onClick={handleComplete}
                        disabled={isComplete}
                        style={{
                          background: isComplete ? "lightgrey" : "#efc71d",
                        }}
                      >
                        Complete
                      </button>
                    )}
                  </>
                )}
                <ExportSavedReportPDF
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={exp ? true : isSaved}
                  scores={scores}
                  cumulativeScore={cumulativeScore}
                  otherDetails={otherDetails}
                  ReportUID={selectedReportData.report_id}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  name={name}
                  facilityInfo={facilityInfo}
                />
                <ExportExcel
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={exp ? true : isSaved}
                  scores={scores}
                  cumulativeScore={cumulativeScore}
                  otherDetails={otherDetails}
                  ReportUID={selectedReportData.report_id}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  name={name}
                  facilityInfo={facilityInfo}
                />
                <ExportWordDoc
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={exp ? true : isSaved}
                  scores={scores}
                  cumulativeScore={cumulativeScore}
                  otherDetails={otherDetails}
                  ReportUID={selectedReportData.report_id}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  name={name}
                  facilityInfo={facilityInfo}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};

export default SavedReportModal;