import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelJS from "exceljs";

const ExportExcel = ({
  selectedOrganization,
  selectedSite,
  selectedDateTime,
  backgroundBrief,
  contents,
  exeSummary,
  conclusion,
  criticalObservations,
  selectedObservations,
  isSaved,
  scores,
  cumulativeScore,
  otherDetails,
  reportType,
  ReportUID,
  bestPractice,
  theWayForward,
  startDate,
  endDate,
  name,
  facilityInfo
}) => {
  console.log(facilityInfo)
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();

    // Create a worksheet for the main report data
    const reportWorksheet = workbook.addWorksheet("Report");
    reportWorksheet.addRow([
      "Organization",
      selectedOrganization ? selectedOrganization.label : "",
    ]);
    reportWorksheet.addRow(["Date", selectedDateTime ? selectedDateTime.split("-").reverse().join("-") : ""]);
    reportWorksheet.addRow(["Site", selectedSite ? selectedSite.label : ""]);
    reportWorksheet.addRow(["Background Brief", backgroundBrief]);
    reportWorksheet.addRow(["Contents", contents]);
    reportWorksheet.addRow(["Executive Summary", exeSummary]);
    if (reportType === "HSE") {
      reportWorksheet.addRow(["Global Best Practices", bestPractice]);
    }
    reportWorksheet.addRow(["The Way Forward", theWayForward]);
    reportWorksheet.addRow(["Conclusion", conclusion]);

    // Conditionally create a new worksheet for the history table if reportType !== "HSE"
    if (reportType !== "HSE") {
      const historyWorksheet = workbook.addWorksheet("Document History");
      historyWorksheet.addRow(["PARTICULARS", "DETAILS"]);
      historyWorksheet.addRow([
        "Date of Visit",
        startDate.getTime() === endDate.getTime()
          ? `${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`
          : `${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()} to ${endDate.getDate()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getFullYear()}`
      ]);
      historyWorksheet.addRow(["Document Prepared By", name]);
      historyWorksheet.addRow(["Date of Document Submission", selectedDateTime.split("-").reverse().join("-")]);
      historyWorksheet.addRow(["Document Version", ""]); // Add document version if available
    }

    const observationsAndRecommendationsWorksheet = workbook.addWorksheet(
      "Observations & Recommendations"
    );
    observationsAndRecommendationsWorksheet.addRow([
      "Sr No.",
      "Area",
      "Observation",
      "Criticality",
      "Recommendations",
      "IS Reference",
      "Photo Evidence",
      reportType === "HSE" ? "Score" : null,
    ]);

    // Add rows with observations, recommendations, and images
    for (let i = 0; i < selectedObservations.length; i++) {
      const observation = selectedObservations[i].area || "N/A";
      const description = selectedObservations[i].observation || "N/A";
      const criticality = selectedObservations[i].criticality || "N/A";
      const recommendation = selectedObservations[i].recommendations || "N/A";
      const isRef = selectedObservations[i].is_reference || "N/A";
      const score = selectedObservations[i].score || "N/A";
      const imageUrls = selectedObservations[i].imageUrls || [];

      // Format "Photo Evidence" column based on number of image URLs
      let photoEvidence = imageUrls.length > 0 ? imageUrls.join("\n") : "N/A";

      observationsAndRecommendationsWorksheet.addRow([
        i + 1,
        observation,
        description,
        criticality,
        recommendation,
        isRef,
        photoEvidence,
        reportType === "HSE" ? score : null,
      ]);
    }

    // Set the column width for the image column in "Observations & Recommendations"
    observationsAndRecommendationsWorksheet.getColumn(7).width = 30;

    // Set the column width for the "Score" column in "Observations & Recommendations"
    observationsAndRecommendationsWorksheet.getColumn(8).width = 10;

    // Create a worksheet for "Critical Observations"
    const criticalObservationsWorksheet = workbook.addWorksheet(
      "Critical Observations"
    );
    criticalObservationsWorksheet.addRow(["Sr No.", "Observation"]);

    // Add rows for critical observations
    for (let i = 0; i < criticalObservations.length; i++) {
      const observation = criticalObservations[i].observation || "";

      criticalObservationsWorksheet.addRow([i + 1, observation]);
    }

    // Add an empty row as a separator between critical observations and other details
    criticalObservationsWorksheet.addRow([]);

    // Add "Other Details" as a single cell below the table
    criticalObservationsWorksheet.addRow(["", otherDetails]);

    // Create a worksheet for "Scores"
    if (reportType !== "HSE") {
      const scoresWorksheet = workbook.addWorksheet("Scores");
      scoresWorksheet.addRow([
        "Electrical Safety",
        "Max Score",
        "Score Obtained",
      ]);

      // Add rows for scores
      scores.forEach((row, index) => {
        scoresWorksheet.addRow([
          row["Electrical Safety"],
          row["Max Score"],
          row["Score Obtained"],
        ]);
      });

      // Add a row for "Cumulative" in the "Scores" worksheet
      const cumulativeRow = ["Cumulative", 10, cumulativeScore];
      scoresWorksheet.addRow(cumulativeRow).eachCell((cell, colNumber) => {
        if (colNumber === cumulativeRow.length) {
          // Align cumulative score to the right
          cell.alignment = { horizontal: "right" };
        }
      });
    }

    // Set the column width for the image column in "Observations & Recommendations" (adjust the width as needed)
    observationsAndRecommendationsWorksheet.getColumn(7).width = 100;

    const academicInforWorksheet = workbook.addWorksheet(
      "Academic Information"
    );
    academicInforWorksheet.addRow([
      "Facility Information",
      "Comments & Notes"
    ]);

    Object.entries(facilityInfo).forEach(([key, value]) => {
      academicInforWorksheet.addRow([key, value || ""]);
    });

    // Trigger the download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${ReportUID}.xlsx`;
    a.click();
  };

  return (
    <div>
      <button
        onClick={exportToExcel}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          background: isSaved ? "#efc71d" : "lightgrey",
          borderStyle: "none",
          borderRadius: "5px",
          fontWeight: "bold",
          padding: "11px 18px",
          textTransform: "uppercase",
          color: "white",
          fontSize: "1rem",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
        }}
        disabled={!isSaved}
      >
        EXPORT TO EXCEL
        <FileDownloadIcon fontSize="small" style={{ marginLeft: "8px" }} />
      </button>
    </div>
  );
};

export default ExportExcel;


